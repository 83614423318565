import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Grid, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import useStyles from "./styles";
import Images from "../../Config/Images";
import { color } from "../../Config/theme";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import authActions from "../../Redux/reducers/auth/actions";
import { toast } from "react-toastify";

const fbAppId = Setting.FACEBOOK_APP_ID;

const { setUserData, setToken } = authActions;

export default function FBLoginButton(props) {
  const platForm = isAndroid ? "android" : isIOS ? "ios" : "android";
  const {
    fullWidth = false,
    from = "",
    type = "",
    onGetUserInfo = () => null,
    onCloseModal = () => null,
  } = props;

  const { useruuid } = useSelector((state) => state.auth);

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [buttonLoader, setButtonLoader] = useState(false);

  async function signUpAction(accessToken) {
    if (_.isString(accessToken) && !_.isEmpty(accessToken)) {
      const data = {
        "SocialForm[social_type]": 3, // 1 For Google, 2 For Linked, & 3 for Facebook
        "SocialForm[uuid]": useruuid,
        "SocialForm[platform]": platForm,
        "SocialForm[token]": accessToken,
      };

      try {
        let endPoint = Setting.endpoints.social_sign_up;
        const response = await getAPIProgressData(endPoint, "POST", data);

        if (response?.status) {
          setButtonLoader(false);
          onGetUserInfo(response?.data);
        } else {
          setButtonLoader(false);
        }
      } catch (err) {
        console.log("Catch Part", err);
        setButtonLoader(false);
      }
    }
  }

  async function loginAction(accessToken) {
    if (_.isString(accessToken) && !_.isEmpty(accessToken)) {
      const data = {
        "SocialForm[social_type]": 3, // 1 For Google, 2 For Linked, & 3 for Facebook
        "SocialForm[uuid]": useruuid,
        "SocialForm[platform]": platForm,
        "SocialForm[token]": accessToken,
      };

      try {
        let endPoint = Setting.endpoints.social_login;
        const response = await getAPIProgressData(endPoint, "POST", data);

        if (response?.status) {
          if (response?.data?.role === "employer") {
            navigate("/employer/company");
          } else {
            navigate("/candidate/profile");
          }
          toast.success("Logged in successfully");
          dispatch(setUserData(response?.data));
          dispatch(setToken(response?.data?.access_token || ""));
          setButtonLoader(false);
          onCloseModal();
        } else {
          setButtonLoader(false);
          onCloseModal();
        }
      } catch (err) {
        console.log("Catch Part", err);
        setButtonLoader(false);
        onCloseModal();
      }
    }
  }

  const responseFacebook = (response) => {
    const accessToken = response?.accessToken;
    console.log("accessToken ===============>", accessToken);

    if (from === "SignUp") {
      // Sign Up with Facebook Account
      signUpAction(accessToken);
    } else {
      // Login With Facebook Account
      loginAction(accessToken);
    }
  };

  return (
    <FacebookLogin
      appId={fbAppId}
      autoLoad={false}
      fields="name,email,picture"
      scope="public_profile"
      onFailure={() => {
        setButtonLoader(false);
      }}
      disableMobileRedirect={true}
      callback={responseFacebook}
      render={(renderProps) =>
        type === "employer" ? (
          buttonLoader ? (
            <CircularProgress
              style={{
                height: 26,
                width: 26,
                marginRight: 20,
                color: color.primary,
              }}
            />
          ) : (
            <img
              src={Images?.Fb_blue_logo}
              alt="error"
              style={{
                height: 30,
                width: 30,
                // marginRight: 20,
                cursor: "pointer",
              }}
              onClick={
                buttonLoader
                  ? null
                  : () => {
                      setButtonLoader(true);
                      renderProps.onClick();
                    }
              }
            />
          )
        ) : (
          <Grid
            container
            className={`${classes.button_main_con} ${
              fullWidth ? classes.fullWidthDiv : ""
            }`}
            onClick={
              buttonLoader
                ? null
                : () => {
                    console.log(
                      "<=============== Facebook On Click ===============>"
                    );
                    renderProps.onClick();
                  }
            }
            style={{ marginBottom: 15 }}
          >
            {buttonLoader ? (
              <CircularProgress
                style={{
                  width: 15,
                  height: 15,
                  color: color.primary,
                }}
              />
            ) : (
              <>
                <img
                  alt="facebook_logo"
                  src={Images.FB_logo}
                  style={{
                    width: "30px",
                    objectFit: "contain",
                    marginRight: 10,
                  }}
                />
                <Typography className={classes.button_name}>
                  {from === "SignUp"
                    ? "Sign up with Facebook"
                    : "Log In with Facebook"}
                </Typography>
              </>
            )}
          </Grid>
        )
      }
    />
  );
}
