import React, { useState, useEffect } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Select,
  MenuItem,
  FormControl,
  Modal,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  CircularProgress,
  FormHelperText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Images from "../../../../Config/Images";
import useStyles from "../../styles";
import { useSelector } from "react-redux";
import { isDate, isEmpty } from "lodash";

const errorObj = {
  instituteErr: false,
  courceErr: false,
  levelErr: false,
  resultErr: false,
  startDateErr: false,
  endDateErr: false,
  instituteMsg: "",
  courceMsg: "",
  levelMsg: "",
  resultMsg: "",
  startDateMsg: "",
  endDateMsg: "",
};

function MEducation(props) {
  const {
    visible = false,
    handleModal = () => null,
    onSave = () => null,
    loader = false,
    itemData = {},
  } = props;
  const { dropdowndata } = useSelector((state) => state.auth);
  const classes = useStyles();
  const theme = useTheme();
  const [institution, setInstitution] = useState("");
  const [courseTitle, setCourseTitle] = useState("");
  const [level, setLevel] = useState("");
  const [result, setResult] = useState("");
  const [study, setStudy] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [picker, setPicker] = useState("");
  const [errObj, setErrObj] = useState(errorObj);
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 290 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: 550,
  };

  useEffect(() => {
    if (visible && !isEmpty(itemData)) {
      setInstitution(itemData?.institute_name || "");
      setCourseTitle(itemData?.course_title || "");
      setLevel(itemData?.level || "");
      setResult(itemData?.result || "");
      setStudy(itemData?.currently_studying === 1 ? true : false || false);
      setStartDate(itemData?.from_date ? new Date(itemData?.from_date) : "");
      setEndDate(itemData?.to_date ? new Date(itemData?.to_date) : "");
    }
    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  // this function for reset form data
  function resetFormData(type = "") {
    setInstitution("");
    setCourseTitle("");
    setLevel("");
    setResult("");
    setStudy(false);
    setStartDate(null);
    setEndDate(null);
    setErrObj(errorObj);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(level.toString())) {
      valid = false;
      error.levelErr = true;
      error.levelMsg = "Please select level of education";
    } else if (isEmpty(institution) && level !== 270) {
      valid = false;
      error.instituteErr = true;
      error.instituteMsg = "Please enter institute";
    } else if (isEmpty(courseTitle) && level !== 270) {
      valid = false;
      error.courceErr = true;
      error.courceMsg = "Please enter course name";
    } else if (isEmpty(result) && level !== 270) {
      valid = false;
      error.resultErr = true;
      error.resultMsg = "Please enter result";
    } else if (!isDate(startDate) && level !== 270) {
      valid = false;
      error.startDateErr = true;
      error.startDateMsg = "Please select start date";
    } else if (!study && !isDate(endDate) && level !== 270) {
      valid = false;
      error.endDateErr = true;
      error.endDateMsg = "Please select end date";
    }

    setErrObj(error);

    if (valid) {
      const educationData = {
        institution: institution,
        courseTitle: courseTitle,
        level: level,
        result: result,
        study: study,
        startDate: startDate,
        endDate: endDate,
      };
      onSave(educationData);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle}>
            <Typography variant="subtitle1" component="h2">
              Add Education
            </Typography>
            <img
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div
            className={`${classes.formDivStyle} sliderscroll`}
            style={{ maxHeight: 350 }}
          >
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.inputFieldStyle}
            >
              <Select
                IconComponent={() => (
                  <img src={Images.angle} className={classes.angleBtnStyle} />
                )}
                onChange={(e) => {
                  setLevel(e.target.value);
                  setErrObj({ ...errObj, levelErr: false, levelMsg: "" });
                  e.target.value === 270 && setErrObj(errorObj);
                }}
                value={level}
                error={errObj.levelErr}
                displayEmpty
                style={level === "" ? { color: "#A2A2A2" } : {}}
              >
                <MenuItem value="" disabled>
                  Level
                </MenuItem>
                {dropdowndata?.education_level.map((item) => {
                  return (
                    <MenuItem
                      key={`education_${item.value}`}
                      value={item?.value}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </Select>
              {errObj.levelErr ? (
                <FormHelperText style={{ color: "red" }}>
                  {errObj.levelMsg}
                </FormHelperText>
              ) : null}
            </FormControl>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Institution"
              className={classes.inputFieldStyle}
              value={institution}
              error={errObj.instituteErr}
              helperText={errObj.instituteErr ? errObj.instituteMsg : null}
              onChange={(e) => {
                setInstitution(e.target.value);
                setErrObj({ ...errObj, instituteErr: false, instituteMsg: "" });
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Course title"
              className={classes.inputFieldStyle}
              value={courseTitle}
              error={errObj.courceErr}
              helperText={errObj.courceErr ? errObj.courceMsg : null}
              onChange={(e) => {
                setCourseTitle(e.target.value);
                setErrObj({ ...errObj, courceErr: false, courceMsg: "" });
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Result"
              className={classes.inputFieldStyle}
              value={result}
              error={errObj.resultErr}
              helperText={errObj.resultErr ? errObj.resultMsg : null}
              onChange={(e) => {
                setResult(e.target.value);
                setErrObj({ ...errObj, resultErr: false, resultMsg: "" });
              }}
            />
            <FormControlLabel
              style={{ marginBottom: 15 }}
              control={
                <Checkbox
                  checked={study}
                  onChange={() => {
                    setStudy(!study);
                    setErrObj({
                      ...errObj,
                      endDateErr: false,
                      endDateMsg: "",
                    });
                  }}
                  name="study"
                />
              }
              label={
                <Typography variant="body2" className={classes.checkBoxText}>
                  I am currently studying here
                </Typography>
              }
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={startDate}
                showToolbar={false}
                inputFormat={"dd/MM/yyyy"}
                maxDate={new Date()}
                onChange={(newValue) => {
                  setStartDate(newValue);
                  setPicker("");
                  setErrObj({
                    ...errObj,
                    startDateErr: false,
                    startDateMsg: "",
                  });
                }}
                open={picker === "from"}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    className={classes.inputFieldStyle}
                    {...params}
                    error={errObj.startDateErr}
                    helperText={
                      errObj.startDateErr ? errObj.startDateMsg : null
                    }
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "Select Date",
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            className={`${classes.cViewStyle} ${classes.pickerStyle}`}
                            onClick={() => setPicker("from")}
                          >
                            <img src={Images.picker} style={{ width: 24 }} />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={endDate}
                showToolbar={false}
                disabled={study}
                maxDate={new Date()}
                inputFormat={"dd/MM/yyyy"}
                onChange={(newValue) => {
                  setEndDate(newValue);
                  setPicker("");
                  setErrObj({
                    ...errObj,
                    endDateErr: false,
                    endDateMsg: "",
                  });
                }}
                open={picker === "to"}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    className={classes.inputFieldStyle}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "Select Date",
                    }}
                    error={errObj.endDateErr}
                    helperText={errObj.endDateErr ? errObj.endDateMsg : null}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            className={`${classes.cViewStyle} ${classes.pickerStyle}`}
                            onClick={() => {
                              if (study) {
                                return null;
                              }
                              setPicker("to");
                            }}
                          >
                            <img src={Images.picker} style={{ width: 24 }} />
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div className={classes.splitViewStyle} style={{ marginTop: 10 }}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.modalBtnStyle}
              onClick={resetFormData}
            >
              Reset
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.modalBtnStyle}
              onClick={() => {
                checkValidation();
              }}
              disabled={loader}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={26} />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default MEducation;
