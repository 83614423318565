import React from "react";
import {
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import useStyles from "./styles";
import thankYou from "../../../../Assets/Lottie/thank-you.json";
import Lottie from "react-lottie";

function ThankYou(props) {
  const { visible = false, handleModal = () => null } = props;
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: md ? 300 : 600,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: 550,
  };

  return (
    <Modal
      open={visible}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: thankYou,
            }}
            height={window.innerWidth >= 500 ? 300 : 250}
            width={window.innerWidth >= 500 ? 300 : 250}
          />

          <div
            className={classes.splitViewStyle}
            style={{ justifyContent: "unset", marginTop: 10 }}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.modalBtnStyle}
              fullWidth
              onClick={handleModal}
            >
              Okay
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ThankYou;
