/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { flattenDeep, isArray, isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MJob from "../../../Components/Jobs/MJob";
import { Setting } from "../../../Utils/Setting";
import { getApiData } from "../../../Utils/APIHelper";
import useStyles from "./styles";

export default function ViewAllJobs() {
  const classes = useStyles();
  const location = useLocation();
  const { token, candidateProfile } = useSelector((state) => state.auth);
  const jobId = !isEmpty(location?.search)
    ? location?.search?.substring(8)
    : location?.state?.jobId || null;
  const job_post_id = !isEmpty(location?.search)
    ? location?.search?.substring(8)
    : location?.state?.job_post_id || null;
  const [jobs, setJobs] = useState({
    data: [],
    pageData: {},
  });
  const [pageLoad, setPageLoad] = useState(true);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    document.title = Setting.page_name.EMPLOYER_MATCHING_JOB;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (jobId) {
      setPageLoad(true);
      getCandidateJobList();
    } else {
      setPageLoad(false);
    }
  }, [jobId]);

  // this function for get candidate job list
  async function getCandidateJobList(key = "") {
    const sector_id = !isEmpty(candidateProfile) && candidateProfile?.sector_id;
    try {
      const response = await getApiData(
        `${Setting.endpoints.viewAllCandidates}?sector_id=${jobId}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data.rows)) {
          const newData =
            key === "load"
              ? flattenDeep([...jobs?.data, response?.data?.rows])
              : response?.data?.rows;
          setJobs({
            data: newData || [],
            pageData: response?.data?.pagination,
          });
        }
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.titleSection}>
        <Typography className={classes.sectionTitle}>
          View All Candidates
        </Typography>
      </div>

      {pageLoad ? (
        <div className={classes.loaderView}>
          <CircularProgress size={50} />
        </div>
      ) : isArray(jobs?.data) && jobs?.data.length > 0 ? (
        jobs?.data.map((item) => {
          const newData = { ...item, job_post_id: job_post_id };
          return (
            <MJob
              key={`job_${item?.job_post_id}`}
              data={newData}
              updateList={() => {
                setPageLoad(true);
                getCandidateJobList();
              }}
              from="ViewAllCandidate"
            />
          );
        })
      ) : (
        <div className={classes.noDataView}>
          <Typography className={classes.sectionTitle}>
            No Candidates available
          </Typography>
        </div>
      )}
    </Grid>
  );
}
