import React, { useEffect, useState } from "react";
import "./styles.css";
import { Setting } from "../../../Utils/Setting";
import { Typography, Grid, CircularProgress } from "@mui/material";
import { getApiData } from "../../../Utils/APIHelper";
import { useSelector } from "react-redux";
import Images from "../../../Config/Images";
import { isMobile, isTablet } from "react-device-detect";

function Contact_Us() {
  useEffect(() => {
    document.title = Setting.page_name.CONTACT;
    getcontectList();
  }, []);

  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState({});
  const [pageLoader, setPageLoader] = useState(false);

  // this function for get contect us data
  async function getcontectList() {
    setPageLoader(true);
    let endpoint = `${Setting.endpoints.cmsdetails}?slug=contact_us`;
    try {
      const response = await getApiData(
        endpoint,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setData(response?.data);
        setPageLoader(false);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoader(false);
    }
  }

  return (
    <div>
      <Grid container>
        {pageLoader ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              height: 500,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height={"80vh"}
            wrap={isMobile ? "wrap" : "nowrap"}
          >
            <Grid
              container={isMobile ? true : false}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img src={Images.contact} width={isTablet ? "60%" : "90%"} />
            </Grid>
            <Grid
              container={isMobile ? true : false}
              display="flex"
              flexDirection={"column"}
              alignItems="center"
              justifyContent={"center"}
              marginTop={isMobile && 5}
            >
              <Typography className="primarycolor fontsize2">
                Contact Us
              </Typography>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.app_body || "",
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Contact_Us;
