import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  OutlinedInput,
  useMediaQuery,
  Grid,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import Images from "../../../../Config/Images";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { Setting } from "../../../../Utils/Setting";
import useStyles from "../../styles";

function MInformation(props) {
  const { visible = false, handleModal = () => null, data = {} } = props;
  const { token } = useSelector((state) => state.auth);
  const [imageUrl, setImageUrl] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [tagLine, setTagLine] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (visible) {
      setCompanyName(data?.company_name || "");
      setTagLine(data?.tag_line || "");
      setImageUrl(data?.company_logo);
    }
  }, [visible]);

  function err(message) {
    toast.error(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      progress: undefined,
    });
  }

  function checkValidation() {
    let valid = true;

    if (isEmpty(imageUrl)) {
      valid = false;
      err("Please select company logo");
    } else if (isEmpty(companyName)) {
      valid = false;
      err("Please add company name");
    } else if (isEmpty(tagLine)) {
      valid = false;
      err("Please add tagline");
    }

    if (valid) {
      console.log("save ===>>");
      updateDetails();
    }
  }

  // this function for update information to server
  async function updateDetails() {
    setBtnLoad(true);
    const obj = {
      "EmployerCompany[company_name]": companyName,
      "EmployerCompany[tag_line]": tagLine,
    };

    if (selectedFile?.name) {
      obj["EmployerCompany[company_logo]"] = selectedFile;
    }

    try {
      const response = await getAPIProgressData(
        Setting.endpoints.employerAddInformation,
        "POST",
        obj,
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        resetFormData("update", response.data);
      } else {
        toast.error(response.message);
      }
      setBtnLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setBtnLoad(false);
    }
  }

  function uploadItem(file) {
    const displayUrl = URL.createObjectURL(file);

    setImageUrl(displayUrl);
    setSelectedFile(file);
  }

  function resetFormData(type = "", data = {}) {
    setImageUrl("");
    setCompanyName("");
    setTagLine("");
    if (type === "close" && handleModal) {
      handleModal();
    } else if (type === "update") {
      handleModal(data, type);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => (btnLoad ? null : resetFormData("close"))}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography variant="subtitle1" component="h2">
              Add Information
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => (btnLoad ? null : resetFormData("close"))}
            />
          </div>
          <div
            className={classes.companylogo}
            style={{ marginBottom: 10, position: "relative" }}
          >
            {isEmpty(imageUrl) ? (
              <Button component="label" className={classes.addimage}>
                <Typography
                  className="lightsilvercolor regulerlargefont"
                  style={{ textAlign: "center" }}
                >
                  Add company logo
                </Typography>
                <input
                  type="file"
                  onChange={(e) => {
                    uploadItem(e.target.files[0]);
                  }}
                  accept="image/jpeg, image/png, image/jpg"
                  hidden
                />
              </Button>
            ) : (
              <>
                <img
                  src={imageUrl}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: 4,
                    objectFit: "cover",
                  }}
                  alt="coverimage"
                />
                <div
                  className={classes.closeIconDivStyle}
                  onClick={() => setImageUrl("")}
                >
                  <Close className={classes.closeIconStyle} />
                </div>
              </>
            )}
          </div>
          <OutlinedInput
            placeholder="Company Name*"
            value={companyName}
            onChange={(v) => {
              setCompanyName(v.target.value);
            }}
            style={{ marginBottom: 10, width: "100%" }}
          />
          <OutlinedInput
            placeholder="Tagline*"
            value={tagLine}
            onChange={(v) => {
              setTagLine(v.target.value);
            }}
            multiline
            rows={4}
            style={{ marginBottom: 10, width: "100%" }}
          />
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid xs={5.8} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
                disabled={btnLoad}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8}>
              <Button
                variant={btnLoad ? "outlined" : "contained"}
                style={{ width: "100%" }}
                onClick={() => {
                  checkValidation();
                }}
                disabled={btnLoad}
              >
                {btnLoad ? <CircularProgress size={28} /> : "SAVE"}
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MInformation;
