import React, { useState } from "react";
import { Typography, Button, Grid, Collapse } from "@mui/material";
import { isEmpty } from "lodash";
import moment from "moment";
import SendMessageModal from "../../SendMessageModal";
import Images from "../../../Config/Images";
import useStyles from "../styles";

function Message(props) {
  const { data = {} } = props;
  const classes = useStyles();
  const [expand, setExpand] = useState(false);
  const [visible, setVisible] = useState(false);

  const uData = (!isEmpty(data) && data?.sender_details) || {};
  return (
    <>
      <Grid
        container
        className={`${classes.container} ${classes.messageContainer}`}
        style={{ cursor: "pointer" }}
      >
        <div
          className={classes.messageContainerDiv}
          onClick={() => setExpand(!expand)}
        >
          <div className={classes.messageImageContainer}>
            <img src={uData?.photo} className={classes.msgImg} />
            <div className={`${classes.hideDateView} ${classes.pl20}`}>
              <Typography className={classes.sectionTitle}>
                {uData?.name || ""}
              </Typography>
              {/* <Typography style={{ fontFamily: "PoppinsMedium" }}>
                {data?.message || ""}
              </Typography> */}
            </div>
          </div>
          <Typography
            className={`${classes.sectionDesc} ${classes.hideDateView}`}
          >
            {(data?.created_at &&
              moment(data?.created_at).format("DD MMMM YYYY")) ||
              ""}
          </Typography>
          <div className={`${classes.mobileRespDiv} ${classes.pl20}`}>
            <div>
              <Typography className={classes.sectionTitle}>
                {uData?.name || ""}
              </Typography>
              {/* <Typography style={{ fontFamily: "PoppinsMedium" }}>
                {data?.message || ""}
              </Typography> */}
            </div>
            <Typography
              className={classes.sectionDesc}
              style={{ textAlign: "right" }}
            >
              {(data?.created_at &&
                moment(data?.created_at).format("DD MMMM YYYY")) ||
                ""}
            </Typography>
          </div>
        </div>
        <Collapse
          in={expand}
          timeout="auto"
          unmountOnExit
          style={{ marginTop: 20, width: "100%" }}
        >
          <Typography
            className={classes.sectionDesc}
            style={{ whiteSpace: "pre-line", wordBreak: "break-all" }}
          >
            {data?.message}
          </Typography>
          <div className={classes.msgBtnContainer}>
            <Grid xs={12} sm={4} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setVisible(true)}
              >
                Message
              </Button>
            </Grid>
          </div>
        </Collapse>
      </Grid>
      <SendMessageModal
        visible={visible}
        data={data}
        receiverId={data?.sender_details?.id || null}
        handleModal={() => setVisible(false)}
      />
    </>
  );
}

export default Message;
