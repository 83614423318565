import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import _, { isArray, isEmpty, isObject } from "lodash";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { EventAvailable } from "@mui/icons-material";
import Images from "../../../../Config/Images";
import CheckoutForm from "../../../Billing/CheckoutForm";
import { getApiData, getAPIProgressData } from "../../../../Utils/APIHelper";
import { Setting } from "../../../../Utils/Setting";
import useStyles from "../../styles";

function MPurchase(props) {
  const {
    visible = false,
    handleModal = () => null,
    puchaseSuccess = () => null,
    data = {},
    jobId = null,
    candidateId = null,
  } = props;
  const { token } = useSelector((state) => state.auth);
  const [skillArr, setSkillArr] = useState([]);
  const [isCardDetail, setCardDetail] = useState(false);
  const [isPurchaseSuccessful, setIsPurchaseSuccessful] = useState(false);
  const [selectedCard, setSelectedCard] = useState("0");
  const [stripePromise, setStripePromise] = useState(null);
  const [newCard, setNewCard] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [btnLoad, setBtnLoad] = useState(false);

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 450,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    setStripePromise(loadStripe(Setting?.stripeKey));
    getCardList();
  }, []);

  useEffect(() => {
    if (isArray(data?.skills) && data?.skills.length > 0) {
      setSkillArr(data?.skills);
    }
  }, [data]);

  // this function for get card list
  async function getCardList() {
    try {
      const response = await getApiData(
        Setting.endpoints.paymentCardList,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.data) &&
          response?.data?.data.length > 0
        ) {
          setCardList(response.data.data);
        }
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  }

  // this function for payment by stripe
  async function payment(cToken) {
    setBtnLoad(true);
    try {
      const obj = {
        card_token: cToken,
        job_post_id: jobId,
        candidate_id: candidateId,
        amount: 20,
      };
      const response = await getAPIProgressData(
        Setting.endpoints.paymentMakePayment,
        "POST",
        obj,
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        toast.success(response.message);
        setIsPurchaseSuccessful(true);
        setCardDetail(false);
        puchaseSuccess(response?.data || {});
      } else {
        toast.error(response.message);
      }
      setBtnLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setBtnLoad(false);
    }
  }

  function resetFormData(type = "") {
    setCardDetail(false);
    setIsPurchaseSuccessful(false);
    setNewCard(false);
    setSelectedCard("0");
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography variant="subtitle1" component="h2">
              {isCardDetail
                ? `Make payment to view ${data?.firstname || ""} ${
                    data?.lastname || ""
                  } profile`
                : isPurchaseSuccessful
                ? "Purchase successful"
                : "Confirm purchase"}
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          {isCardDetail ? (
            <Grid container justifyContent="space-between">
              {newCard ? (
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    handleModal={(type, data) => {
                      if (type === "success") {
                        setNewCard(false);
                        getCardList();
                        // payment(data);
                      } else {
                        setNewCard(false);
                      }
                    }}
                  />
                </Elements>
              ) : (
                <>
                  <Select
                    classes={{
                      root: classes.bgColor,
                    }}
                    variant="outlined"
                    placeholder="Select card"
                    value={selectedCard}
                    onChange={(e) => setSelectedCard(e.target.value)}
                    fullWidth
                  >
                    <MenuItem
                      classes={{
                        root: classes.bgColor,
                      }}
                      value="0"
                    >
                      Select Card
                    </MenuItem>
                    {isArray(cardList) &&
                      cardList.length > 0 &&
                      cardList.map((val) => (
                        <MenuItem
                          key={`card_${val.id}`}
                          classes={{
                            root: classes.bgColor,
                          }}
                          value={val.id}
                        >
                          *** *** **** {val.last4}
                        </MenuItem>
                      ))}
                  </Select>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginTop: 10, marginBottom: 20 }}
                      onClick={() => setNewCard(true)}
                    >
                      Add new card
                    </Button>
                  </div>
                </>
              )}
            </Grid>
          ) : (
            <>
              {isPurchaseSuccessful ? null : (
                <Typography className="mediumfont" style={{ marginTop: 8 }}>
                  {data?.job_title || "-"}
                </Typography>
              )}
              <Typography
                className="regulerfont lightsilvercolor"
                style={{ marginBottom: 10 }}
              >
                {isPurchaseSuccessful
                  ? `Here are the candidate's details, get in touch to hire the candidate.`
                  : data?.job_role || "-"}
              </Typography>
              {isPurchaseSuccessful ? (
                <Typography
                  className="mediumfont"
                  style={{ marginTop: 8, marginBottom: 10 }}
                >
                  {data?.firstname || ""} {data?.lastname || ""}
                </Typography>
              ) : null}
              <div
                className={classes.shadowViewStyle}
                style={{ padding: 10, marginBottom: 20 }}
              >
                {isPurchaseSuccessful ? null : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <Typography className="regulerfont lightsilvercolor">
                      Last log in at {data?.last_login_at || ""}
                    </Typography>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <img
                    src={isPurchaseSuccessful ? Images.phone : Images.location}
                    style={{ height: 26, width: 26, marginRight: 10 }}
                    alt="img"
                  />
                  <Typography className="regulerfont lightsilvercolor">
                    {isPurchaseSuccessful
                      ? data?.phone || "-"
                      : data?.location || "-"}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <img
                    src={isPurchaseSuccessful ? Images.email : Images.orangeP}
                    style={{ height: 26, width: 26, marginRight: 10 }}
                    alt="img"
                  />
                  <Typography className="regulerfont lightsilvercolor">
                    {isPurchaseSuccessful
                      ? data?.email || "-"
                      : data?.job_role_type === "p_full_time" ||
                        data?.job_role_type === "t_full_time"
                      ? `${data?.charge_per_year} per year`
                      : data?.job_role_type === "p_part_time" ||
                        data?.job_role_type === "t_part_time"
                      ? `${data?.charge_per_hour} per hour`
                      : data?.job_role_type === "contract" ||
                        data?.job_role_type === "any"
                      ? `${data?.charge_per_hour} per hour, ${data?.charge_per_year} per year`
                      : "-"}
                  </Typography>
                </div>

                {isPurchaseSuccessful ? null : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    <EventAvailable
                      style={{ marginRight: 10, color: "#D6AC85" }}
                    />
                    <Typography className="regulerfont lightsilvercolor">
                      Ready to hire {data?.availablity}
                    </Typography>
                  </div>
                )}

                {isPurchaseSuccessful
                  ? null
                  : isArray(skillArr) &&
                    skillArr.length > 0 && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={Images.orangeS}
                          style={{ height: 26, width: 26, marginRight: 10 }}
                          alt="img"
                        />
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {isArray(skillArr) &&
                            skillArr.length > 0 &&
                            skillArr.map((ii) => {
                              return (
                                <div
                                  key={`skillArr_${ii.value}`}
                                  className={classes.skillsmain}
                                >
                                  <Typography className="lightsilvercolor regulerfont">
                                    {ii}
                                  </Typography>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
              </div>
            </>
          )}

          {isCardDetail || isPurchaseSuccessful ? null : (
            <Typography className="mediumfont lightsilvercolor">
              £{data?.charge_amount} to unlock the profile
            </Typography>
          )}

          {isPurchaseSuccessful || newCard ? null : (
            <div
              style={{
                marginTop: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {isCardDetail ? (
                <Grid xs={5.8} style={{ marginRight: 10 }}>
                  <Button
                    variant="outlined"
                    style={{ width: "100%" }}
                    onClick={() => {
                      resetFormData();
                    }}
                  >
                    Back
                  </Button>
                </Grid>
              ) : null}
              <Grid xs={isCardDetail ? 5.8 : 12}>
                <Button
                  variant={btnLoad ? "outlined" : "contained"}
                  style={{ width: "100%" }}
                  onClick={() => {
                    if (isCardDetail) {
                      if (selectedCard === "0") {
                        toast.error("Please select card");
                      } else {
                        payment(selectedCard);
                      }
                    } else {
                      setCardDetail(true);
                    }
                  }}
                  disabled={btnLoad}
                >
                  {btnLoad ? (
                    <CircularProgress size={28} />
                  ) : isCardDetail ? (
                    `£${data?.charge_amount || 0} PAY`
                  ) : (
                    `Connect with Candidate`
                  )}
                </Button>
              </Grid>
            </div>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}

export default MPurchase;
