import { Map, Marker, GoogleApiWrapper, Circle } from "google-maps-react";

function MapContainer(props) {
  const { data = {}, miles = 0 } = props;

  const radius = miles * 1609.344;
  return (
    <Map
      google={window.google}
      initialCenter={{ lat: data?.lat, lng: data?.lng }}
      center={{ lat: data?.lat, lng: data?.lng }}
    >
      {data?.lat && data?.lng && (
        <Marker
          title={data?.location || ""}
          position={{ lat: data?.lat, lng: data?.lng }}
        />
      )}
      {data?.lat && data?.lng && (
        <Circle
          radius={radius}
          center={{ lat: data?.lat, lng: data?.lng }}
          strokeColor="transparent"
          strokeOpacity={0}
          strokeWeight={5}
          fillColor="#FF0000"
          fillOpacity={0.2}
        />
      )}
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyA3brzWUkPZefbmCIXng1Zd2X1SyetcSa4",
})(MapContainer);
