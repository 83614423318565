import React, { useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { isArray } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getApiData } from "../../Utils/APIHelper";
import { Setting } from "../../Utils/Setting";
import useStyles from "./styles";

function CVModal(props) {
  const { visible = false, handleModal = () => null, data = {} } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { token } = useSelector((state) => state.auth);
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [btnLoad, setBtnLoad] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: md ? 300 : 600,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: 550,
  };

  // this function for update data from CV
  async function updateData() {
    setBtnLoad(true);
    try {
      const response = await getApiData(
        Setting.endpoints.employeeImportCV,
        "POST",
        data,
        {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      );

      if (response.status) {
        toast.success(response.message);
        if (handleModal) {
          handleModal("success");
        }
      } else {
        toast.error(response.message);
      }
      setBtnLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setBtnLoad(false);
    }
  }

  return (
    <Modal
      open={visible}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <Typography
            variant="subtitle1"
            component="h2"
            className={classes.modalTitle}
          >
            Imported Data From CV
          </Typography>
          <Typography className={classes.noteTextStyle}>
            Note: This is the preview after the importing the CV the data is not
            precise. Click on 'update' to edit the information.
          </Typography>
          <div className="sliderscroll" style={{ height: 350 }}>
            {data?.name && (
              <div>
                <Typography className={classes.sectionTitle}>Name</Typography>
                <Typography className={classes.sectionDesc}>
                  {data?.name || "-"}
                </Typography>
              </div>
            )}
            {data?.phone && (
              <div>
                <Typography className={classes.sectionTitle}>Phone</Typography>
                <Typography className={classes.sectionDesc}>
                  {data?.phone || "-"}
                </Typography>
              </div>
            )}
            {isArray(data?.experience) && data?.experience.length > 0 && (
              <div>
                <Typography className={classes.sectionTitle}>
                  Experience
                </Typography>
                {data?.experience.map((item, index) => {
                  return (
                    <div
                      key={`experience_${item?.organization || index}`}
                      style={{ marginTop: 10 }}
                    >
                      <Typography style={{ fontFamily: "PoppinsMedium" }}>
                        {item?.organization || ""}
                      </Typography>
                      <Typography className={classes.sectionDesc}>
                        {`${item?.from_date || "-"} ${
                          item?.to_date ? "-" : ""
                        } ${item?.to_date || ""}`}
                      </Typography>
                      <Typography className={classes.sectionDesc}>
                        {item?.description || ""}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            )}
            {isArray(data?.skills) && data?.skills.length > 0 && (
              <div>
                <Typography className={classes.sectionTitle}>Skills</Typography>
                <div className={classes.skillDivStyle}>
                  {data?.skills.map((item) => {
                    return (
                      <div
                        key={`skill${item}_s`}
                        className={classes.tagViewStyle}
                      >
                        <Typography
                          variant="body2"
                          style={{ color: "#5A5A5A" }}
                        >
                          {item}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {isArray(data?.education) && data?.education.length > 0 && (
              <div>
                <Typography className={classes.sectionTitle}>
                  Education
                </Typography>
                {data?.education.map((item, index) => {
                  return (
                    <div key={`educations_${index}`} style={{ marginTop: 10 }}>
                      <Typography style={{ fontFamily: "PoppinsMedium" }}>
                        {item?.title || ""}
                      </Typography>
                      <Typography className={classes.sectionDesc}>
                        {item?.organization || ""}
                      </Typography>
                      <Typography className={classes.sectionDesc}>
                        {item?.dates?.toString() || ""}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            )}
            {data?.address && (
              <div>
                <Typography className={classes.sectionTitle}>
                  Address
                </Typography>
                <Typography className={classes.sectionDesc}>
                  {data?.address || "-"}
                </Typography>
              </div>
            )}
          </div>
          <div
            className={classes.splitViewStyle}
            style={{ justifyContent: "unset" }}
          >
            <Button
              variant="outlined"
              color="primary"
              className={classes.modalBtnStyle}
              fullWidth
              onClick={() => {
                if (handleModal) {
                  handleModal();
                }
              }}
              disabled={btnLoad}
            >
              Cancel
            </Button>
            <div style={{ width: 50 }} />
            <Button
              variant={btnLoad ? "outlined" : "contained"}
              color="primary"
              className={classes.modalBtnStyle}
              fullWidth
              onClick={updateData}
              disabled={btnLoad}
            >
              {btnLoad ? <CircularProgress size={28} /> : "Update"}
            </Button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default CVModal;
