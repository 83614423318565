import types from "./actions";

const initialState = {
  useruuid: "",
  userdata: {},
  dropdowndata: {},
  token: "",
  isNotifiy: false,
  reviewdata: {},
  candidateProfile: {},
  communicationPreference: {},
  welcomeScreen: true,
  visibility: 0,
  isOnline: true,
  isBack: 0,
  rating: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_DATA:
      localStorage.setItem("userData", JSON.stringify(action.userdata));
      return {
        ...state,
        userdata: action.userdata,
      };

    case types.SET_DROPDOWN_DATA:
      localStorage.setItem("dropDownData", action.dropdowndata);
      return {
        ...state,
        dropdowndata: action.dropdowndata,
      };

    case types.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    case types.SET_RATING:
      return {
        ...state,
        rating: action.rating,
      };

    case types.SET_VISIBILITY:
      return {
        ...state,
        visibility: action.visibility,
      };

    case types.SET_USER_UUID:
      return {
        ...state,
        useruuid: action.useruuid,
      };

    case types.SET_DISPLAY_NOTIFICATION_POP_UP:
      return {
        ...state,
        isNotifiy: action.isNotifiy,
      };

    case types.SET_NOTI_DATA:
      return {
        ...state,
        notiData: action.notiData,
      };

    case types.SET_REVIEW:
      localStorage.setItem("reviewdata", action.reviewdata);
      return {
        ...state,
        reviewdata: action.reviewdata,
      };

    case types.SET_WELCOME_SCREEN:
      return {
        ...state,
        welcomeScreen: action.welcomeScreen,
      };

    case types.SET_CANDIDATEPROFILE:
      localStorage.setItem("candidateProfile", action.candidateProfile);
      return {
        ...state,
        candidateProfile: action.candidateProfile,
      };

    case types.SET_COMMUNICATION:
      localStorage.setItem(
        "communicationPreference",
        action.communicationPreference
      );
      return {
        ...state,
        communicationPreference: action.communicationPreference,
      };

    case types.CLEAR_ALL_STORAGE_DATA:
      // localStorage.clear();
      localStorage.removeItem("userData", "welcomeScreen", "token");
      return {
        ...state,
        userdata: {},
        token: "",
        welcomeScreen: true,
        isNotifiy: false,
      };

    case types.ONLINE_STATUS:
      return {
        ...state,
        isOnline: action.isOnline,
      };

    // signup steper back
    case types.BACK_ACTION:
      return {
        ...state,
        isBack: action.isBack,
      };

    default:
      return state;
  }
}
