import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import { Grid, Typography } from "@mui/material";
import { AddCircle, Info } from "@mui/icons-material";
import { isArray, isEmpty } from "lodash";
import moment from "moment";
import MExperience from "../Modal/MExperience";
import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import { useSelector } from "react-redux";
import useStyles from "../styles";
import { Setting } from "../../../Utils/Setting";
import { toast } from "react-toastify";
import { getAPIProgressData, getApiData } from "../../../Utils/APIHelper";
import ConfirmDialog from "../../Modal/ConfirmDialog";

function Experience(props, ref) {
  const { from = "", data = {}, getProfileData = () => null } = props;
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [loader, setLoader] = useState(false);
  const [itemData, setItemData] = useState({});

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [deleteType, setDeleteType] = useState({});

  useEffect(() => {
    if (!modal) {
      setItemData({});
    }
  }, [modal]);

  useImperativeHandle(ref, () => ({
    handleModal: () => {
      handleModal();
    },
  }));

  function handleModal() {
    setModal(true);
  }

  const isModify = !isEmpty(itemData);
  const header = { Authorization: `Bearer ${token}` };

  async function userExperience(data) {
    const datet = data?.endDate
      ? moment(data?.endDate).format("YYYY-MM-DD")
      : "";
    const datef = data?.startDate
      ? moment(data?.startDate).format("YYYY-MM-DD")
      : "";

    setLoader(true);
    const experienceData = {
      "CandidateExperience[company_name]": data?.companyName,
      "CandidateExperience[company_website]": data?.website,
      "CandidateExperience[from_date]": datef,
      "CandidateExperience[to_date]": datet,
      "CandidateExperience[currently_working]": data?.currentlyWork ? 1 : 0,
      "CandidateExperience[description]": data?.description,
    };

    if (isModify) {
      experienceData["CandidateExperience[id]"] = itemData?.id;
    }

    try {
      let endPoint = Setting.endpoints.employeeAddExperience;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        experienceData,
        header
      );
      if (response?.status) {
        setModal(false);
        getProfileData();
        setLoader(false);
      } else {
        //display error msg here
        toast.error(response?.message);
        setLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setLoader(false);
    }
  }

  async function deleteExperience() {
    setRemoveBtnLoad(true);
    try {
      let endPoint = `${Setting.endpoints.employeeDeleteExperience}?id=${deleteType?.id}`;
      const response = await getApiData(endPoint, "GET", null, header);
      if (response && response.status && response.status === true) {
        getProfileData();
      } else {
        console.log("err==>>");
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (err) {
      setRemoveBtnLoad(false);
      console.log("Catch Part", err);
      err("Something_went_wrong");
    }
  }

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="experience" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>Experience</Typography>
        {from === "employee" ? null : (
          <AddCircle
            color={color.primary}
            className={classes.addIcon}
            onClick={() => setModal(true)}
          />
        )}
      </div>
      {isArray(data?.user_experience) && data?.user_experience.length > 0 ? (
        data?.user_experience.map((item) => {
          const missingData =
            isEmpty(item?.company_name) ||
            isEmpty(item?.company_website) ||
            isEmpty(item?.from_date) ||
            isEmpty(item?.description);
          return (
            <div key={`key_${item.company_website}`}>
              <div
                className={`${classes.splitViewStyle} ${classes.contentDetailContainer}`}
              >
                <Typography style={{ fontFamily: "PoppinsMedium" }}>
                  {item?.company_name || ""}
                </Typography>
                {from === "employee" ? null : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {missingData && (
                      <div className={classes.missingDataDiv}>
                        <Info className={classes.infoIconStyle} />
                        <Typography className={classes.sectionDesc}>
                          Missing info
                        </Typography>
                      </div>
                    )}
                    <div
                      className={classes.editBtnStyle}
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        setItemData(item);
                        setModal(true);
                      }}
                    >
                      <img src={Images.edit} style={{ width: 14 }} />
                    </div>
                    <div
                      className={`${classes.editBtnStyle} ${classes.deleteBtnStyle}`}
                      onClick={() => {
                        setConfirmDialog(true);
                        setDeleteType(item);
                      }}
                    >
                      <img src={Images.odelete} style={{ width: 14 }} />
                    </div>
                  </div>
                )}
              </div>
              <Typography className={classes.sectionDesc}>
                {item?.company_website || ""}
              </Typography>
              {item?.from_date && (
                <Typography className={classes.sectionDesc}>
                  {`${moment(item.from_date).format("DD MMM YYYY")} - ${
                    item?.currently_working === 0
                      ? moment(item.to_date).format("DD MMM YYYY")
                      : "Currently working"
                  }`}
                </Typography>
              )}
              <Typography
                className={classes.sectionDesc}
                style={{ whiteSpace: "pre-line", wordBreak: "break-all" }}
              >
                {item?.description || ""}
              </Typography>
            </div>
          );
        })
      ) : (
        <Typography className={classes.sectionDesc}>
          {from === "employee"
            ? "Fresher"
            : "Tell employers about your most recent employment experience."}
        </Typography>
      )}
      <MExperience
        visible={modal}
        handleModal={() => setModal(false)}
        onSave={(data) => {
          userExperience(data);
        }}
        loader={loader}
        itemData={itemData}
      />
      <ConfirmDialog
        title={`Are you sure you want to delete this experience?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            deleteExperience();
          } else {
            setConfirmDialog(false);
            setDeleteType({});
          }
        }}
        btnLoad={removeBtnLoad}
      />
    </Grid>
  );
}
export default forwardRef(Experience);
