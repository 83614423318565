import { Grid, Typography, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import React, { useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import GoogleLogin from "react-google-login";
import _ from "lodash";
import useStyles from "./styles";
import Images from "../../Config/Images";
import { color } from "../../Config/theme";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import authActions from "../../Redux/reducers/auth/actions";
import { toast } from "react-toastify";

const { setUserData, setToken } = authActions;
function GoogleLoginButton(props) {
  const platForm = isAndroid ? "android" : isIOS ? "ios" : "android";
  const {
    fullWidth = false,
    from = "",
    type = "",
    onGetUserInfo = () => null,
    onCloseModal = () => null,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [buttonLoader, setButtonLoader] = useState(false);
  const { useruuid } = useSelector((state) => state.auth);

  async function signUpAction(accessToken) {
    if (_.isString(accessToken) && !_.isEmpty(accessToken)) {
      const data = {
        "SocialForm[social_type]": 1, // 1 For Google, 2 For Linked, & 3 for Facebook
        "SocialForm[uuid]": useruuid,
        "SocialForm[platform]": platForm,
        "SocialForm[token]": accessToken,
      };

      try {
        let endPoint = Setting.endpoints.social_sign_up;
        const response = await getAPIProgressData(endPoint, "POST", data);

        if (response?.status) {
          setButtonLoader(false);
          onGetUserInfo(response?.data);
        } else {
          setButtonLoader(false);
          toast.error(response?.message);
        }
      } catch (err) {
        console.log("Catch Part", err);
        setButtonLoader(false);
      }
    }
  }

  async function loginAction(accessToken) {
    if (_.isString(accessToken) && !_.isEmpty(accessToken)) {
      const data = {
        "SocialForm[social_type]": 1, // 1 For Google, 2 For Linked, & 3 for Facebook
        "SocialForm[uuid]": useruuid,
        "SocialForm[platform]": platForm,
        "SocialForm[token]": accessToken,
      };

      try {
        let endPoint = Setting.endpoints.social_login;
        const response = await getAPIProgressData(endPoint, "POST", data);

        if (response?.status) {
          if (response?.data?.role === "employer") {
            navigate("/employer/company");
          } else {
            navigate("/candidate/profile");
          }
          toast.success("Logged in successfully");
          dispatch(setUserData(response?.data));
          dispatch(setToken(response?.data?.access_token || ""));
          setButtonLoader(false);
          onCloseModal();
        } else {
          setButtonLoader(false);
          toast.error(response?.message);
        }
      } catch (err) {
        console.log("Catch Part", err);
        setButtonLoader(false);
        onCloseModal();
      }
    }
  }

  function responseGoogle(response) {
    const accessToken = response?.accessToken;

    if (from === "SignUp") {
      // Sign Up with Google Account
      signUpAction(accessToken);
    } else {
      // Login With Google Account
      loginAction(accessToken);
    }
  }

  return (
    <GoogleLogin
      clientId={Setting.GOOGLE_CLIENT_ID}
      onSuccess={responseGoogle}
      onFailure={(fail) => {
        setButtonLoader(false);
      }}
      autoLoad={false}
      cookiePolicy={"single_host_origin"}
      render={(renderProps) =>
        type === "employer" ? (
          buttonLoader ? (
            <CircularProgress
              style={{
                height: 26,
                width: 26,
                marginRight: 20,
                color: color.primary,
              }}
            />
          ) : (
            <img
              src={Images?.google}
              alt="error"
              style={{
                height: 30,
                width: 30,
                marginRight: 20,
                cursor: "pointer",
              }}
              onClick={
                buttonLoader
                  ? null
                  : () => {
                      setButtonLoader(true);
                      renderProps.onClick();
                    }
              }
            />
          )
        ) : (
          <Grid
            container
            className={`${classes.button_main_con} ${
              fullWidth ? classes.fullWidthDiv : ""
            }`}
            onClick={
              buttonLoader
                ? null
                : () => {
                    setButtonLoader(true);
                    renderProps.onClick();
                  }
            }
            style={{ marginBottom: 15 }}
          >
            {buttonLoader ? (
              <CircularProgress
                style={{
                  width: 15,
                  height: 15,
                  color: color.primary,
                }}
              />
            ) : (
              <>
                <img
                  alt="google_logo"
                  src={Images.google_logo}
                  style={{
                    width: "30px",
                    objectFit: "contain",
                    marginRight: 10,
                  }}
                />
                <Typography
                  className={classes.button_name}
                  style={{ marginRight: 20 }}
                >
                  {from === "SignUp"
                    ? "Sign up with Google"
                    : "Log In with Google"}
                </Typography>
              </>
            )}
          </Grid>
        )
      }
    />
  );
}

GoogleLoginButton.propTypes = {};

GoogleLoginButton.defaultProps = {};

export default GoogleLoginButton;
