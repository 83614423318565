import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  loaderView: {
    width: "100%",
    minHeight: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    marginTop: "3% !important",
    marginBottom: "50px !important",
    "@media (max-width:1024px)": {
      marginTop: "3% !important",
    },
    "@media (max-width:425px)": {
      marginTop: "6% !important",
    },
  },
  shadowViewStyle: {
    boxShadow: "rgb(48 48 48 / 14%) 0px 3px 6px 2px",
    borderRadius: 3,
  },
  singleViewStyle: {
    display: "flex",
    padding: "12px 20px",
    cursor: "pointer",
    "@media (max-width:1024px)": {
      padding: "12px 15px",
    },
  },
  paddingStyle: {
    padding: "20px 25px",
  },
  marginDiv: {
    marginBottom: "24px !important",
  },
  detailContainer: {
    "@media (max-width:425px)": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },
  sectionTitle: {
    color: color.primary,
    fontFamily: "PoppinsSemiBold !important",
    fontSize: "20px !important",
  },
  sectionDesc: {
    color: color.black,
    fontFamily: "PoppinsRegular !important",
    fontSize: "14px !important",
    margin: "5px 0px !important",
  },
}));

export default useStyles;
