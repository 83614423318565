import { makeStyles } from "@mui/styles";
import { color } from "../../Config/theme";

const useStyles = makeStyles((theme) => ({
  shadowViewStyle: {
    boxShadow: "rgb(48 48 48 / 14%) 0px 3px 6px 2px",
    borderRadius: 3,
    height: "100%",
  },
  singleViewStyle: {
    display: "flex",
    padding: "10px 20px",
    cursor: "pointer",
  },
  paddingStyle: {
    padding: "20px 25px",
  },
  marginDiv: {
    marginBottom: "24px !important",
  },
  splitViewStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  addIcon: {
    color: color.primary,
    fontSize: "35px !important",
    cursor: "pointer",
    "&:hover": {
      color: "#243C53",
    },
  },
  sectionTitle: {
    color: color.primary,
    fontFamily: "PoppinsSemiBold !important",
    fontSize: "20px !important",
  },
  sectionDesc: {
    color: color.black,
    fontFamily: "PoppinsRegular !important",
    fontSize: "14px !important",
    margin: "5px 0px !important",
  },
  inputFieldStyle: {
    marginBottom: "15px !important",
  },
  closeBtnStyle: { height: 40, width: 40, cursor: "pointer" },
  formDivStyle: { margin: "25px 0px 5px" },
  angleBtnStyle: { width: 18, cursor: "pointer" },
  modalBtnStyle: { minWidth: "140px !important" },
  cViewStyle: {
    backgroundColor: color.primary,
    padding: 10,
    color: color.white,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    fontSize: 14,
    marginRight: -14,
  },
  tagViewStyle: {
    position: "relative",
    border: "0.5px solid #1F4771",
    background: "#F5F9FF 0% 0% no-repeat padding-box",
    borderRadius: 4,
    padding: "10px 15px",
    marginRight: 18,
    marginBottom: 18,
  },
  closeIconDivStyle: {
    position: "absolute",
    backgroundColor: color.primary,
    height: 20,
    width: 20,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: -10,
    right: -10,
    cursor: "pointer",
  },
  closeIconStyle: { fontSize: "15px !important", color: color.white },
  skillDivStyle: { display: "flex", flexWrap: "wrap" },
  editBtnStyle: {
    height: "30px",
    width: "30px",
    borderRadius: "15px",
    backgroundColor: color.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    boxShadow: "0px 3px 6px #00000052",
  },
  deleteBtnStyle: {
    backgroundColor: color.white,
    border: `1px solid ${color.secondary}`,
    borderRadius: "100%",
  },
  contentDetailContainer: {
    marginTop: 20,
  },
  contentDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  singleDetailDivStyle: { width: "50%", paddingRight: 15 },
  contentTitleStyle: { fontFamily: "PoppinsMedium" },
  checkBoxText: {
    color: color.primary,
  },
  pickerStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 12px",
    cursor: "pointer",
  },
  modalTitle: {
    "@media (max-width:768px)": {
      fontSize: "24px !important",
    },
  },
  mb20: {
    marginBottom: 20,
  },
  mb15: {
    marginBottom: "15px !important",
  },
  benefitContainer: {
    display: "flex",
    alignItems: "center",
    "@media (max-width:768px)": {
      paddingRight: 10,
    },
  },
  dotStyle: {
    height: 6,
    width: 6,
    borderRadius: "100%",
    backgroundColor: color.primary,
    marginRight: 8,
  },
  socialDiv: {
    height: 35,
    width: 35,
    borderRadius: "100%",
    backgroundColor: color.primary,
    boxShadow: "0px 3px 6px #00000052",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15,
    cursor: "pointer",
    "@media (max-width:768px)": {
      height: 30,
      width: 30,
    },
  },
  sliderImg: {
    height: 180,
    width: 250,
    borderRadius: 2,
    objectFit: "cover",
    "@media (max-width:1024px)": {
      height: 160,
      width: 210,
    },
    "@media (max-width:768px)": {
      height: 110,
      width: 130,
    },
    "@media (max-width:490px)": {
      height: 200,
      width: "100%",
    },
  },
  arrowStyle: {
    color: color.white,
    fontSize: "22px !important",
    "@media (max-width:768px)": {
      fontSize: "20px !important",
    },
  },
  benefitMainContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  addimage: {
    boxShadow: "0px 0px 0px !important",
    height: "100%",
    width: "100%",
  },
  imgMain3: {
    border: "1px dashed #1F4771 !important",
    height: 180,
    width: "100%",
    borderRadius: 4,
  },
  companylogo: {
    border: "1px dashed #1F4771 !important",
    height: 120,
    width: 120,
    borderRadius: 4,
  },
  arrimagestyle: {
    borderRadius: 4,
    height: 130,
    marginBottom: "20px !important",
    marginRight: "20px !important",
    border: "1px dashed #1F4771 !important",
    position: "relative",
    "@media (max-width:490px)": {
      marginRight: "0px !important",
    },
  },
  deletemainicon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 10,
    right: 10,
    height: 28,
    width: 28,
    borderRadius: 14,
    background:
      "transparent linear-gradient(228deg, #E54545 0%, #D40101 100%) 0% 0% no-repeat padding-box",
    cursor: "pointer",
    boxShadow: "0px 3px 6px #00000052",
  },
  skillBtnView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "@media (max-width:768px)": {
      justifyContent: "unset",
      marginTop: 15,
    },
  },
  skillsmain: {
    padding: "6px 8px",
    border: "1px solid #1F4771",
    background: "#F5F9FF 0% 0% no-repeat padding-box",
    borderRadius: 4,
    marginRight: 8,
    marginBottom: 6,
  },
  addImageBtn: {
    marginRight: "10px !important",
    "@media (max-width:425px)": {
      fontSize: "12px !important",
    },
    "@media (max-width:375px)": {
      fontSize: "10px !important",
    },
  },
  addImgButton: {
    marginRight: "10px !important",
    "@media (max-width:425px)": {
      fontSize: "12px !important",
    },
    "@media (max-width:375px)": {
      fontSize: "10px !important",
    },
  },
  customImgSlider: {
    display: "flex",
    alignItems: "center",
    "@media (max-width:425px)": {
      display: "unset",
      "& > div": {
        marginBottom: "15px",
      },
    },
  },
  missingDataDiv: {
    display: "flex",
    alignItems: "center",
    marginRight: 15,
  },
  infoIconStyle: {
    fontSize: 30,
    marginRight: 5,
    color: "#D40101",
  },
  mapContainerStyle: { position: "relative", width: "100%", height: 250 },
}));

export default useStyles;
