import React from "react";
import { Typography, Button, Grid, CircularProgress } from "@mui/material";
import { isEqual } from "lodash";
import useStyles from "../styles";
import moment from "moment";

function JobDetail(props) {
  const {
    data = {},
    activeTab = "1",
    updateStatus = () => null,
    interestBtnLoad = {},
  } = props;
  const classes = useStyles();

  const btnLoad = isEqual(data, interestBtnLoad);
  return (
    <Grid container className={classes.container}>
      <Grid xs={12} style={{ marginBottom: 15 }}>
        {data?.is_payment ? (
          <Typography className={classes.sectionTitle}>
            {data?.company_name || data?.employer_details?.company_name || ""}
          </Typography>
        ) : null}
        <Typography style={{ fontFamily: "PoppinsMedium" }}>
          {data?.title || data?.job_title || ""}
        </Typography>
      </Grid>
      <Grid xs={12} sm={3} className={classes.singleDetail}>
        <Typography className={classes.sectionDesc}>
          {data?.rate ? "Rate" : "Salary"}
        </Typography>
        {data?.job_role_type === "contract" || data?.job_role_type === "any" ? (
          <>
            {data?.charge_per_year && (
              <Typography className={classes.sectionSubDetail}>
                {`£${data?.charge_per_year} per year`}
              </Typography>
            )}
            {data?.charge_per_hour && (
              <Typography className={classes.sectionSubDetail}>
                {`£${data?.charge_per_hour} per hour`}
              </Typography>
            )}
          </>
        ) : (
          <Typography className={classes.sectionSubDetail}>
            {data?.rate
              ? data?.rate
              : data?.job_role_type === "p_full_time" ||
                data?.job_role_type === "t_full_time"
              ? `£${data?.charge_per_year}`
              : data?.job_role_type === "p_part_time" ||
                data?.job_role_type === "t_part_time"
              ? `£${data?.charge_per_hour}`
              : "0"}
          </Typography>
        )}
      </Grid>
      <Grid xs={12} sm={2} md={3} className={classes.singleDetail}>
        <Typography className={classes.sectionDesc}>Postcode</Typography>
        <Typography className={classes.sectionSubDetail}>
          {data?.location || "Remote"}
        </Typography>
      </Grid>
      <Grid xs={12} sm={2} className={classes.singleDetail}>
        <Typography className={classes.sectionDesc}>Expiring on</Typography>
        <Typography className={classes.sectionSubDetail}>
          {moment(new Date(data?.expired_date)).format("DD MMM YYYY") || "-"}
        </Typography>
      </Grid>
      <Grid xs={12} sm={2} className={classes.singleDetail}>
        <Typography className={classes.sectionDesc}>Duration</Typography>
        <Typography className={classes.sectionSubDetail}>
          {data?.job_role_type === "p_full_time"
            ? "Permanent - Full Time"
            : data?.job_role_type === "p_part_time"
            ? "Permanent - Part Time"
            : data?.job_role_type === "t_full_time"
            ? "Temporary - Full Time"
            : data?.job_role_type === "t_part_time"
            ? "Temporary - Part Time"
            : data?.job_role_type === "any"
            ? "Flexible"
            : "Contract" || "-"}
        </Typography>
      </Grid>
      {/* {activeTab != 6 && ( */}
      <Grid xs={12} sm={3} md={2} className={classes.centeredDetail}>
        <Button
          variant={btnLoad ? "outlined" : "contained"}
          color="primary"
          fullWidth
          className={`${classes.btnStyle} ${
            activeTab === "2" ? classes.respBtn : ""
          }`}
          onClick={() => updateStatus(data, activeTab)}
          disabled={btnLoad}
        >
          {btnLoad ? (
            <CircularProgress size={28} />
          ) : activeTab === "2" ? (
            "Not Interested"
          ) : (
            "Interested"
          )}
        </Button>
      </Grid>
      {/* )} */}
    </Grid>
  );
}

export default JobDetail;
