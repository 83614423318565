import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import _, { isArray, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Images from "../../../../Config/Images";
import { getApiData, getAPIProgressData } from "../../../../Utils/APIHelper";
import { Setting } from "../../../../Utils/Setting";
import useStyles from "../../styles";

function MCulture(props) {
  const {
    visible = false,
    handleModal = () => null,
    type = "",
    cultureDetail = "",
    images = [],
    companyId = null,
  } = props;
  const { token } = useSelector((state) => state.auth);
  const [imageArr, setImageArr] = useState([]);
  const [imageFilesArr, setImageFilesArr] = useState([]);
  const [cultureText, setCultureText] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const [deleteBtnLoad, setDeleteBtnLoad] = useState(null);
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : md ? 500 : 800,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: 550,
  };

  useEffect(() => {
    if (visible) {
      setCultureText(cultureDetail);
    }
  }, [visible]);

  useEffect(() => {
    setImageArr(images || []);
    setImageFilesArr(images || []);
  }, [visible, images]);

  function uploadItem(file) {
    const dummy_arr = [...imageArr];
    const dummy_arr1 = [...imageFilesArr];
    Object.keys(file).map((item) => {
      dummy_arr.push(URL.createObjectURL(file[item]));
      dummy_arr1.push(file[item]);
    });
    // const displayUrl = URL.createObjectURL(file);
    // file.image = displayUrl;
    // dummy_arr.push(file);
    setImageArr(dummy_arr);
    setImageFilesArr(dummy_arr1);
  }

  function resetFormData(type = "", data = "", key = "", point = "") {
    if (point !== "remove") {
      setImageArr([]);
      setImageFilesArr([]);
    }
    setCultureText("");
    if (type === "close" && handleModal) {
      handleModal();
    } else if (type === "update") {
      handleModal(data, type, key, point);
    }
  }

  async function removeImage(index, sImg) {
    if (!sImg.id) {
      const arr = [...imageArr];
      const arr1 = [...imageFilesArr];
      arr.map((i, ii) => {
        if (ii === index) {
          arr.splice(ii, 1);
          arr1.splice(ii, 1);
        }
        setImageArr(arr);
        setImageFilesArr(arr1);
      });
    } else {
      try {
        setDeleteBtnLoad(index);
        const response = await getApiData(
          `${Setting.endpoints.employerRemoveCompanyImage}?id=${sImg.id}`,
          "GET",
          {},
          {
            Authorization: `Bearer ${token}`,
          }
        );

        if (response.status) {
          const arr = [...imageArr];
          const arr1 = [...imageFilesArr];
          arr.map((i, ii) => {
            if (ii === index) {
              arr.splice(ii, 1);
              arr1.splice(ii, 1);
            }
            setImageArr(arr);
            setImageFilesArr(arr1);
          });
          resetFormData(
            "update",
            response?.data?.company_images,
            "company_images",
            "remove"
          );
        } else {
          toast.error(response.message);
        }
        setDeleteBtnLoad(null);
      } catch (error) {
        console.log("error ===>>>", error);
        toast.error(error.toString());
        setDeleteBtnLoad(null);
      }
    }
  }

  // this function checks validation
  function validation() {
    if (type === "culture") {
      if (isEmpty(cultureText.trim())) {
        toast.error("Please enter text");
      } else {
        submitData();
      }
    } else {
      if (isArray(imageArr) && imageArr.length > 0) {
        const arr = [];
        imageFilesArr.map((item, index) => {
          if (!item?.image) {
            arr.push(item);
          }
        });

        if (isArray(arr) && arr.length > 0) {
          uploadImages(arr);
        } else {
          toast.error("Please add any new image");
        }
      } else {
        toast.error("Please add any new image");
      }
    }
  }

  // this function for upload company images
  async function uploadImages(nData) {
    const obj = {};
    nData.map((item, index) => {
      obj[`CompanyImages[image][${index}]`] = item;
    });
    obj["CompanyImages[company_id]"] = companyId;

    setBtnLoad(true);
    try {
      const response = await getAPIProgressData(
        Setting.endpoints.employerUploadCompanyImage,
        "POST",
        obj,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response.status) {
        resetFormData("update", response?.data, "company_images");
      } else {
        toast.error(response.message);
      }
      setBtnLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setBtnLoad(false);
      toast.error(error.toString());
    }
  }

  // this function for submit data
  async function submitData() {
    setBtnLoad(true);
    try {
      const response = await getAPIProgressData(
        Setting.endpoints.employerAddCulture,
        "POST",
        {
          "EmployerCompany[company_culture]": cultureText,
        },
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response.status) {
        resetFormData(
          "update",
          response?.data?.company_culture,
          "company_culture"
        );
      } else {
        toast.error(response.message);
      }
      setBtnLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setBtnLoad(false);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => (btnLoad ? null : resetFormData("close"))}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography variant="subtitle1" component="h2">
              Add Culture
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => (btnLoad ? null : resetFormData("close"))}
            />
          </div>
          <div className="sliderscroll">
            {type === "culture" && (
              <Editor
                onEditorChange={(e) => {
                  setCultureText(e);
                }}
                value={cultureText}
                apiKey="fc04elxcr618mmqpqlz178ljh3fnxlmk4yduvz64c9yfgsqk"
                init={{
                  height: 300,
                  plugins: "lists advlist emoticons",
                  // menubar: false,
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor  | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help emoticons",
                }}
              />
            )}
            {type === "image" && (
              <Grid
                container
                style={{
                  overflowX: "auto",
                  height: 400,
                }}
              >
                {isArray(imageArr) &&
                  imageArr.length > 0 &&
                  imageArr.map((ii, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={3.4}
                        md={2.6}
                        className={classes.arrimagestyle}
                      >
                        <img
                          src={ii?.image || ii}
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: 4,
                            objectFit: "cover",
                          }}
                          alt="coverimage"
                        />
                        <div
                          className={classes.deletemainicon}
                          onClick={() => {
                            removeImage(index, ii);
                          }}
                        >
                          {deleteBtnLoad === index ? (
                            <CircularProgress
                              size={16}
                              style={{ color: "#FFF" }}
                            />
                          ) : (
                            <img
                              src={Images.delete}
                              style={{
                                height: "60%",
                                width: "60%",
                                borderRadius: 4,
                              }}
                              alt="coverimage"
                            />
                          )}
                        </div>
                      </Grid>
                    );
                  })}
                <Grid
                  item
                  xs={12}
                  sm={3.4}
                  md={2.6}
                  className={classes.imgMain3}
                  style={{ height: 130 }}
                >
                  <Button component="label" className={classes.addimage}>
                    <Typography
                      className="lightsilvercolor regulerlargefont"
                      style={{ textAlign: "center" }}
                    >
                      Add company image
                    </Typography>
                    <input
                      type="file"
                      multiple
                      onChange={(e) => {
                        uploadItem(e.target.files);
                      }}
                      accept="image/jpeg, image/png, image/jpg"
                      hidden
                    />
                  </Button>
                </Grid>
              </Grid>
            )}
          </div>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: sm ? "space-between" : "unset",
            }}
          >
            <Grid xs={5.8} sm={4} md={3} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
                disabled={btnLoad}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8} sm={4} md={3}>
              <Button
                variant={btnLoad ? "outlined" : "contained"}
                style={{ width: "100%" }}
                onClick={validation}
                disabled={btnLoad}
              >
                {btnLoad ? <CircularProgress size={28} /> : "SAVE"}
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MCulture;
