import React, { useState } from "react";
import { Typography, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { color } from "../../../Config/theme";
import useStyles from "../styles";
import MPurchase from "../../Profile/Modal/MPurchase";
import moment from "moment";

function MJob(props) {
  const { data = {}, updateList = () => null, from = "" } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  // this function for redirect to profile page
  function viewProfile() {
    navigate("/candidate/profile", {
      state: {
        from: "employee",
        jobId: data?.job_post_id,
        userId: from === "ViewAllCandidate" ? data?.id : data?.user_id,
      },
    });
    window.scrollTo(0, 0);
  }

  return (
    <Grid container className={classes.container}>
      <Grid xs={12} sm={3} md={2}>
        <img src={data?.photo} className={classes.cJobImgStyle} />
      </Grid>
      <Grid xs={12} sm={9} md={10} className={classes.mJobContainerStyle}>
        <Grid container style={{ height: "100%" }}>
          <Grid xs={12}>
            <Typography className={classes.sectionTitle}>
              {`${data?.firstname || ""} ${data?.lastname || ""}`}
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Grid container>
              <Grid xs={12} sm={7} md={9}>
                <Grid container>
                  <Grid
                    xs={12}
                    sm={12}
                    md={6}
                    className={classes.matchSingleJobDetail}
                  >
                    <Typography className={classes.sectionDesc}>
                      Expected Salary
                    </Typography>
                    {data?.job_role_type === "contract" ||
                    data?.job_role_type === "any" ? (
                      <>
                        {data?.charge_per_year && (
                          <Typography className={classes.sectionSubDetail}>
                            {`£${data?.charge_per_year || "-"} per year`}
                          </Typography>
                        )}
                        {data?.charge_per_hour && (
                          <Typography className={classes.sectionSubDetail}>
                            {`£${data?.charge_per_hour || "-"} per hour`}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Typography className={classes.sectionSubDetail}>
                        {data?.job_role_type === "p_part_time" ||
                        data?.job_role_type === "t_part_time"
                          ? `£${data?.charge_per_hour} per hour` || ""
                          : data?.job_role_type === "p_full_time" ||
                            data?.job_role_type === "t_full_time"
                          ? `£${data?.charge_per_year} per year` || "-"
                          : null}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={6}
                    className={classes.matchSingleJobDetail}
                  >
                    <Typography className={classes.sectionDesc}>
                      Availability
                    </Typography>
                    <Typography className={classes.sectionSubDetail}>
                      {data?.availablity || "-"}
                    </Typography>
                  </Grid>
                  <Grid xs={12} sm={12} md={6}>
                    <Typography
                      className={classes.sectionDesc}
                      style={{ color: "#000" }}
                    >
                      Recent Experience
                    </Typography>
                    <Typography
                      className={classes.sectionDesc}
                      style={{ color: color.primary }}
                    >
                      {data?.latest_experience?.company_name || "-"}
                    </Typography>
                    <Typography className={classes.sectionDesc}>
                      {data?.latest_experience?.company_website || "-"}
                    </Typography>
                    <Typography className={classes.sectionDesc}>
                      {`${
                        data?.latest_experience?.from_date
                          ? moment(
                              data?.latest_experience?.from_date,
                              "YYYY-MM-DD"
                            ).format("DD MMM YYYY")
                          : ""
                      } - ${
                        data?.latest_experience?.currently_working
                          ? "Working now"
                          : data?.latest_experience?.to_date
                          ? `${moment(data?.latest_experience?.to_date).format(
                              "DD MMM YYYY"
                            )}`
                          : ""
                      }`}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={12}
                    md={6}
                    className={classes.matchSingleJobDetail}
                  >
                    <Typography className={classes.sectionDesc}>
                      Last Log In
                    </Typography>
                    <Typography className={classes.sectionSubDetail}>
                      {data?.last_login_at || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} sm={5} md={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  style={{ marginBottom: 15 }}
                  onClick={viewProfile}
                >
                  View Profile
                </Button>
                {data?.is_payment || from === "ViewAllCandidate" ? null : (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    Unlock Profile
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MPurchase
        visible={openModal}
        data={data || {}}
        jobId={data?.job_post_id || null}
        candidateId={data?.user_id || null}
        handleModal={() => {
          setOpenModal(false);
        }}
        puchaseSuccess={updateList}
      />
    </Grid>
  );
}

export default MJob;
