import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
  Checkbox,
} from "@mui/material";
import useStyles from "../../styles";
import Images from "../../../../Config/Images";
import { isEmpty } from "lodash";
import PlaceAutoComplete from "../../../../Components/PlaceAutoComplete";
import { toast } from "react-toastify";

function MLocation(props) {
  const {
    visible = false,
    handleModal = () => null,
    onSave = () => null,
    editData = {},
  } = props;

  const [remoteOnly, setRemoteOnly] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [locationName, setLocationName] = useState("");

  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (visible) {
      setSelectedLocation(editData?.selectedLocation || {});
      setRemoteOnly(editData?.remoteOnly || false);
    }

    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  useEffect(() => {
    setLocationName(selectedLocation?.location || "");
  }, [selectedLocation]);

  function checkValidation() {
    let valid = true;

    if (!remoteOnly && isEmpty(selectedLocation?.location)) {
      valid = false;
      toast.error("Please enter postcode or Remote only");
    }

    if (valid) {
      const locationData = {
        remoteOnly: remoteOnly,
        selectedLocation: selectedLocation,
      };
      onSave(locationData);
    }
  }

  function resetFormData(type = "") {
    setRemoteOnly("");
    setRemoteOnly(false);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle1"
              component="h2"
              className={classes.modalTitle}
            >
              Add postcode
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          {!isEmpty(editData) && !remoteOnly && !isEmpty(locationName) ? (
            <Typography
              style={{ marginBottom: 10 }}
              className="primarycolor semibold18"
            >{`Postcode: ${locationName}`}</Typography>
          ) : null}
          <PlaceAutoComplete
            placeholder="Enter postcode for the UK only"
            style={{ marginBottom: 10, width: "100%" }}
            onChange={(obj) => setSelectedLocation(obj)}
            disable={remoteOnly ? true : false}
          />
          <Typography
            className="primarycolor mediumfont"
            style={{ marginBottom: 8, marginTop: 8 }}
          >
            Remote only
          </Typography>
          <div className="checkboxmain">
            <Checkbox
              checked={remoteOnly}
              onChange={() => {
                setRemoteOnly(!remoteOnly);
              }}
            />
            <Typography className="lightsilvercolor regulerfont">
              Remote
            </Typography>
          </div>
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid xs={5.8} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8}>
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={() => {
                  checkValidation();
                }}
              >
                SAVE
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MLocation;
