/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Grid,
  Box,
  Button,
  Checkbox,
  useTheme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import useStyles from "./styles";
import { Setting } from "../../../Utils/Setting";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MChange from "../../../Components/Modal/ChangePassModal";
import authActions from "../../../Redux/reducers/auth/actions";
import ConfirmDialog from "../../../Components/ConfirmDialog";
import { toast } from "react-toastify";
import { communicationData } from "../../../Config/Static_Data";
import { getAPIProgressData, getApiData } from "../../../Utils/APIHelper";

const { setCommunication, clearAllData, setVisibility } = authActions;

export default function Account() {
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    token,
    candidateProfile,
    communicationPreference,
    userdata,
    visibility,
  } = useSelector((state) => state.auth);

  const isSocial = userdata?.is_social ? true : false;
  const [communicationArr, setCommunicationArr] = useState(communicationData);
  const [chnagePassModal, setChnagePassModal] = useState(false);
  const [visibility1, setVisibility1] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);

  const [loader, setLoader] = useState(false);
  const header = { Authorization: `Bearer ${token}` };
  const theme = useTheme();
  const dispatch = useDispatch();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    document.title = Setting.page_name.CANDIDATE_ACCOUNT;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setVisibility1(visibility === 1 ? true : false);
  }, [visibility]);

  useEffect(() => {
    setCheckBox();
  }, [communicationPreference]);

  function err(message) {
    toast.error(message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      progress: undefined,
    });
  }

  async function ChangePassword(data) {
    setLoader(true);
    const chnagePassData = {
      "ChangePasswordForm[password]": data?.newPass,
      "ChangePasswordForm[cpassword]": data?.confirmPass,
    };

    try {
      let endPoint = Setting.endpoints.change_password;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        chnagePassData,
        header
      );
      if (response?.status) {
        toast.success(response?.message);
        setLoader(false);
        setChnagePassModal(false);
      } else {
        //display error msg here
        err(response?.message);
        setLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setLoader(false);
    }
  }

  async function changeCommumication(type, value) {
    try {
      let endPoint = `${
        Setting.endpoints.employeeCommunication
      }?type=${type}&value=${value ? 1 : 0}`;
      const response = await getApiData(endPoint, "GET", null, header);
      if (response && response.status && response.status === true) {
        dispatch(setCommunication(response?.data));
      } else {
        console.log("err==>>");
        err(response?.message);
      }
    } catch (err) {
      console.log("Catch Part", err);
      err("Something_went_wrong");
    }
  }

  async function changeVisibility(value) {
    try {
      let endPoint = `${Setting.endpoints.employeeVisibility}?visibility=${value}`;
      const response = await getApiData(endPoint, "GET", null, header);
      if (response && response.status && response.status === true) {
        toast.success(response?.message);
        dispatch(setVisibility(Number(response?.data?.visibility)));
      } else {
        err(response?.message);
      }
    } catch (err) {
      console.log("Catch Part", err);
      err("Something_went_wrong");
    }
  }

  async function deleteAccount() {
    setRemoveBtnLoad(true);
    try {
      let endPoint = `${Setting.endpoints.employeeRemoveAccount}`;
      const response = await getApiData(endPoint, "GET", null, header);
      if (response && response.status && response.status === true) {
        toast.success(response?.message);
        dispatch(clearAllData());
        navigate("/");
        setConfirmDialog(false);
        setRemoveBtnLoad(false);
      } else {
        console.log("err==>>");
        err(response?.message);
        setRemoveBtnLoad(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      err("Something_went_wrong");
      setRemoveBtnLoad(false);
    }
  }

  function clickCheckBox(data, str) {
    const dummy_arr = [...communicationArr];
    {
      dummy_arr.map((ii) => {
        if (ii?.id === data?.id) {
          if (str === "e") {
            ii.email = !ii.email;
            if (ii?.id === 2) {
              changeCommumication("profile_reminder_email", ii.email);
            } else if (ii?.id === 3) {
              changeCommumication("news_about_email", ii.email);
            }
          } else {
            ii.phone = !ii.phone;
            if (ii?.id === 2) {
              changeCommumication("profile_reminder_push", ii.phone);
            } else if (ii?.id === 3) {
              changeCommumication("news_about_push", ii.phone);
            }
          }
        } else {
          console.log("err");
        }
      });
    }
    setCommunicationArr(dummy_arr);
  }

  function setCheckBox() {
    const dummy_arr = [...communicationArr];

    dummy_arr.map((ii) => {
      if (ii?.id === 2) {
        ii.email =
          Number(communicationPreference?.profile_reminder_email) === 1
            ? true
            : false;
        ii.phone =
          Number(communicationPreference?.profile_reminder_push) === 1
            ? true
            : false;
      } else if (ii?.id === 3) {
        ii.email =
          Number(communicationPreference?.news_about_email) === 1
            ? true
            : false;
        ii.phone =
          Number(communicationPreference?.news_about_push) === 1 ? true : false;
      }
    });
    setCommunicationArr(dummy_arr);
  }

  function renderFirstCard() {
    return (
      <Grid container className={classes.paperstyle}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography className="mediumsemiboldfont primarycolor">
            Your Email Address
          </Typography>
          {/* <div
            className={classes.editBtnStyle}
            // onClick={() => setModal(true)}
          >
            <img alt="error" src={Images.edit} style={{ width: 14 }} />
          </div> */}
        </Grid>
        <Typography
          className="lightsilvercolor regulerfont"
          style={{ marginTop: 10 }}
        >
          {candidateProfile?.email}
        </Typography>
      </Grid>
    );
  }

  function renderSecond() {
    return (
      <Grid container className={classes.paperstyle} direction="column">
        <Typography className="mediumsemiboldfont primarycolor">
          Password
        </Typography>
        <Typography
          className="lightsilvercolor largerregulerfont"
          style={{ marginTop: 10 }}
        >
          ********
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            setChnagePassModal(true);
          }}
          style={{ maxWidth: sm ? "100%" : 300 }}
        >
          change password
        </Button>
      </Grid>
    );
  }

  function renderThird() {
    return (
      <Grid container className={classes.paperstyle} direction="column">
        <Typography className="mediumsemiboldfont primarycolor">
          Select how you're happy for us to contact you
        </Typography>
        <Typography
          className="lightsilvercolor regulerfont"
          style={{ marginBottom: 20 }}
        >
          Please note that it may take several hours after changing your
          preferences for our records to be fully updated
        </Typography>
        {_.isArray(communicationArr) && !_.isEmpty(communicationArr)
          ? communicationArr.map((item, index) => {
              return (
                <Grid
                  container
                  alignItems="center"
                  style={{ marginTop: 20 }}
                  key={index}
                >
                  <Grid item xs={12} sm={8} md={7}>
                    <Typography className="mediumfont">{item?.name}</Typography>
                    <Typography
                      className="regulerfont lightsilvercolor"
                      style={{ marginTop: sm ? 10 : 0 }}
                    >
                      {item?.detail}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    sm={4}
                    md={5}
                    alignItems="center"
                    style={{ marginTop: sm ? 20 : 0 }}
                    justifyContent={sm ? "unset" : "space-evenly"}
                  >
                    <div style={{ display: "flex", width: "50%" }}>
                      <Checkbox
                        checked={item?.email}
                        disabled={item?.disable}
                        onChange={() => {
                          clickCheckBox(item, "e");
                        }}
                      />
                      <Typography
                        className="regulerfont"
                        style={{ color: item?.disable ? "#575757" : "#000000" }}
                      >
                        Email
                      </Typography>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Checkbox
                        checked={item?.phone}
                        disabled={item?.disable}
                        onChange={() => {
                          clickCheckBox(item, "p");
                        }}
                      />
                      <Typography
                        className="regulerfont"
                        style={{ color: item?.disable ? "#575757" : "#000000" }}
                      >
                        Phone
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              );
            })
          : null}
      </Grid>
    );
  }

  function renderFourth() {
    return (
      <Grid container className={classes.paperstyle} direction="column">
        <Typography
          className="mediumsemiboldfont primarycolor"
          style={{ marginBottom: 10 }}
        >
          Profile visibility
        </Typography>
        <Typography className="mediumfont" style={{ marginBottom: 10 }}>
          Are you not looking for work at the moment, but want to keep your
          Direct Expertise profile?
        </Typography>
        <Typography className="lightsilvercolor regulerfont">
          You can set your availability if you know when you'll be available. If
          you are unavailable, you can choose for your profile to be invisible.
          This will mean that employers won't be able to see your profile and
          you won't be matched to employers or any job openings. (Please note
          that if you are contacted by an employer, your profile will still be
          visible, and if you express interest in a job, your profile will
          automatically become visible).
        </Typography>
        <Grid
          container
          style={{ marginTop: 20 }}
          justifyContent={sm ? "space-between" : "unset"}
        >
          <Grid
            item
            xs={5.6}
            sm={3}
            md={2}
            style={{ marginRight: sm ? 10 : 20 }}
          >
            <Button
              variant={visibility1 ? "contained" : "outlined"}
              onClick={() => {
                if (visibility1) {
                  toast.info("Allready visible");
                } else {
                  changeVisibility(1);
                }
              }}
              style={{ width: "100%" }}
            >
              visible
            </Button>
          </Grid>
          <Grid item xs={5.6} sm={3} md={2}>
            <Button
              variant={visibility1 ? "outlined" : "contained"}
              onClick={() => {
                if (visibility1) {
                  changeVisibility(0);
                } else {
                  toast.info("Allready invisible");
                }
              }}
              style={{ width: "100%" }}
            >
              INVISIBLE
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function renderFifth() {
    return (
      <Grid container className={classes.paperstyle} direction="column">
        <Typography
          className="mediumsemiboldfont primarycolor"
          style={{ marginBottom: 10 }}
        >
          Account removal
        </Typography>
        <Typography className="mediumfont" style={{ marginBottom: 10 }}>
          If you want to permanently delete your Direct Expertise account, you
          can do so below. Please note that if you choose this option, it cannot
          be reversed.
        </Typography>
        <Typography
          className="lightsilvercolor regulerfont"
          style={{ marginBottom: 10 }}
        >
          When you opt for your account to be deleted, your data will be
          removed, including your work history, profile picture, video, etc.
          (Please note that it can take some time before your profile is fully
          deleted).
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            setConfirmDialog(true);
          }}
          style={{ maxWidth: sm ? "100%" : 300 }}
        >
          DELETE MY ACCOUNT
        </Button>
      </Grid>
    );
  }

  function renderMain() {
    return (
      <Box sx={{ flexGrow: 1, paddingTop: 4 }}>
        <Grid container>
          <Typography className="fontsize2" style={{ marginBottom: 20 }}>
            My details
          </Typography>
          {renderFirstCard()}
          {isSocial ? null : renderSecond()}
          <Typography
            className="fontsize2"
            style={{ marginBottom: 20, marginTop: 40 }}
          >
            Communication preferences
          </Typography>
          {renderThird()}
          <Typography
            className="fontsize2"
            style={{ marginBottom: 20, marginTop: 40 }}
          >
            Visibility
          </Typography>
          {renderFourth()}
          {renderFifth()}
        </Grid>
        <MChange
          visible={chnagePassModal}
          handleModal={() => {
            setChnagePassModal(false);
          }}
          saveClick={(data) => {
            ChangePassword(data);
          }}
          loader={loader}
        />
        <ConfirmDialog
          title="Are you sure you want to DELETE the account?"
          visible={confirmDialog}
          handleModal={(bool) => {
            if (bool) {
              deleteAccount();
            } else {
              setConfirmDialog(false);
            }
          }}
          btnLoad={removeBtnLoad}
        />
      </Box>
    );
  }

  return <div>{renderMain()}</div>;
}
