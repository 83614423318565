import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { Add, AddCircle } from "@mui/icons-material";
import { isArray, isEmpty, isObject, toLower } from "lodash";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import COverview from "../../../Components/Profile/COverview";
import CBenefit from "../../../Components/Profile/CBenefit";
import CCulture from "../../../Components/Profile/CCulture";
import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import { Setting } from "../../../Utils/Setting";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import useStyles from "./styles";
import MCoverImg from "../../../Components/Profile/Modal/MCoverImg";
import MInformation from "../../../Components/Profile/Modal/MInformation";
import MDetail from "../../../Components/Profile/Modal/MDetail";
import ConfirmDialog from "../../../Components/Modal/ConfirmDialog";
import { isIOS } from "react-device-detect";

export default function CompanyProfile() {
  const classes = useStyles();
  const location = useLocation();
  const from = location?.state?.from || "";
  const jobId = location?.state?.jobId || null;
  const shortlist = location?.state?.shortlist || false;
  const shortListData = location?.state?.shortListData || {};
  const { userdata, token, dropdowndata } = useSelector((state) => state.auth);
  const [openImg, setOpenImg] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoLoad, setVideoLoad] = useState(false);
  const [userType, setUserType] = useState(userdata?.role);

  const [coverimgModal, setCoverImageModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [profileDetail, setProfileDetail] = useState({});
  const [pageLoad, setPageLoad] = useState(true);
  const [uploadLoader, setUploadLoader] = useState("");
  const isCandidate = toLower(userType) === "user";

  const [sector, setSector] = useState("");
  const [companySize, setCompanySize] = useState("");

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [deleteType, setDeleteType] = useState("");

  useEffect(() => {
    document.title = Setting.page_name.EMPLOYER_COMPANY;
    if (isCandidate) {
      getProfileDetail();
    } else {
      getCompanyDetails();
    }
  }, []);

  useEffect(() => {
    if (isObject(userdata) && !isEmpty(userdata)) {
      setUserType(userdata?.role);
    }
  }, [userdata]);

  // this function for get profile detail
  async function getProfileDetail() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.employeeCompanyDetails}?job_post_id=${jobId}`,
        "GET",
        {},
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response.status) {
        if (!isEmpty(response?.data)) {
          setProfileDetail(response?.data || {});
          setVideoUrl(response?.data?.company_video || "");
        }
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  }

  // this function for get company details
  async function getCompanyDetails() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        Setting.endpoints.employerCompanyDetails,
        "GET",
        {},
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (response.status) {
        if (!isEmpty(response.data)) {
          setProfileDetail(response?.data || {});
          setVideoUrl(response?.data?.company_video || "");
          getDropdownData(response.data);
        }
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  }

  function getDropdownData(data) {
    dropdowndata.sectors.map((ii) => {
      if (Number(ii?.value) === Number(data?.sector)) {
        setSector(ii?.label);
      }
    });

    dropdowndata.company_size.map((ii) => {
      if (Number(ii?.value) === Number(data?.company_size)) {
        setCompanySize(ii?.label);
      }
    });
  }

  // this function handles user selected image for upload
  function uploadItem(file, type = "") {
    const displayUrl = URL.createObjectURL(file);

    if (type === "video") {
      setVideoLoad(true);
      setVideoUrl("");
    }

    setTimeout(() => {
      if (type === "image") {
        setOpenImg(displayUrl);
        uploadData(file, "img");
      } else {
        setVideoUrl(displayUrl);
        setVideoLoad(false);
        uploadData(file, "video");
      }
    }, 1000);
  }

  // this function for upload profile picture
  async function uploadData(url, type) {
    let endpoint = "";
    let obj = {};

    if (type === "video") {
      endpoint = Setting.endpoints.employerUploadCompanyVideo;
      obj = {
        "EmployerCompany[company_video]": url,
      };
    }

    setUploadLoader(type);
    try {
      const response = await getAPIProgressData(endpoint, "POST", obj, {
        Authorization: `Bearer ${token}`,
      });

      if (response.status) {
        if (type === "video") {
          setProfileDetail({
            ...profileDetail,
            company_video: response?.data?.company_video,
          });
        }
      } else {
        toast.error(response.message);
        if (type === "img") {
          setOpenImg("");
        } else {
          setVideoUrl("");
        }
      }
      setUploadLoader("");
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setUploadLoader("");
      if (type === "img") {
        setOpenImg("");
      } else {
        setVideoUrl("");
      }
    }
  }

  // this function for remove profile picture
  async function removeImageVideo() {
    setRemoveBtnLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.employeeRemoveVideoOrPhoto}?type=${deleteType}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (deleteType === "video") {
          setVideoUrl("");
          setProfileDetail({ ...profileDetail, company_video: "" });
        } else {
          setProfileDetail({ ...profileDetail, cover_image: "" });
        }
      }
      setConfirmDialog(false);
      setRemoveBtnLoad(false);
    } catch (error) {
      setConfirmDialog(false);
      setRemoveBtnLoad(false);
      console.log("error ===>>>", error);
    }
  }

  // this function for update detail
  function updateData(data = "", type = "") {
    const obj = { ...profileDetail };

    if (isArray(data)) {
      obj[type] = data;
    } else if (isObject(data) && !isEmpty(data)) {
      Object.keys(data).map((dK) => {
        obj[dK] = data[dK];
      });
    } else if (type === "company_culture") {
      obj["company_culture_text"] = data;
    } else {
      obj[type] = data;
    }

    setProfileDetail(obj);
  }

  if (pageLoad) {
    return (
      <Grid container className={classes.container}>
        <div className={classes.loaderView}>
          <CircularProgress size={50} />
        </div>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={8} md={9}>
        <Container className={classes.detailContainer}>
          <Grid
            item
            xs={12}
            className={[classes.shadowViewStyle, classes.marginDiv]}
            style={{ position: "relative" }}
          >
            {(isCandidate && profileDetail?.cover_image) ||
            profileDetail?.cover_image ? (
              <img
                src={profileDetail?.cover_image}
                className={classes.coverImgStyle}
                alt="img"
              />
            ) : (
              <div className={classes.posterDiv}>
                <Typography className={classes.sectionDesc}>
                  {from === "preview" || isCandidate
                    ? "Cover image"
                    : "Add cover image"}
                </Typography>
              </div>
            )}
            <div className={classes.companyDetailContainer}>
              <div className={classes.companyImgContainer}>
                {isCandidate || profileDetail?.company_logo ? (
                  <img
                    src={profileDetail?.company_logo}
                    className={classes.companyImgStyle}
                    alt="img"
                  />
                ) : (
                  <div className={classes.noCImgDiv} />
                )}
              </div>
              <div className={`${classes.pl20} ${classes.cDetailDivStyle}`}>
                <div>
                  <Typography className={classes.sectionTitle}>
                    {profileDetail?.company_name || "-"}
                  </Typography>
                  <Typography className={classes.sectionDesc}>
                    {profileDetail?.tag_line || "-"}
                  </Typography>
                </div>
                {isCandidate || from === "preview" ? null : (
                  <AddCircle
                    className={classes.addIcon}
                    onClick={() => {
                      setInfoModal(true);
                    }}
                  />
                )}
              </div>
            </div>
            {isCandidate || from === "preview" ? null : (
              <div
                className={classes.detailAddIcon}
                onClick={() => {
                  setCoverImageModal(true);
                }}
              >
                <AddCircle className={classes.addIcon} />
              </div>
            )}
            {isCandidate || from === "preview"
              ? null
              : profileDetail?.cover_image !== "" && (
                  <div
                    className={`${classes.buttonAbsoluteDiv} ${classes.deleteBtnStyle}`}
                    style={{ right: 65 }}
                    onClick={() => {
                      setConfirmDialog(true);
                      setDeleteType("cover_image");
                    }}
                  >
                    <img
                      src={Images.odelete}
                      alt="deletevideo"
                      style={{ width: 14 }}
                    />
                  </div>
                )}
          </Grid>
          {shortlist &&
            (shortListData?.job_role || shortListData?.job_description) && (
              <Grid
                item
                xs={12}
                className={[
                  classes.shadowViewStyle,
                  classes.paddingStyle,
                  classes.marginDiv,
                ]}
              >
                <div
                  id="coverview"
                  className={`${classes.splitViewStyle} ${classes.mb20}`}
                >
                  <Typography className={classes.sectionTitle}>
                    Job Role
                  </Typography>
                  <Typography className={classes.sectionDesc}>
                    {shortListData?.job_role || ""}
                  </Typography>

                  <Typography className={classes.sectionTitle}>
                    Job Description
                  </Typography>
                  {shortListData?.job_description && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: shortListData?.job_description || "",
                      }}
                    />
                  )}
                </div>
              </Grid>
            )}
          {(profileDetail?.company_overview || from === "") && (
            <COverview
              isCandidate={isCandidate}
              from={from}
              overview={profileDetail?.company_overview}
              updateData={updateData}
            />
          )}
          {((isArray(profileDetail?.benefits) &&
            profileDetail?.benefits.length > 0) ||
            from === "") && (
            <CBenefit
              isCandidate={isCandidate}
              from={from}
              data={profileDetail?.benefits || []}
              updateData={updateData}
            />
          )}
          {(profileDetail?.company_culture || from === "") && (
            <CCulture
              isCandidate={isCandidate}
              from={from}
              data={profileDetail}
              updateData={updateData}
            />
          )}
        </Container>
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <div className={classes.shadowViewStyle}>
          <div className={classes.singleViewStyle}>
            <Typography
              style={{
                color: color.primary,
                fontFamily: "PoppinsRegular",
              }}
            >
              Details
            </Typography>
            {isCandidate || from === "preview" ? null : (
              <AddCircle
                className={classes.addIcon}
                onClick={() => {
                  setDetailModal(true);
                }}
              />
            )}
          </div>
          <div className={classes.paddingStyle}>
            <div className={classes.cDetailSection}>
              <Typography
                className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
              >
                Company size
              </Typography>
              <Typography className={classes.sectionDesc}>
                {isCandidate ? profileDetail?.company_size || 0 : companySize}
              </Typography>
            </div>
            {/* <div className={classes.cDetailSection}>
              <Typography
                className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
              >
                Culture
              </Typography>
              <Typography className={classes.sectionDesc}>Startup</Typography>
            </div> */}
            <div className={classes.cDetailSection}>
              <Typography
                className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
              >
                Industry
              </Typography>
              <Typography className={classes.sectionDesc}>
                {isCandidate ? profileDetail?.sector || "-" : sector}
              </Typography>
            </div>
            <div className={classes.cDetailSection}>
              <Typography
                className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
              >
                Website
              </Typography>
              <Typography className={classes.sectionDesc}>
                {profileDetail?.company_website || "-"}
              </Typography>
            </div>
            {(profileDetail?.facebook_url ||
              profileDetail?.instagram_url ||
              profileDetail?.linkedin_url ||
              profileDetail?.twitter_url) && (
              <div
                className={classes.cDetailSection}
                style={{ marginBottom: 0 }}
              >
                <Typography
                  className={`${classes.sectionDesc} ${classes.textBlack} ${classes.fontWeight700}`}
                >
                  Follow us
                </Typography>
                <div className={classes.socialContainer}>
                  {profileDetail?.facebook_url && (
                    <a href={profileDetail.facebook_url} target="_blank">
                      <div className={classes.socialDiv}>
                        <img
                          src={Images.faceBook}
                          className={classes.socialImgStyle}
                        />
                      </div>
                    </a>
                  )}
                  {profileDetail?.instagram_url && (
                    <a href={profileDetail.instagram_url} target="_blank">
                      <div className={classes.socialDiv}>
                        <img
                          src={Images.insta}
                          className={classes.socialImgStyle}
                        />
                      </div>
                    </a>
                  )}
                  {profileDetail?.linkedin_url && (
                    <a href={profileDetail.linkedin_url} target="_blank">
                      <div className={classes.socialDiv}>
                        <img
                          src={Images.linkedin}
                          className={classes.socialImgStyle}
                        />
                      </div>
                    </a>
                  )}
                  {profileDetail?.twitter_url && (
                    <a href={profileDetail.twitter_url} target="_blank">
                      <div className={classes.socialDiv}>
                        <img
                          src={Images.twitter}
                          className={classes.socialImgStyle}
                        />
                      </div>
                    </a>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={classes.videoContainer}>
          <Typography className={classes.videoTitle}>Company Video</Typography>
          <div className={classes.videoSectionStyle}>
            {videoUrl ? (
              <div>
                <video
                  width="100%"
                  className={classes.videoStyle}
                  controls
                  poster={isIOS ? Images.videoThumbnail : ""}
                >
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </div>
            ) : (
              <Typography>
                {isCandidate || from === "preview" ? "No video" : "Add video"}
              </Typography>
            )}
            {isCandidate || from === "preview" ? null : (
              <>
                <div className={classes.buttonAbsoluteDiv}>
                  <Button component="label" className={classes.videoIcon}>
                    <Add style={{ color: "#FFF" }} />
                    <input
                      type="file"
                      onChange={(e) => uploadItem(e.target.files[0], "video")}
                      accept="video/*"
                      hidden
                    />
                  </Button>
                </div>
              </>
            )}

            {isCandidate || from === "preview"
              ? null
              : profileDetail?.company_video !== "" && (
                  <div
                    className={`${classes.buttonAbsoluteDiv} ${classes.deleteBtnStyle}`}
                    style={{ right: 60 }}
                    onClick={() => {
                      setConfirmDialog(true);
                      setDeleteType("video");
                    }}
                  >
                    <img
                      src={Images.odelete}
                      alt="deletevideo"
                      style={{ width: 14 }}
                    />
                  </div>
                )}
            {(videoLoad || uploadLoader === "video") && (
              <CircularProgress style={{ position: "absolute" }} />
            )}
          </div>
        </div>
      </Grid>
      <MCoverImg
        visible={coverimgModal}
        handleModal={(data, type, key) => {
          setCoverImageModal(false);
          if (type === "update") {
            updateData(data, key);
          }
        }}
      />
      <MInformation
        visible={infoModal}
        data={profileDetail}
        handleModal={(data, type) => {
          setInfoModal(false);
          if (type === "update") {
            updateData(data, type);
          }
        }}
      />
      <MDetail
        data={profileDetail}
        visible={detailModal}
        handleModal={(type = "") => {
          setDetailModal(false);
          if (type === "success") {
            if (isCandidate) {
              getProfileDetail();
            } else {
              getCompanyDetails();
            }
          }
        }}
      />
      <ConfirmDialog
        title={`Are you sure you want to delete this ${
          deleteType === "video" ? "video" : "cover image"
        }?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            removeImageVideo();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
    </Grid>
  );
}
