import React, { useEffect, useState } from "react";
// import "./styles.css";
import { Setting } from "../../../Utils/Setting";
import { Typography, Grid, Button } from "@mui/material";
import { getApiData } from "../../../Utils/APIHelper";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../../Components/ConfirmDialog";
import { toast } from "react-toastify";

function Unsubscribe() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = Setting.page_name.TERMS;
  }, []);

  const { token } = useSelector((state) => state.auth);
  const [pageLoader, setPageLoader] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  async function unsubscribeMe() {
    setPageLoader(true);
    let endpoint = `${Setting.endpoints.employeeunsubscribe}`;
    try {
      const response = await getApiData(
        endpoint,
        "POST",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        navigate("/");
        toast.success("You have successfully unsubscribed");
        setConfirmDialog(false);
      }
      setPageLoader(false);
      setConfirmDialog(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoader(false);
    }
  }

  return (
    <Grid container gap={2} alignContent={"center"} style={{ height: 500 }}>
      <Grid item container justifyContent={"center"} alignItems={"center"}>
        <Typography className="fontsize">Unsubscribe</Typography>
      </Grid>
      <Grid item container justifyContent={"center"} alignItems={"center"}>
        <Typography>Are You Sure?</Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent={"center"}
        alignItems={"center"}
        marginTop={3}
      >
        <Typography
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        >
          Cancel
        </Typography>
      </Grid>
      <Grid item container justifyContent={"center"} alignItems={"center"}>
        <Button
          variant="contained"
          color="primary"
          style={{ width: 240 }}
          onClick={() => {
            setConfirmDialog(true);
          }}
        >
          Yes, Unsubscribe
        </Button>
      </Grid>
      <ConfirmDialog
        title="Are you sure you'd like to unsubscribe?"
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            unsubscribeMe();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={pageLoader}
      />
    </Grid>
  );
}

export default Unsubscribe;
