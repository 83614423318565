import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import useStyles from "../styles";
import { find, isArray, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import moment from "moment";
import { isIOS } from "react-device-detect";
import Images from "../../../Config/Images";

function PostPreviewModal(props) {
  const { visible = false, handleModal = () => null, data = {} } = props;
  const { dropdowndata } = useSelector((state) => state.auth);
  const classes = useStyles();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [sectorName, setSectorName] = useState("");
  const [JobRoleName, setJobroleName] = useState("");

  useEffect(() => {
    dropdowndata?.sectors.map((ii) => {
      if (Number(ii?.value) === Number(data?.aboutJob?.sector)) {
        setSectorName(ii?.label);

        ii?.job_roles.map((ii2) => {
          if (Number(ii2?.value) === Number(data?.aboutJob?.job_role_id)) {
            setJobroleName(ii2?.label);
          }
        });
      }
    });
  }, [visible]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    height: 500,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };

  // this function for reset form data
  function resetFormData(type = "") {
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  const educationLvl =
    isArray(dropdowndata?.education_level) &&
    dropdowndata?.education_level.find(
      (item) => Number(item.value) === Number(data?.aboutJob?.education)
    );

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style} className={classes.boxmain}>
          <Grid container direction="column">
            <Typography className="primarycolor fontsize2">Preview</Typography>

            <Typography
              className={classes.sectionTitle}
              style={{ marginTop: 20 }}
            >
              About your job
            </Typography>
            {!isEmpty(data?.aboutJob) ? (
              <div>
                <Typography className="regulerfont primarycolor">
                  Sector: {sectorName}
                </Typography>
                <Typography className="regulerfont primarycolor">
                  Job Role: {JobRoleName}
                </Typography>
                <Typography className="regulerfont primarycolor">
                  Job Experience: {data?.aboutJob?.experience}
                </Typography>
                <Typography className="regulerfont primarycolor">
                  Job Education Level:{" "}
                  {!isEmpty(educationLvl) && educationLvl?.label}
                </Typography>
                <Typography className="regulerfont primarycolor">
                  Vacancies: {data?.aboutJob?.vacancies}
                </Typography>
                <Typography className="regulerfont primarycolor">
                  Job Title: {data?.aboutJob?.jobTitle}
                </Typography>
                {data?.aboutJob?.closeDate && (
                  <Typography className="regulerfont primarycolor">
                    Job close date:{" "}
                    {moment(data?.aboutJob?.closeDate).format("DD MMMM YYYY")}
                  </Typography>
                )}
              </div>
            ) : (
              <Typography className={classes.sectionDesc}>
                Add job information
              </Typography>
            )}

            <Typography
              className={classes.sectionTitle}
              style={{ marginTop: 20 }}
            >
              Employment type
            </Typography>
            {!isEmpty(data?.employmentType) ? (
              <div>
                {data?.employmentType?.jobType === "p_full_time" ||
                data?.employmentType?.jobType === "t_full_time" ? (
                  <Typography className="regulerfont primarycolor">
                    {`${
                      data?.employmentType?.jobType === "p_full_time"
                        ? "Permanent Full Time"
                        : "Temporary Full Time"
                    } : £${data?.employmentType?.perYear} per year`}
                  </Typography>
                ) : data?.employmentType?.jobType === "p_part_time" ||
                  data?.employmentType?.jobType === "t_part_time" ? (
                  <Typography className="regulerfont primarycolor">
                    {`${
                      data?.employmentType?.jobType === "p_part_time"
                        ? "Permanent Part Time"
                        : "Temporary Part Time"
                    } : £${data?.employmentType?.perHour} per hour`}
                  </Typography>
                ) : (
                  <>
                    <Typography className="regulerfont primarycolor">
                      {`Full Time : £${data?.employmentType?.bothPerYear} per year`}
                    </Typography>
                    <Typography className="regulerfont primarycolor">
                      {`Part Time : £${data?.employmentType?.bothPerHour} per hour`}
                    </Typography>
                  </>
                )}
                <div
                  // style={{ marginTop: 10 }}
                  dangerouslySetInnerHTML={{
                    __html: data?.employmentType?.data || "",
                  }}
                />
              </div>
            ) : (
              <Typography className={classes.sectionDesc}>
                Add information
              </Typography>
            )}

            <Typography
              className={classes.sectionTitle}
              style={{ marginTop: 20 }}
            >
              Video for your profile
            </Typography>
            {!isEmpty(data?.videoRole) ? (
              <div>
                <video
                  width="100%"
                  height={200}
                  controls
                  poster={isIOS ? Images.videoThumbnail : ""}
                >
                  <source src={data?.videoRole?.VideoUrl} type="video/mp4" />
                </video>
              </div>
            ) : (
              <Typography className={classes.sectionDesc}>Add video</Typography>
            )}

            <Typography
              className={classes.sectionTitle}
              style={{ marginTop: 20 }}
            >
              Job postcode
            </Typography>
            {!isEmpty(data?.jobLocation) ? (
              <div>
                {data?.jobLocation?.remoteOnly ? (
                  <Typography className="regulerfont primarycolor">
                    You have selected candidates from all locations within the
                    United Kingdom
                  </Typography>
                ) : (
                  <Typography className="regulerfont primarycolor">
                    Postcode: {data?.jobLocation?.selectedLocation?.location}
                  </Typography>
                )}
              </div>
            ) : (
              <Typography className={classes.sectionDesc}>
                Add postcode
              </Typography>
            )}

            <Typography
              className={classes.sectionTitle}
              style={{ marginTop: 20 }}
            >
              Description of role
            </Typography>
            {!isEmpty(data?.descriptionRole) ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.descriptionRole || "",
                }}
              />
            ) : (
              <Typography className={classes.sectionDesc}>
                Add description
              </Typography>
            )}

            <Typography
              className={classes.sectionTitle}
              style={{ marginTop: 20 }}
            >
              Skills
            </Typography>
            <div className={classes.skillDivStyle}>
              {!isEmpty(data?.skills?.selectedSkills) ? (
                data?.skills?.selectedSkills.map((item) => {
                  return (
                    <div
                      key={`skill${item.label}_s`}
                      className={classes.tagViewStyle}
                    >
                      <Typography variant="body2" style={{ color: "#5A5A5A" }}>
                        {item?.label}
                      </Typography>
                    </div>
                  );
                })
              ) : (
                <Typography className={classes.sectionDesc}>
                  Strengthen your profile by adding relevant skills
                </Typography>
              )}
            </div>

            <Typography
              className={classes.sectionTitle}
              style={{ marginTop: 20 }}
            >
              Language required
            </Typography>
            <div className={classes.skillDivStyle}>
              {isArray(data?.language) && !isEmpty(data?.language) ? (
                data?.language.map((item, index) => {
                  const obj = find(
                    dropdowndata?.language,
                    (key) => key.value === item.value
                  );
                  return (
                    <div key={index} className={classes.tagViewStyle}>
                      <Typography variant="body2" style={{ color: "#5A5A5A" }}>
                        {`${obj?.label} - ${item.preference}` || ""}
                      </Typography>
                    </div>
                  );
                })
              ) : (
                <Typography className={classes.sectionDesc}>
                  Add your language/s.
                </Typography>
              )}
            </div>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                handleModal();
              }}
              style={{ marginTop: 20 }}
            >
              Done
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}

export default PostPreviewModal;
