import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  Avatar,
  Checkbox,
  useMediaQuery,
  useTheme,
  TextField,
  CircularProgress,
  InputAdornment,
  Rating,
} from "@mui/material";
import Slider from "react-slick";
import {
  ArrowForward,
  ArrowDownward,
  KeyboardArrowRight,
  KeyboardArrowLeft,
  ContentCopy,
  VisibilityOff,
  Visibility,
} from "@mui/icons-material";
import "./styles.css";
import Images from "../../../Config/Images";
import { Setting } from "../../../Utils/Setting";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isEdge } from "react-device-detect";
import { toast } from "react-toastify";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import { isEmpty, isObject } from "lodash";
import MUserDetail from "../../../Components/Profile/Modal/MUserDetail";
import GoogleLoginButton from "../../../Components/Social_Login/GoogleLoginButton";
import FBLoginButton from "../../../Components/Social_Login/FBLoginButton";
import MLogin from "../../../Components/Modal/LoginModal";
import MForgot from "../../../Components/Modal/ForgotModal";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

const errorObj = {
  companyNameErr: false,
  emailErr: false,
  phoneErr: false,
  passwordErr: false,
  companyNameMsg: "",
  emailMsg: "",
  phoneMsg: "",
  passwordMsg: "",
};

export default function Home() {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const Current_Url = window.location.href;
  const email_Regex = Setting.JS_Regex.email_Regex;
  const number_Regex = Setting.JS_Regex.number_Regex;
  const passRegex = Setting.JS_Regex.pwd1_Regex;
  const isEmployerHome = Current_Url.includes("employer/home");
  const { reviewdata } = useSelector((state) => state.auth);

  const candidateReview = reviewdata?.user_review || [];
  const employerReview = reviewdata?.employer_review || [];

  const isCandidateEmpty = isEmpty(candidateReview);
  const isEmployerEmpty = isEmpty(employerReview);

  const isOne = candidateReview.length === 1;

  const navigate = useNavigate();
  const sliderRef = useRef();
  const firstSliderRef = useRef();
  const firstSliderImgRef = useRef();

  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errObj, setErrObj] = useState(errorObj);

  const [loader, setLoader] = useState(false);
  const [agree, setAgree] = useState(false);

  const [modalSaveBtnLoader, setModalSaveBtnLoader] = useState(false);
  const [socialUserInfo, setSocialUserInfo] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [forgotModal, setForgotModal] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    document.title = Setting.page_name.EMPLOYER_HOME;
    window.scrollTo(0, 0);
  }, []);

  function resetData() {
    setCompanyName("");
    setEmail("");
    setPhone("");
    setPassword("");
    setAgree(false);
    setErrObj(errorObj);
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(companyName)) {
      valid = false;
      error.companyNameErr = true;
      error.companyNameMsg = "Please enter company name";
    } else if (isEmpty(email)) {
      valid = false;
      error.emailErr = true;
      error.emailMsg = "Please enter email address";
    } else if (!email_Regex.test(email)) {
      valid = false;
      error.emailErr = true;
      error.emailMsg = "Please enter valid email address";
    } else if (isEmpty(phone.toString())) {
      valid = false;
      error.phoneErr = true;
      error.phoneMsg = "Please enter phone number";
    } else if (phone.length < 10 || !number_Regex.test(phone)) {
      error.phoneErr = true;
      error.phoneMsg = "Please enter valid phone number";
    }
    // else if (!phone_Regex.test(phone)) {
    //   valid = false;
    //   error.phoneErr = true;
    //   error.phoneMsg = "Number must be in +44 XXXXXXXXXX format";
    // }
    else if (isEmpty(password)) {
      valid = false;
      error.passwordErr = true;
      error.passwordMsg = "Please enter password";
    } else if (!passRegex.test(password)) {
      valid = false;
      error.passwordErr = true;
      error.passwordMsg = "Password must be 8 characters long";
    } else if (!agree) {
      valid = false;
      toast.error("Please accept the Terms and Conditions");
    }

    setErrObj(error);

    if (valid) {
      companySignupProcess();
      setLoader(true);
    }
  }

  async function companySignupProcess(data = {}) {
    const isSocialSignUp = isObject(data) && !isEmpty(data);

    const signUpData = {
      "EmployerSignup[company_name]": isSocialSignUp
        ? data?.firstName
        : companyName,
      "EmployerSignup[email]": isSocialSignUp ? data?.email : email,
      "EmployerSignup[phone]": isSocialSignUp ? data?.phone : `+44${phone}`,
      "EmployerSignup[terms_condition]": agree ? 1 : 0,
    };

    if (!isSocialSignUp) {
      signUpData["EmployerSignup[password]"] = password;
    } else {
      signUpData["EmployerSignup[provider_type]"] =
        socialUserInfo?.provider_type;
      signUpData["EmployerSignup[provider_key]"] = socialUserInfo?.provider_key;
    }

    try {
      let endPoint = Setting.endpoints.employer_signup;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        signUpData,
        true
      );
      if (response?.status) {
        if (isSocialSignUp) {
          setOpenModal(false);
          setModalSaveBtnLoader(false);
        }
        toast.success(response?.message);
        setLoader(false);
        resetData();
      } else {
        //display error msg here
        toast.error(response?.message);
        setModalSaveBtnLoader(false);
        setLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      toast.error("Something_went_wrong");
      setModalSaveBtnLoader(false);
      setLoader(false);
    }
  }

  const setting = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    slidesToShow: isOne ? 1 : 2,
    autoplaySpeed: 6000,
    slidesToScroll: 1,
    swipe: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
          dots: false,
          arrows: false,
          swipe: false,
        },
      },
    ],
  };

  const firstSetting = {
    dots: false,
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false,
    arrows: false,
  };

  function renderFirst() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={6}
            alignItems="center"
            justifyContent="center"
          >
            {sm ? null : (
              <img
                alt="Error"
                src={Images?.heroimg2}
                style={{ height: "100%", width: "100%" }}
              />
            )}
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={6}
            direction="column"
            justifyContent={"center"}
            style={{ paddingLeft: sm ? 0 : md ? 30 : 100 }}
          >
            <Typography className="fontsize primarycolor">
              Employer Sign up
            </Typography>
            <Typography className="primarycolor" style={{ marginBottom: 16 }}>
              Want to sign up as candidate?{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/candidate/signup");
                }}
              >
                <u>Click here</u>
              </span>
            </Typography>
            <TextField
              variant="outlined"
              placeholder="Company name*"
              value={companyName}
              error={errObj.companyNameErr}
              helperText={errObj.companyNameErr ? errObj.companyNameMsg : null}
              onChange={(v) => {
                setCompanyName(v.target.value);
                setErrObj({
                  ...errObj,
                  companyNameErr: false,
                  companyNameMsg: "",
                });
              }}
              style={{ maxWidth: sm ? "100%" : 310, marginBottom: 10 }}
            />
            <TextField
              variant="outlined"
              placeholder="Email*"
              value={email}
              error={errObj.emailErr}
              helperText={errObj.emailErr ? errObj.emailMsg : null}
              onChange={(v) => {
                setEmail(v.target.value);
                setErrObj({
                  ...errObj,
                  emailErr: false,
                  emailMsg: "",
                });
              }}
              style={{ maxWidth: sm ? "100%" : 310, marginBottom: 10 }}
            />
            <TextField
              variant="outlined"
              placeholder="Phone*"
              value={phone}
              error={errObj.phoneErr}
              helperText={errObj.phoneErr ? errObj.phoneMsg : null}
              inputProps={{
                maxLength: 10,
              }}
              onChange={(v) => {
                setPhone(v.target.value);
                setErrObj({
                  ...errObj,
                  phoneErr: false,
                  phoneMsg: "",
                });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+44</InputAdornment>
                ),
              }}
              style={{ maxWidth: sm ? "100%" : 310, marginBottom: 10 }}
            />
            <TextField
              variant="outlined"
              placeholder="Password*"
              value={password}
              error={errObj.passwordErr}
              helperText={errObj.passwordErr ? errObj.passwordMsg : null}
              onChange={(v) => {
                setPassword(v.target.value);
                setErrObj({
                  ...errObj,
                  passwordErr: false,
                  passwordMsg: "",
                });
              }}
              autoComplete="new-password"
              type={passwordVisible ? "none" : "password"}
              style={{ maxWidth: sm ? "100%" : 310, marginBottom: 10 }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                  checkValidation();
                }
              }}
              InputProps={{
                endAdornment: isEdge ? null : !passwordVisible ? (
                  <VisibilityOff
                    style={{ color: "#1F4771", cursor: "pointer" }}
                    onClick={() => {
                      setPasswordVisible(!passwordVisible);
                    }}
                  />
                ) : (
                  <Visibility
                    style={{ color: "#1F4771", cursor: "pointer" }}
                    onClick={() => {
                      setPasswordVisible(!passwordVisible);
                    }}
                  />
                ),
              }}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={agree}
                onChange={() => {
                  setAgree(!agree);
                }}
                style={{ width: 40, marginLeft: -9 }}
              />
              <Typography className="lightsilvercolor">
                I agree to the{" "}
                <span
                  className="primarycolor"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/cms/terms-and-condition");
                  }}
                >
                  <u>T&Cs</u>
                </span>{" "}
                and{" "}
                <span
                  className="primarycolor"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/cms/privacy-policy");
                  }}
                >
                  <u>Privacy Policy</u>
                </span>
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              style={{ width: sm ? "100%" : 310, marginTop: 10 }}
              onClick={() => {
                checkValidation();
              }}
              disabled={loader}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={26} />
              ) : (
                "sign up"
              )}
            </Button>
            <Typography className={`primarycolor regulerfont loginText`}>
              Already have an account?{" "}
              <Typography
                className="smallsemiboldfont"
                style={{ cursor: "pointer", width: 70, marginLeft: 3 }}
                onClick={() => setLoginModal(true)}
              >
                <u> Log In</u>
              </Typography>
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                width: sm ? "100%" : 310,
              }}
            >
              <div className="divider1" />
              <Typography
                className="primarycolor"
                style={{ marginLeft: 8, marginRight: 8 }}
              >
                OR
              </Typography>
              <div className="divider1" />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: sm ? "100%" : 310,
                marginTop: 10,
              }}
            >
              <GoogleLoginButton
                from="SignUp"
                type="employer"
                onGetUserInfo={(v) => {
                  setSocialUserInfo(v);
                  setOpenModal(true);
                }}
              />
              <FBLoginButton
                from="SignUp"
                type="employer"
                onGetUserInfo={(v) => {
                  setSocialUserInfo(v);
                  setOpenModal(true);
                }}
              />
              {/* <img
                src={Images?.linkedinTheme}
                alt="error"
                style={{ height: 30, width: 30, cursor: "pointer" }}
              /> */}
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderSecond() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 5 }}>
        <Grid
          container
          style={{
            padding: 20,
            backgroundColor: "#f5f9ff",
            borderRadius: 8,
            paddingBottom: 30,
          }}
        >
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              How our service works
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 30 }}
          >
            <Grid item xs={12} sm={3.6} className="singleDetailContainer">
              <div className="secondGrid1">
                <Typography
                  style={{
                    marginBottom: 6,
                  }}
                  className="primarycolor mediumsemiboldfont"
                >
                  Find Suitable Candidates Instantly
                </Typography>
                <Typography className="mediumfont lightsilvercolor">
                  No need to browse through hundreds of CVs. Direct Expertise
                  provides an instant shortlist of matched candidates according
                  to your needs and requirements. We keep you updated with any
                  interest from candidates who have viewed your job, giving you
                  the time you need to focus more on your business.
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={0.5} justifyContent="center">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {sm ? (
                  <ArrowDownward
                    style={{ color: "#D6AC85", marginTop: 4, marginBottom: 4 }}
                  />
                ) : (
                  <ArrowForward style={{ color: "#D6AC85" }} />
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={3.6} className="singleDetailContainer">
              <div className="secondGrid1">
                <Typography
                  style={{ marginBottom: 6 }}
                  className="primarycolor mediumsemiboldfont"
                >
                  Browse Candidate Profiles for Free
                </Typography>
                <Typography className="mediumfont lightsilvercolor">
                  Browse through matched candidate profiles which include the
                  skills, experience, and knowledge of candidates, as well as a
                  video, profile picture, and a percentage score of candidate
                  responses to Situational Judgement Tests relevant to your job
                  role - giving you all the correct information you need to
                  choose the best suitable candidate.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={0.5} justifyContent="center">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {sm ? (
                  <ArrowDownward
                    style={{ color: "#D6AC85", marginTop: 4, marginBottom: 4 }}
                  />
                ) : (
                  <ArrowForward style={{ color: "#D6AC85" }} />
                )}
              </div>
            </Grid>

            <Grid item xs={12} sm={3.6} className="singleDetailContainer">
              <div className="secondGrid1">
                <Typography
                  style={{ marginBottom: 6 }}
                  className="primarycolor mediumsemiboldfont"
                >
                  Instant Hire
                </Typography>
                <Typography className="mediumfont lightsilvercolor">
                  No subscription fees or any other hidden costs. Once you have
                  viewed all the information, you can unlock the candidate
                  profile by paying a small one-off fee and instantly connect
                  with the candidate. Why waste time when you can instantly find
                  the right expertise for your business.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderThird() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10 }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <Typography
              className="fontsize"
              style={{ textAlign: "center", marginBottom: 20 }}
            >
              More than just a job post
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="thirdmain">
            <div style={{ display: "flex" }}>
              <div className="imgmain2">
                <img src={Images?.icon1} alt="error" className="imageicon" />
              </div>
              <div className="textmain2">
                <Typography
                  className="primarycolor mediumsemiboldfont"
                  style={{ marginBottom: 10 }}
                >
                  Make direct contact
                </Typography>
                <Typography className="lightsilvercolor mediumfont">
                  Immediate access to matched candidate details.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="thirdmain">
            <div style={{ display: "flex" }}>
              <div className="imgmain2">
                <img src={Images?.icon2} alt="error" className="imageicon" />
              </div>
              <div className="textmain2">
                <Typography
                  className="primarycolor mediumsemiboldfont"
                  style={{ marginBottom: 10 }}
                >
                  Job management app
                </Typography>
                <Typography className="lightsilvercolor mediumfont">
                  Browse, connect, message, and call candidates instantly, all
                  in one place.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="thirdmain">
            <div style={{ display: "flex" }}>
              <div className="imgmain2">
                <img src={Images?.icon3} alt="error" className="imageicon" />
              </div>
              <div className="textmain2">
                <Typography
                  className="primarycolor mediumsemiboldfont"
                  style={{ marginBottom: 10 }}
                >
                  One-off cost
                </Typography>
                <Typography className="lightsilvercolor mediumfont">
                  No subscriptions or hidden costs. Only a one-off fee after
                  browsing candidate profiles for free to unlock your chosen
                  candidate/s.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="thirdmain">
            <div style={{ display: "flex" }}>
              <div className="imgmain2">
                <img src={Images?.icon4} alt="error" className="imageicon" />
              </div>
              <div className="textmain2">
                <Typography
                  className="primarycolor mediumsemiboldfont"
                  style={{ marginBottom: 10 }}
                >
                  Situational Judgement Tests
                </Typography>
                <Typography className="lightsilvercolor mediumfont">
                  View, choose, and select questions and answers you wish
                  candidates to answer relevant to your sector.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="thirdmain">
            <div style={{ display: "flex" }}>
              <div className="imgmain2">
                <img src={Images?.icon5} alt="error" className="imageicon" />
              </div>
              <div className="textmain2">
                <Typography
                  className="primarycolor mediumsemiboldfont"
                  style={{ marginBottom: 10 }}
                >
                  No need to browse hundreds of CV’s
                </Typography>
                <Typography className="lightsilvercolor mediumfont">
                  Instantly view a shortlist of suitable candidates based on
                  your job requirements and receive notifications of any
                  interests from candidates directly to your mailbox at no cost.
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.8} md={3.8} className="thirdmain">
            <div style={{ display: "flex" }}>
              <div className="imgmain2">
                <img src={Images?.icon6} alt="error" className="imageicon" />
              </div>
              <div className="textmain2">
                <Typography
                  className="primarycolor mediumsemiboldfont"
                  style={{ marginBottom: 10 }}
                >
                  Easily understand the expertise available via candidate
                  profiles
                </Typography>
                <Typography className="lightsilvercolor mediumfont">
                  Access videos, pictures, skills, experience, and knowledge of
                  candidates.
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderFourth() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10 }}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ marginBottom: 20 }}
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              Words from our employers
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={7.5}>
              <Slider ref={firstSliderImgRef} {...firstSetting}>
                {employerReview.map((ii, index) => {
                  return (
                    <>
                      <div key={index} style={{ display: "flex" }}>
                        <img alt="Error" src={ii.photo} className="sliderimg" />
                      </div>
                    </>
                  );
                })}
              </Slider>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4.5}
              style={{ position: "relative", marginTop: sm ? 20 : 0 }}
            >
              <div className="maindiv">
                {sm ? null : <div className="divdesign" />}
                <div className="remaindiv">
                  <div className="arrowmaindiv">
                    <div
                      className="arrowdiv"
                      onClick={() => {
                        firstSliderRef.current.slickPrev();
                        firstSliderImgRef.current.slickPrev();
                      }}
                    >
                      <KeyboardArrowLeft
                        style={{ color: "#fff", fontSize: sm ? 30 : 40 }}
                      />
                      <div
                        className="arrowdiv arrowdiv2"
                        onClick={() => {
                          firstSliderRef.current.slickNext();
                          firstSliderImgRef.current.slickNext();
                        }}
                      >
                        <KeyboardArrowRight
                          style={{ color: "#fff", fontSize: sm ? 30 : 40 }}
                        />
                      </div>
                    </div>
                  </div>
                  <Slider ref={firstSliderRef} {...firstSetting}>
                    {employerReview.map((ii, index) => {
                      return (
                        <>
                          <div key={index} className="messsagefromclien">
                            <div className="messageheder">
                              <Typography className="primarycolor mediumlargefont">
                                {ii?.name}
                              </Typography>
                              <div className="underline" />

                              <Rating
                                name="half-rating"
                                defaultValue={ii?.rating}
                                precision={0.5}
                                readOnly
                              />
                            </div>
                            <div
                              className="sliderscroll"
                              style={{
                                margin: 20,
                                maxHeight: md ? 240 : 320,
                              }}
                            >
                              <Typography className="regulerfont">
                                {ii?.description}
                              </Typography>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderFifth() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: sm ? 10 : 20 }}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ padding: 20 }}
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              3 steps to start your work adventure
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              direction="column"
              justifyContent="center"
            >
              <Typography
                className="primarycolor fontsize2"
                style={{ marginBottom: 6 }}
              >
                1. Create a company profile
              </Typography>
              <Typography
                className="lightsilvercolor"
                style={{ lineHeight: 2, fontSize: 18 }}
              >
                Create a company profile with your job requirements and company
                information, including a picture and video to help candidates
                understand your company. Select your preferred questions and
                answers for candidates to answer in their Situational Judgement
                Test, and we do all the leg work for you.
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              alignItems="center"
              justifyContent="center"
            >
              <img
                alt="Error"
                src={Images?.estep1}
                style={{ height: "100%", width: "100%" }}
              />
            </Grid>
            <Grid container xs={12} direction="row-reverse">
              <Grid
                container
                xs={12}
                sm={6}
                md={6}
                direction="column"
                justifyContent="center"
              >
                <Typography
                  className="primarycolor fontsize2"
                  style={{ marginBottom: 6 }}
                >
                  2. Get candidate profiles suited to your requirements
                </Typography>
                <Typography
                  className="lightsilvercolor"
                  style={{ lineHeight: 2, fontSize: 18 }}
                >
                  We use the information you provided to give you a shortlist of
                  matched candidates. You can browse these candidate profiles at
                  no cost, including the responses to the questions and answers
                  you selected, videos and pictures of candidates, and their
                  skills, experience, and knowledge to give you all the
                  information you need to hire the best expertise for your
                  job/s.
                </Typography>
              </Grid>
              <Grid
                container
                xs={12}
                sm={6}
                md={6}
                alignItems="center"
                justifyContent="center"
              >
                <img
                  alt="Error"
                  src={Images?.estep2}
                  style={{ height: "100%", width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              direction="column"
              justifyContent="center"
            >
              <Typography
                className="primarycolor fontsize2"
                style={{ marginBottom: 6 }}
              >
                3. Connect and hire instantly
              </Typography>
              <Typography
                className="lightsilvercolor"
                style={{ lineHeight: 2, fontSize: 18 }}
              >
                Select the expertise you require, make a one-off small payment,
                and instantly unlock the candidate profile to message, call and
                hire on the Direct Expertise Platform or via our App. Why waste
                time browsing through CVs or using a third party when it is all
                done instantly for you using our innovative software?
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              md={6}
              alignItems="center"
              justifyContent="center"
            >
              <img
                alt="Error"
                src={Images?.estep3}
                style={{ height: "100%", width: "100%" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderSixth() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10, marginBottom: 10 }}>
        <Grid
          container
          style={{ padding: 20, backgroundColor: "#f5f9ff", borderRadius: 8 }}
        >
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            style={{ marginBottom: 20 }}
          >
            <Typography
              className="fontsize"
              style={{ textAlign: sm ? "center" : "unset" }}
            >
              Words from our employers
            </Typography>
          </Grid>
          <Grid container xs={12} alignItems="center" justifyContent="center">
            <Grid item xs={1}>
              <Button
                variant="outlined"
                className="buttun"
                onClick={() => {
                  sliderRef.current.slickPrev();
                }}
              >
                <KeyboardArrowLeft style={{ fontSize: 20 }} />
              </Button>
            </Grid>
            <Grid item xs={10}>
              <Slider ref={sliderRef} {...setting} className="carousal">
                {employerReview.map((ii, index) => {
                  return (
                    <Grid item container xs={12} key={index}>
                      <div className="slider12">
                        <div className="msg-icn">
                          <Typography
                            className="lightsilvercolor regulersmallfont"
                            style={{ marginBottom: 16 }}
                          >
                            {ii?.description}
                          </Typography>

                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography className="primarycolor smallsemiboldfont">
                              {ii?.name}
                            </Typography>

                            <Rating
                              name="half-rating"
                              defaultValue={ii?.rating}
                              precision={0.5}
                              readOnly
                              style={{ marginLeft: 6, fontSize: 20 }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="profilecmt">
                        <Avatar
                          className="avatarUI"
                          alt="error"
                          src={ii?.photo}
                        />
                      </div>
                    </Grid>
                  );
                })}
              </Slider>
            </Grid>
            <Grid item xs={1}>
              <div
                style={{
                  display: sm ? "unset" : "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  className="buttun"
                  onClick={() => {
                    sliderRef.current.slickNext();
                  }}
                >
                  <KeyboardArrowRight style={{ fontSize: 20 }} />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function renderSeventh() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: 10, marginBottom: 5 }}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            style={{ marginBottom: 26 }}
          >
            <Typography className="fontsize" style={{ textAlign: "center" }}>
              Get our PWA app on Android and iPhone and share on
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {/* <div className="iconstyle">
                <img
                  alt="Error"
                  src={Images?.install}
                  style={{ height: "60%", width: "60%" }}
                />
              </div> */}
              {/*<div*/}
              {/*  className="iconstyle"*/}
              {/*  onClick={() => {*/}
              {/*    navigator.clipboard.writeText(Setting?.mainURL);*/}
              {/*    toast.success("linked successful copied to clipboard");*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <ContentCopy style={{ color: "#fff", fontSize: 18 }} />*/}
              {/*</div>*/}
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FacebookShareButton url={Setting?.mainURL}>
                <div className="iconstyle">
                  <img
                    alt="Error"
                    src={Images?.faceBook}
                    style={{ height: "60%", width: "60%" }}
                  />
                </div>
              </FacebookShareButton>
            </Grid>
            {/* <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="iconstyle">
                <img
                  alt="Error"
                  src={Images?.insta}
                  style={{ height: "60%", width: "60%" }}
                />
              </div>
            </Grid> */}
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <LinkedinShareButton url={Setting?.mainURL}>
                <div className="iconstyle">
                  <img
                    alt="Error"
                    src={Images?.linkedin}
                    style={{ height: "60%", width: "60%" }}
                  />
                </div>
              </LinkedinShareButton>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={0.5}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <TwitterShareButton url={Setting?.mainURL}>
                <div className="iconstyle">
                  <img
                    alt="Error"
                    src={Images?.twitter}
                    style={{ height: "60%", width: "60%" }}
                  />
                </div>
              </TwitterShareButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <div>
      {renderFirst()}
      {renderSecond()}
      {renderThird()}
      {/* {isEmployerEmpty ? null : renderFourth()} */}
      {renderFifth()}
      {isEmployerEmpty ? null : renderSixth()}
      {renderSeventh()}
      <MUserDetail
        visible={openModal}
        from="employer"
        handleModal={() => {
          setOpenModal(false);
          setModalSaveBtnLoader(false);
        }}
        socialUserInfo={socialUserInfo}
        modalSaveBtnLoader={modalSaveBtnLoader}
        onSignUpAction={(v) => {
          companySignupProcess(v);
          setModalSaveBtnLoader(true);
        }}
      />
      {loginModal && (
        <MLogin
          visible={loginModal}
          handleModal={() => {
            setLoginModal(false);
          }}
          forgotClick={() => {
            setLoginModal(false);
            setForgotModal(true);
          }}
          loginClick={() => {
            setLoginModal(false);
          }}
          isEmployerHome={isEmployerHome}
        />
      )}
      {forgotModal && (
        <MForgot
          visible={forgotModal}
          handleModal={() => {
            setForgotModal(false);
          }}
        />
      )}
    </div>
  );
}
