import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "7% !important",
    marginBottom: "50px !important",
    "@media (max-width:1024px)": {
      marginTop: "9% !important",
      paddingLeft: "24px !important",
    },
    "@media (max-width:768px)": {
      marginTop: "10% !important",
    },
    "@media (max-width:425px)": {
      paddingRight: 24,
      marginTop: "20% !important",
    },
  },
  shadowViewStyle: {
    boxShadow: "rgb(48 48 48 / 14%) 0px 3px 6px 2px",
    borderRadius: 3,
  },
  singleViewStyle: {
    display: "flex",
    padding: "12px 20px",
    cursor: "pointer",
    "@media (max-width:1024px)": {
      padding: "12px 15px",
    },
  },
  paddingStyle: {
    padding: "20px 25px",
  },
  marginDiv: {
    marginBottom: "24px !important",
  },
  splitViewStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  addIcon: {
    color: color.primary,
    fontSize: "35px !important",
    cursor: "pointer",
    "&:hover": {
      color: "#243C53",
    },
  },
  sectionTitle: {
    color: color.primary,
    fontFamily: "PoppinsSemiBold !important",
    fontSize: "20px !important",
  },
  sectionDesc: {
    color: color.black,
    fontFamily: "PoppinsRegular !important",
    fontSize: "14px !important",
    margin: "5px 0px !important",
  },
  editBtnStyle: {
    height: "30px",
    width: "30px",
    borderRadius: "15px",
    backgroundColor: color.primary,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    boxShadow: "0px 3px 6px #00000052",
  },
  profileDetailText: {
    color: color.black,
    fontFamily: "PoppinsMedium !important",
    fontSize: "15px !important",
  },
  userTitleStyle: {
    color: color.primary,
    fontFamily: "PoppinsSemiBold !important",
    fontSize: "25px !important",
    letterSpacing: 2,
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px !important",
    },
  },
  progessTextStyle: {
    color: "#1F4771",
    fontFamily: "PoppinsMedium !important",
    fontSize: "16px !important",
  },
  listTextStyle: {
    fontFamily: "PoppinsRegular !important",
    fontSize: "14px !important",
    color: "#FFF",
    padding: "6px 0px",
    cursor: "pointer",
  },
  profileContainerStyle: {
    marginBottom: 100,
    display: "flex !important",
    justifyContent: "space-between",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    "@media (max-width:1024px)": {
      marginBottom: 50,
      paddingRight: "24px !important",
    },
    [theme.breakpoints.down("sm")]: {
      display: "unset !important",
    },
    "@media (max-width:425px)": {
      paddingRight: "0px !important",
      marginBottom: 40,
    },
  },
  userImgStyle: {
    height: 140,
    width: 140,
    borderRadius: "100%",
    border: `5px solid ${color.primary}`,
    "@media (max-width:768px)": {
      height: 100,
      width: 100,
    },
    "@media (max-width:425px)": {
      height: 70,
      width: 70,
    },
  },
  userContentStyle: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 15,
    [theme.breakpoints.down("md")]: {
      marginTop: 8,
    },
    "@media (max-width:425px)": {
      flexDirection: "column",
    },
  },
  userDetailTextStyle: {
    display: "flex",
    alignItems: "center",
    margin: "6px 0px",
    [theme.breakpoints.down("md")]: {
      margin: "4px 0px",
    },
  },
  emailImgStyle: {
    width: 38,
    marginRight: 10,
    [theme.breakpoints.down("md")]: {
      width: 30,
    },
  },
  userDetailIconStyle: {
    width: 36,
    marginRight: 10,
    [theme.breakpoints.down("md")]: {
      width: 30,
    },
  },
  gradientDivStyle: {
    padding: "15px 20px",
    background:
      "transparent linear-gradient(242deg, #3A5B7E 0%, #1F4771 100%) 0% 0% no-repeat padding-box",
    borderRadius: 2,
    display: "flex",
    flexDirection: "column !important",
    justifyContent: "space-evenly",
  },
  cameraIcon: {
    color: "#fff !important",
    right: "10px",
    bottom: "4px",
    padding: "0px !important",
    position: "absolute !important",
    borderRadius: "100% !important",
    backgroundColor: `${color.primary} !important`,
    minWidth: "35px !important",
    minHeight: "35px !important",
    "@media (max-width:425px)": {
      minWidth: "30px !important",
      minHeight: "30px !important",
      bottom: 0,
      right: 0,
    },
  },
  mobileIconSize: {
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
    },
  },
  pendingActionText: {
    color: "#FFF",
    padding: "6px 0px",
    cursor: "pointer",
  },
  buttonAbsoluteDiv: {
    position: "absolute",
    top: 15,
    right: 15,
  },
  videoContainer: {
    marginTop: 40,
    "@media (max-width:425px)": {
      marginBottom: 20,
    },
  },
  videoTitle: {
    fontFamily: "PoppinsMedium !important",
    marginBottom: "15px !important",
  },
  videoSectionStyle: {
    height: 300,
    // border: "1px dashed #1F4771",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxShadow: "0px 3px 15px #00000029",
    borderRadius: "4px",
  },
  videoIcon: {
    backgroundColor: `${color.primary} !important`,
    minWidth: "35px !important",
    minHeight: "35px !important",
    borderRadius: "100% !important",
    padding: "0px !important",
  },
  ml30: {
    marginLeft: "30px !important",
    "@media (max-width:1024px)": {
      marginLeft: "75px !important",
    },
    "@media (max-width:425px)": {
      marginLeft: "0px !important",
    },
  },
  orderListStyle: {
    marginRight: 50,
    marginInlineStart: 15,
    "@media (max-width:1024px)": {
      marginRight: 30,
    },
  },
  videoStyle: {
    height: 300,
  },
  hideMobileView: {
    "@media (max-width:425px)": {
      display: "none",
    },
  },
  profilePercentDiv: {
    marginTop: 20,
    "@media (max-width:425px)": {
      marginBottom: 20,
    },
  },
  detailContainer: {
    paddingRight: "0px !important",
    "@media (max-width:1024px)": {
      paddingRight: "24px !important",
    },
    "@media (max-width:425px)": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },
  deviceContentResponsive: {
    "@media (max-width:425px)": {
      display: "flex",
    },
  },
  fullWidthStyle: {
    "@media (max-width:425px)": {
      width: "100% !important",
    },
  },
  cameraImgStyle: {
    width: 20,
    "@media (max-width:425px)": {
      width: 18,
    },
  },
  hideWebView: {
    display: "none",
    "@media (max-width:425px)": {
      display: "flex",
    },
  },
  employeeContainer: {
    marginBottom: "100px !important",
    "@media (max-width:1024px)": {
      paddingLeft: "24px !important",
      marginBottom: "50px !important",
    },
    "@media (max-width:768px)": {
      paddingLeft: "0px !important",
    },
    "@media (max-width:425px)": {
      paddingRight: 0,
      marginBottom: "40px !important",
    },
  },
  profileImgStyle: {
    height: "240px",
    width: "240px",
    objectFit: "cover",
    borderRadius: 3,
    "@media (max-width:768px)": {
      height: "160px",
      width: "160px",
    },
    "@media (max-width:425px)": {
      marginBottom: 20,
    },
  },
  unlockProfileSection: {
    display: "flex",
    alignItems: "center",
    marginTop: 20,
    "@media (max-width:768px)": {
      display: "unset",
    },
  },
  unlockText: { fontFamily: "PoppinsMedium", color: "#000" },
  unlockBtnStyle: {
    marginLeft: "30px !important",
    "@media (max-width:768px)": {
      marginLeft: "0px !important",
    },
  },
  profileImgSection: {
    "@media (max-width:425px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 15,
    },
  },
  makeOfferBtnStyle: {
    "@media (max-width:425px)": {
      marginBottom: "15px !important",
    },
  },
  imgLoader: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "140px",
    top: "-130px",
    bottom: 0,
    left: 0,
    right: 0,
    "@media (max-width:768px)": {
      height: 100,
      top: "-90px",
    },
    "@media (max-width:425px)": {
      height: 70,
      top: 5,
    },
  },
  actionWraper: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 10,
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    minWidth: "120px",
  },
  actionDot: {
    backgroundColor: "#FFF",
    height: 7,
    width: 7,
    borderRadius: "100%",
    marginRight: 10,
  },
  loaderView: {
    width: "100%",
    minHeight: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  userDetailStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:768px)": {
      display: "unset",
    },
  },
  deleteBtnStyle: {
    backgroundColor: color.white,
    border: `1px solid ${color.secondary}`,
    borderRadius: "100%",
  },
}));

export default useStyles;
