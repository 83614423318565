import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import useStyles from "../../styles";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Images from "../../../../Config/Images";
import CDropDown from "../../../CDropDown";
import { Setting } from "../../../../Utils/Setting";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { isEmpty } from "lodash";
import PlaceAutoComplete from "../../../PlaceAutoComplete";
import MapContainer from "../../../MapContainer";

const errorObj = {
  sizeErr: false,
  cultureErr: false,
  sectorErr: false,
  websiteErr: false,
  fbErr: false,
  instaErr: false,
  linkedinErr: false,
  twitterErr: false,
  sizeMsg: "",
  cultureMsg: "",
  sectorMsg: "",
  websiteMsg: "",
  fbMsg: "",
  instaMsg: "",
  linkedinMsg: "",
  twitterMsg: "",
};

function MDetail(props) {
  const { visible = false, handleModal = () => null, data = {} } = props;
  const { dropdowndata, token } = useSelector((state) => state.auth);
  const [companySize, setCompanySize] = useState("");
  const [culture, setCulture] = useState("");
  const [sector, setSector] = useState("");
  const [website, setWebsite] = useState("");

  const [fbUrl, setFbUrl] = useState("");
  const [instaUrl, setInstaUrl] = useState("");
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [errObj, setErrObj] = useState(errorObj);

  const [selectedLocation, setSelectedLocation] = useState({});
  const [locationName, setLocationName] = useState("");

  const [btnLoad, setBtnLoad] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : 330,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    maxHeight: 550,
  };

  useEffect(() => {
    if (visible) {
      setCompanySize(data?.company_size || "");
      setCulture(data?.company_culture || "");
      setSector(data?.sector || "");
      setWebsite(data?.company_website || "");
      setFbUrl(data?.facebook_url || "");
      setInstaUrl(data?.instagram_url || "");
      setTwitterUrl(data?.twitter_url || "");
      setLinkedInUrl(data?.linkedin_url || "");
      setLocationName(data?.location || "");
      setSelectedLocation({
        location: data?.location || "",
        lat: data?.company_lat || null,
        lng: data?.company_long || null,
      });
    }
    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  useEffect(() => {
    if (!isEmpty(selectedLocation)) {
      setLocationName(selectedLocation?.location || "");
    }
  }, [selectedLocation]);

  function resetFormData(type = "") {
    setCompanySize("");
    setCulture("");
    setSector("");
    setWebsite("");
    setFbUrl("");
    setInstaUrl("");
    setLinkedInUrl("");
    setTwitterUrl("");
    setLocationName("");
    setSelectedLocation({});
    setErrObj(errorObj);
    if (type === "close" && handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(companySize.toString())) {
      valid = false;
      error.sizeErr = true;
      error.sizeMsg = "Please select company size";
    } else if (isEmpty(culture.toString())) {
      valid = false;
      error.cultureErr = true;
      error.cultureMsg = "Please select culture";
    } else if (isEmpty(sector.toString())) {
      valid = false;
      error.sectorErr = true;
      error.sectorMsg = "Please select sector";
    } else if (isEmpty(website)) {
      valid = false;
      error.websiteErr = true;
      error.websiteMsg = "Please select website URL";
    } else if (
      !isEmpty(fbUrl) &&
      !(fbUrl.indexOf("https://") === 0 || fbUrl.indexOf("http://") === 0)
    ) {
      valid = false;
      error.fbErr = true;
      error.fbMsg = "Please add http:// or https://";
    } else if (
      !isEmpty(instaUrl) &&
      !(
        instaUrl.indexOf("https://") === 0 || instaUrl.indexOf("http://") === 0
      ) &&
      !isEmpty(instaUrl)
    ) {
      valid = false;
      error.instaErr = true;
      error.instaMsg = "Please add http:// or https://";
    } else if (
      !isEmpty(linkedInUrl) &&
      !(
        linkedInUrl.indexOf("https://") === 0 ||
        linkedInUrl.indexOf("http://") === 0
      ) &&
      !isEmpty(linkedInUrl)
    ) {
      valid = false;
      error.linkedinErr = true;
      error.linkedinMsg = "Please add http:// or https://";
    } else if (
      !isEmpty(twitterUrl) &&
      !(
        twitterUrl.indexOf("https://") === 0 ||
        twitterUrl.indexOf("http://") === 0
      ) &&
      !isEmpty(twitterUrl)
    ) {
      valid = false;
      error.twitterErr = true;
      error.twitterMsg = "Please add http:// or https://";
    }

    setErrObj(error);

    if (valid) {
      updateDetails();
    }
  }

  // this function for update information to server
  async function updateDetails() {
    setBtnLoad(true);
    const obj = {
      "EmployerCompany[company_size]": companySize,
      "EmployerCompany[culture_id]": culture,
      "EmployerCompany[sector_id]": sector,
      "EmployerCompany[company_website]": website,
      "EmployerCompany[facebook_url]": fbUrl,
      "EmployerCompany[twitter_url]": twitterUrl,
      "EmployerCompany[linkedin_url]": linkedInUrl,
      "EmployerCompany[instagram_url]": instaUrl,
      "EmployerCompany[location]": selectedLocation?.location || null,
      "EmployerCompany[company_lat]": selectedLocation?.lat || null,
      "EmployerCompany[company_long]": selectedLocation?.lng || null,
    };

    try {
      const response = await getAPIProgressData(
        Setting.endpoints.employerDetailsEdit,
        "POST",
        obj,
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        resetFormData("update", response.data);
        handleModal("success");
      } else {
        toast.error(response.message);
      }
      setBtnLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setBtnLoad(false);
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("close")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography variant="subtitle1" component="h2">
              Edit Details
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => resetFormData("close")}
            />
          </div>
          <div className="sliderscroll" style={{ maxHeight: 350 }}>
            <CDropDown
              data={dropdowndata?.company_size || []}
              value={companySize}
              error={errObj.sizeErr}
              helperText={errObj.sizeErr ? errObj.sizeMsg : null}
              onChange={(v) => {
                setCompanySize(v.target.value);
                setErrObj({ ...errObj, sizeErr: false, sizeMsg: "" });
              }}
              placeholder="Select company size"
              label="Select the company size"
              style={{ width: "100%" }}
            />
            <CDropDown
              data={dropdowndata?.company_culture || []}
              value={culture}
              error={errObj.cultureErr}
              helperText={errObj.cultureErr ? errObj.cultureMsg : null}
              onChange={(v) => {
                setCulture(v.target.value);
                setErrObj({ ...errObj, cultureErr: false, cultureMsg: "" });
              }}
              placeholder="Select culture"
              label="Select the culture"
              style={{ width: "100%" }}
            />
            <CDropDown
              data={dropdowndata?.sectors || []}
              value={sector}
              error={errObj.sectorErr}
              helperText={errObj.sectorErr ? errObj.sectorMsg : null}
              onChange={(v) => {
                setSector(v.target.value);
                setErrObj({ ...errObj, sectorErr: false, sectorMsg: "" });
              }}
              placeholder="Select sector or industry"
              label="Select the sector or industry"
              style={{ width: "100%" }}
            />
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Company website
            </Typography>
            <TextField
              placeholder="Website URL"
              value={website}
              error={errObj.websiteErr}
              helperText={errObj.websiteErr ? errObj.websiteMsg : null}
              onChange={(v) => {
                setWebsite(v.target.value);
                setErrObj({ ...errObj, websiteErr: false, websiteMsg: "" });
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />

            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              {`Postcode: ${locationName}`}
            </Typography>
            <PlaceAutoComplete
              placeholder="Enter postcode for the UK only"
              style={{ width: "100%", marginBottom: 10 }}
              onChange={(obj) => setSelectedLocation(obj)}
              // disable={remoteOnly ? true : false}
            />
            <div
              className={classes.mapContainerStyle}
              style={isEmpty(selectedLocation) ? { display: "none" } : {}}
            >
              <MapContainer data={selectedLocation} miles={0} />
            </div>
            <Typography
              className="primarycolor mediumfont"
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              Social media link
            </Typography>
            <TextField
              placeholder="Facebook URL"
              value={fbUrl}
              error={errObj?.fbErr}
              helperText={errObj.fbErr ? errObj.fbMsg : null}
              onChange={(v) => {
                setFbUrl(v.target.value);
                setErrObj({ ...errObj, fbErr: false, fbMsg: "" });
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
            <TextField
              placeholder="Instagram URL"
              value={instaUrl}
              error={errObj?.instaErr}
              helperText={errObj.instaErr ? errObj.instaMsg : null}
              onChange={(v) => {
                setInstaUrl(v.target.value);
                setErrObj({ ...errObj, instaErr: false, instaMsg: "" });
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
            <TextField
              placeholder="Linkedin URL"
              value={linkedInUrl}
              error={errObj?.linkedinErr}
              helperText={errObj.linkedinErr ? errObj.linkedinMsg : null}
              onChange={(v) => {
                setLinkedInUrl(v.target.value);
                setErrObj({ ...errObj, linkedinErr: false, linkedinMsg: "" });
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
            <TextField
              placeholder="Twitter URL"
              value={twitterUrl}
              error={errObj?.twitterErr}
              helperText={errObj.twitterErr ? errObj.twitterMsg : null}
              onChange={(v) => {
                setTwitterUrl(v.target.value);
                setErrObj({ ...errObj, twitterErr: false, twitterMsg: "" });
              }}
              style={{ width: "100%", marginBottom: 8 }}
            />
          </div>
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid xs={5.8} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8}>
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={() => {
                  checkValidation();
                }}
                disabled={btnLoad}
              >
                {btnLoad ? (
                  <CircularProgress style={{ color: "#fff" }} size={26} />
                ) : (
                  "SAVE"
                )}
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MDetail;
