import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  detailContainer: {
    paddingLeft: "0px !important",
    "@media (max-width:425px)": {
      paddingRight: "0px !important",
    },
  },
  paddingmain: {
    padding: "20px 20px",
    marginBottom: "20px !important",
  },
  detailmain: {
    borderRadius: 4,
    boxShadow: "0px 3px 15px #00000029",
  },
  detailfirst: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
  },
  detailsecond: {
    padding: "14px 20px",
  },
  detaildivider: {
    borderBottom: "1px solid #3A5B7E",
  },
  videoContainer: {
    marginTop: 40,
    "@media (max-width:425px)": {
      marginBottom: 20,
    },
  },
  videoTitle: {
    fontFamily: "PoppinsMedium !important",
    marginBottom: "15px !important",
  },
  videoSectionStyle: {
    height: 300,
    // border: "1px dashed #1F4771",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxShadow: "0px 3px 15px #00000029",
    borderRadius: "4px",
  },
  videoIcon: {
    backgroundColor: `${color.primary} !important`,
    minWidth: "35px !important",
    minHeight: "35px !important",
    borderRadius: "100% !important",
    padding: "0px !important",
  },
  videoStyle: {
    height: 300,
  },
  buttonAbsoluteDiv: {
    position: "absolute",
    top: 15,
    right: 15,
  },
  topimgmain: {
    borderRadius: 4,
    padding: 6,
    boxShadow: "0px 3px 6px #00000029",
  },
  companyimgmain: {
    borderRadius: 4,
    height: "100%",
    width: "100%",
  },
  editprofilemain: {
    display: "flex",
    padding: "0px 20px !important",
    justifyContent: "center",
    "@media (max-width:768px)": {
      padding: "0px 0px !important",
    },
  },
  cDetailSection: {
    marginBottom: 15,
  },
  textBlack: {
    color: "#000 !important",
  },
  fontWeight700: {
    fontWeight: "700 !important",
  },
  socialContainer: {
    display: "flex",
  },
  socialDiv: {
    height: 35,
    width: 35,
    borderRadius: "100%",
    backgroundColor: color.primary,
    boxShadow: "0px 3px 6px #00000052",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15,
    cursor: "pointer",
  },
  socialImgStyle: { width: 15 },
  sectionDesc: {
    color: color.black,
    fontFamily: "PoppinsRegular !important",
    fontSize: "14px !important",
    margin: "5px 0px !important",
  },
  loaderView: {
    width: "100%",
    minHeight: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  viewBtnContainer: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    "@media (max-width:768px)": {
      display: "unset",
    },
  },
  viewCandidateBtnStyle: {
    width: "100%",
    "@media (max-width:768px)": {
      marginTop: "20px !important",
    },
  },
  activeDetail: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:425px)": {
      display: "unset",
    },
  },
}));

export default useStyles;
