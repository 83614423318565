import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Checkbox,
  useTheme,
  Radio,
  useMediaQuery,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles";
import { getAPIProgressData, getApiData } from "../../../Utils/APIHelper";
import { isEmpty, isArray } from "lodash";

export default function CompanyQuestion() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isAdded = location?.state?.addedJob;
  const jobPostData = location?.state?.jobPostData;
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { token } = useSelector((state) => state.auth);
  const header = { Authorization: `Bearer ${token}` };

  const [pageLoader, setPageLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const [checked, setChecked] = useState([]);
  const [firstStandard, setFirstStandard] = useState([]);
  const isNoData = isEmpty(firstStandard);

  // const leftChecked = intersection(checked, standardList);
  // const rightChecked = intersection(checked, selectedList);

  useEffect(() => {
    document.title = Setting.page_name.EMPLOYER_QUESTION;
    getQuestionList();
  }, []);

  // function not(a, b) {
  //   return a.filter((value) => b.indexOf(value) === -1);
  // }

  function setFilterList(firstStandard1, firstSelected) {
    const dummyArr = [...firstStandard1];
    const EmptyArr = [];

    firstSelected.map((ii) => {
      dummyArr.map((ii2, index) => {
        if (Number(ii?.id) === Number(ii2?.id)) {
          dummyArr.splice(index, 1);
          dummyArr.push(ii);
        }
      });
    });
    firstSelected.map((ii) => {
      dummyArr.map((ii2, index) => {
        if (Number(ii?.id) === Number(ii2?.id)) {
          EmptyArr.push(ii);
        }
      });
    });
    setFirstStandard(dummyArr);
    setChecked(EmptyArr);
  }

  async function getQuestionList() {
    setPageLoader(true);
    const id = isAdded
      ? location.state.jobPostData.job_id
      : jobPostData?.role_id;
    try {
      let endPoint = `${Setting.endpoints.jobQuestionList}?job_id=${id}`;
      const response = await getApiData(endPoint, "GET", null, header);
      if (response && response.status && response.status === true) {
        if (isArray(response.data) && !isEmpty(response.data)) {
          setFirstStandard(response?.data);
          if (isAdded) {
            getEditQuestionList(response?.data);
          } else {
            setPageLoader(false);
          }
        } else {
          setPageLoader(false);
        }
      } else {
        console.log("err==>>");
        toast.error(response?.message);
        setPageLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      setPageLoader(false);
      err("Something_went_wrong");
    }
  }

  async function getEditQuestionList(firstData) {
    setPageLoader(true);
    try {
      let endPoint = `${Setting.endpoints.employerQuestionList}?job_post_id=${location.state.jobPostData.id}`;
      const response = await getApiData(endPoint, "GET", null, header);
      if (response && response.status && response.status === true) {
        if (isArray(response.data) && !isEmpty(response.data)) {
          setFilterList(firstData, response?.data);
        }
        setPageLoader(false);
      } else {
        console.log("err==>>");
        toast.error(response?.message);
        setPageLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      setPageLoader(false);
      err("Something_went_wrong");
    }
  }

  async function addJobQuestion() {
    setBtnLoader(true);
    const sendDataArr = isEmpty(checked) ? [...firstStandard] : [...checked];
    const jobPostId = isAdded
      ? location.state.jobPostData.id
      : jobPostData.job_post_id;

    const addjobQuestionData = {};

    sendDataArr.map((item, index) => {
      addjobQuestionData[`UpdateQuizForm[${index}][job_post_id]`] = jobPostId;
      addjobQuestionData[`UpdateQuizForm[${index}][question_id]`] = item.id;
      addjobQuestionData[`UpdateQuizForm[${index}][selected_opt]`] =
        item.standard_ans;
    });

    try {
      let endPoint = Setting.endpoints.employerUpdateQuiz;
      const response = await getAPIProgressData(
        endPoint,
        "POST",
        addjobQuestionData,
        header
      );
      if (response?.status) {
        toast.success(response?.message);
        setBtnLoader(false);
        navigate(isAdded ? -1 : -2);
      } else {
        //display error msg here
        toast.error(response?.message);
        setBtnLoader(false);
      }
    } catch (err) {
      console.log("Catch Part", err);
      //display something went wrong error msg here
      err("Something_went_wrong");
      setBtnLoader(false);
    }
  }

  // function checkValidation() {
  //   let valid = true;

  //   if (isEmpty(selectedList)) {
  //     valid = false;
  //     toast.error("Please select question from left side");
  //   }

  //   if (valid) {
  //     addJobQuestion();
  //   }
  // }

  // function intersection(a, b) {
  //   return a.filter((value) => b.indexOf(value) !== -1);
  // }

  function handleToggle(value) {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  }

  function handleChange(item, ii) {
    const arr = [...firstStandard];
    const selectedArr = [...checked];

    arr.map((i2) => {
      if (i2?.id === item?.id) {
        i2.standard_ans = ii?.id.toString();
      } else {
        console.log("check==>>");
      }
      setFirstStandard(arr);
    });
    selectedArr.map((i3) => {
      if (i3?.id === item?.id) {
        i3.standard_ans = ii?.id.toString();
      } else {
        console.log("check==>>");
      }
      setChecked(selectedArr);
    });
  }

  // function handleCheckedRight() {
  //   setSelectedList(selectedList.concat(leftChecked));
  //   setStandardList(not(standardList, leftChecked));
  //   setChecked(not(checked, leftChecked));
  // }

  // function handleCheckedLeft() {
  //   setStandardList(standardList.concat(rightChecked));
  //   setSelectedList(not(selectedList, rightChecked));
  //   setChecked(not(checked, rightChecked));
  // }

  // function handleAllRight() {
  //   setSelectedList(selectedList.concat(standardList));
  //   setStandardList([]);
  //   navigate(isAdded ? -1 : -2);
  // }

  // function handleAllLeft() {
  //   setStandardList(standardList.concat(selectedList));
  //   setSelectedList([]);
  // }

  function renderCards(item, index) {
    return (
      <div className={classes.cardmain1}>
        <Grid container alignItems="flex-start" style={{ marginBottom: 10 }}>
          <Grid xs={10.5} sm={10.5} md={11}>
            <Typography className="primarycolor semibold18">
              {`${index + 1}. ${item?.question}`}
            </Typography>
          </Grid>
          <Grid
            xs={1.5}
            sm={1.5}
            md={1}
            display="flex"
            justifyContent="flex-end"
          >
            <Checkbox
              checked={checked.indexOf(item) !== -1}
              onClick={() => {
                handleToggle(item);
              }}
            />
          </Grid>
        </Grid>
        {/* <Typography className="primarycolor smallsemiboldfont">
          {`<a href="http://www.linkedin.com" target="_blank">Visit site</a>`}
        </Typography> */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {item?.options.map((ii, index1) => {
            const str = ii?.id;
            const isSelected = item?.standard_ans === str.toString();
            return (
              <div className={classes.radiomain}>
                <Radio
                  checked={isSelected}
                  onChange={() => {
                    handleChange(item, ii);
                  }}
                  value="a"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "A" }}
                  sx={{
                    "&.Mui-checked": {
                      color: "#00B51E",
                    },
                  }}
                  style={{ padding: 0, marginRight: 6 }}
                />
                <Typography
                  className="regulerfont"
                  style={{ color: isSelected ? "#00B51E" : "#1f4771" }}
                >
                  {ii?.value}
                </Typography>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function renderLeft() {
    return (
      <>
        <Typography
          className="mediumsemiboldfont primarycolor"
          style={{ display: "flex", alignItems: "center" }}
        >
          Standard Questions List{" "}
          <Tooltip title="Select the number of questions you would like to include in the candidate’s Situational Judgement Test by ticking the boxes and select your preferred answers by clicking on the answers. Your answer selections will be highlighted in green.">
            <HelpIcon style={{ marginLeft: 10 }} />
          </Tooltip>
        </Typography>
        <Typography
          className="lightsilvercolor regulerfont"
          style={{ marginBottom: 20 }}
        >
          Use this template to add quiz for{" "}
          <span style={{ color: "#000", fontFamily: "PoppinsMedium" }}>
            Job Post
          </span>
        </Typography>
        {firstStandard.map((ii, index) => {
          return renderCards(ii, index);
        })}
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            style={{ width: sm ? "100%" : 250 }}
            variant="contained"
            disabled={btnLoader}
            onClick={() => {
              // handleAllRight();
              addJobQuestion();
            }}
            // fullWidth={sm}
          >
            {btnLoader ? (
              <CircularProgress style={{ color: "#fff" }} size={26} />
            ) : isAdded ? (
              "Update"
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </>
    );
  }

  // function renderBtn() {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: sm ? "row" : "column",
  //         alignItems: "center",
  //         justifyContent: "center",
  //         height: "100%",
  //       }}
  //     >
  //       <Button
  //         className={classes.btnmain}
  //         component="label"
  //         variant="contained"
  //         onClick={() => {
  //           handleCheckedRight();
  //         }}
  //       >
  //         {sm ? <KeyboardDoubleArrowDown /> : <KeyboardDoubleArrowRight />}
  //       </Button>
  //       <Button
  //         className={classes.btnmain}
  //         component="label"
  //         variant="contained"
  //         onClick={() => {
  //           handleCheckedLeft();
  //         }}
  //       >
  //         {sm ? <KeyboardDoubleArrowUp /> : <KeyboardDoubleArrowLeft />}
  //       </Button>
  //     </div>
  //   );
  // }

  // function renderRight() {
  //   return (
  //     <>
  //       <Typography className="mediumsemiboldfont primarycolor">
  //         Selected for the Job Post
  //       </Typography>
  //       <Typography
  //         className="lightsilvercolor regulerfont"
  //         style={{ marginBottom: 20 }}
  //       >
  //         Use this template to edit the answer of quiz for{" "}
  //         <span style={{ color: "#000", fontFamily: "PoppinsMedium" }}>
  //           Job Post
  //         </span>
  //       </Typography>
  //       {isArray(selectedList) && selectedList.length > 0
  //         ? selectedList.map((ii, index) => {
  //             return renderCards(ii, index);
  //           })
  //         : null}
  //       <Grid container>
  //         <Grid
  //           xs={12}
  //           sm={5.8}
  //           md={5}
  //           style={{ marginRight: sm ? 0 : 10, marginBottom: 10 }}
  //         >
  //           <Button
  //             variant="contained"
  //             fullWidth
  //             onClick={() => {
  //               handleAllLeft();
  //             }}
  //           >
  //             Reset
  //           </Button>
  //         </Grid>
  //         <Grid xs={12} sm={5.8} md={5}>
  //           <Button
  //             variant="contained"
  //             onClick={() => {
  //               checkValidation();
  //             }}
  //             disabled={btnLoader}
  //             fullWidth
  //           >
  //             {btnLoader ? (
  //               <CircularProgress style={{ color: "#fff" }} size={26} />
  //             ) : (
  //               "update quiz"
  //             )}
  //           </Button>
  //         </Grid>
  //       </Grid>
  //     </>
  //   );
  // }

  function renderMain() {
    return (
      <Box sx={{ flexGrow: 1, marginTop: sm ? 5 : 1 }}>
        <Grid container>
          {pageLoader ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                height: 500,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : isNoData ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                height: 500,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>No Data</Typography>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Grid item xs={12} sm={8} md={10} style={{ marginBottom: 20 }}>
                {renderLeft()}
              </Grid>
              {/* <Grid item xs={12} sm={1} md={1}>
                {renderBtn()}
              </Grid>
              <Grid item xs={12} sm={5.5} md={5.5}>
                {renderRight()}
              </Grid> */}
            </div>
          )}
        </Grid>
      </Box>
    );
  }

  return <div>{renderMain()}</div>;
}
