import React, { useState, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import useStyles from "../styles";
import MLocation from "../../Profile/Modal/MLocation";
import Images from "../../../Config/Images";
import { isEmpty } from "lodash";

function JobLocation(props) {
  const { setData = () => null, data = {} } = props;
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const [editData, setEditData] = useState({});
  const isComplete = !isEmpty(editData);
  const isDataEmpty = !isEmpty(data);

  useEffect(() => {
    if (isDataEmpty) {
      setEditData(
        {
          selectedLocation: {
            location: data?.location,
            lat: data?.lat,
            lng: data?.long,
          },
          remoteOnly: data?.is_remote === 1 ? true : false,
        } || {}
      );
    }
  }, [data]);

  useEffect(() => {
    setData(editData);
  }, [editData]);

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div id="joblocation" className={classes.splitViewStyle}>
        <Typography className={classes.sectionTitle}>Job postcode</Typography>
        {isComplete ? (
          <div
            className={classes.editBtnStyle}
            onClick={() => setOpenModal(true)}
          >
            <img src={Images.edit} style={{ width: 14 }} />
          </div>
        ) : (
          <AddCircle
            className={classes.addIcon}
            onClick={() => {
              setOpenModal(true);
            }}
          />
        )}
      </div>
      {isComplete ? (
        <div style={{ marginTop: 10 }}>
          {editData?.remoteOnly ? (
            <Typography className="regulerfont primarycolor">
              You have selected candidates from all locations within the United
              Kingdom
            </Typography>
          ) : (
            <Typography className="regulerfont primarycolor">
              Postcode: {editData?.selectedLocation?.location}
            </Typography>
          )}
        </div>
      ) : (
        <Typography className={classes.sectionDesc}>Add postcode</Typography>
      )}

      <MLocation
        visible={openModal}
        handleModal={() => {
          setOpenModal(false);
        }}
        onSave={(data) => {
          setEditData(data);
          setOpenModal(false);
        }}
        editData={editData}
      />
    </Grid>
  );
}

export default JobLocation;
