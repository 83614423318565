import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "7% !important",
    marginBottom: "50px !important",
    "@media (max-width:1024px)": {
      marginTop: "9% !important",
    },
    "@media (max-width:768px)": {
      marginTop: "6% !important",
    },
    "@media (max-width:425px)": {
      marginTop: "8% !important",
    },
  },
  detailContainer: {
    paddingLeft: "0px !important",
    "@media (max-width:768px)": {
      paddingLeft: "0px !important",
      paddingRight: "18px !important",
    },
    "@media (max-width:425px)": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },
  shadowViewStyle: {
    boxShadow: "rgb(48 48 48 / 14%) 0px 3px 6px 2px",
    borderRadius: 3,
    overflow: "hidden",
  },
  paddingStyle: {
    padding: "20px 25px",
    "@media (max-width:1024px)": {
      padding: "12px 15px",
    },
  },
  marginDiv: {
    marginBottom: "24px !important",
  },
  coverImgStyle: {
    width: "100%",
    height: 140,
    minHeight: 140,
    objectFit: "cover",
    "@media (max-width:425px)": {
      height: 180,
      minHeight: 180,
    },
  },
  companyDetailContainer: {
    padding: "15px 25px 15px 20px",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:425px)": {
      padding: "10px 25px 10px 20px",
    },
  },
  companyImgContainer: {
    boxShadow: "0px 3px 6px #00000029",
    padding: 5,
    backgroundColor: "#FFF",
    borderRadius: 2,
    height: 110,
    width: 110,
    marginTop: -66,
    "@media (max-width:768px)": {
      width: 100,
      height: 100,
      marginTop: -55,
    },
    "@media (max-width:425px)": {
      width: 90,
      height: 90,
    },
  },
  companyImgStyle: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 2,
  },
  sectionTitle: {
    color: color.primary,
    fontFamily: "PoppinsSemiBold !important",
    fontSize: "20px !important",
    "@media (max-width:768px)": {
      fontSize: "18px !important",
    },
  },
  sectionDesc: {
    color: color.black,
    fontFamily: "PoppinsRegular !important",
    fontSize: "14px !important",
    margin: "5px 0px !important",
  },
  singleViewStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 20px",
    borderBottom: "1px solid #34567b",
    "@media (max-width:1024px)": {
      padding: "6px 15px",
    },
  },
  cDetailSection: {
    marginBottom: 15,
  },
  textBlack: {
    color: "#000",
  },
  fontWeight700: {
    fontWeight: "700 !important",
  },
  socialContainer: {
    display: "flex",
  },
  socialDiv: {
    height: 35,
    width: 35,
    borderRadius: "100%",
    backgroundColor: color.primary,
    boxShadow: "0px 3px 6px #00000052",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 15,
    cursor: "pointer",
  },
  socialImgStyle: { width: 15 },
  videoContainer: {
    marginTop: 40,
    "@media (max-width:425px)": {
      marginBottom: 20,
    },
  },
  videoTitle: {
    fontFamily: "PoppinsMedium !important",
    marginBottom: "15px !important",
  },
  videoSectionStyle: {
    height: 300,
    // border: "1px dashed #1F4771",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxShadow: "0px 3px 15px #00000029",
    borderRadius: "4px",
  },
  videoIcon: {
    backgroundColor: `${color.primary} !important`,
    minWidth: "35px !important",
    minHeight: "35px !important",
    borderRadius: "100% !important",
    padding: "0px !important",
  },
  videoStyle: {
    height: 300,
  },
  buttonAbsoluteDiv: {
    position: "absolute",
    top: 15,
    right: 15,
  },
  pl20: {
    paddingLeft: 20,
  },
  posterDiv: {
    width: "100%",
    height: 140,
    backgroundColor: "#D3DBE5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addIcon: {
    color: color.primary,
    fontSize: "35px !important",
    cursor: "pointer",
    "&:hover": {
      color: "#243C53",
    },
  },
  cDetailDivStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "82%",
    "@media (max-width:1024px)": {
      width: "78%",
    },
    "@media (max-width:768px)": {
      width: "68%",
    },
    "@media (max-width:425px)": {
      width: "66%",
    },
  },
  noCImgDiv: {
    backgroundColor: "#D3DBE5",
    height: "100%",
    width: "100%",
    borderRadius: 2,
  },
  detailAddIcon: {
    position: "absolute",
    top: 15,
    right: 25,
  },
  loaderView: {
    width: "100%",
    minHeight: 400,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  deleteBtnStyle: {
    height: "30px",
    width: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    boxShadow: "0px 3px 6px #00000052",
    backgroundColor: color.white,
    border: `1px solid ${color.secondary}`,
    borderRadius: "100%",
  },
}));

export default useStyles;
