import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { isArray } from "lodash";
import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import useStyles from "../styles";
import MBenefit from "../Modal/MBenefit";

function CBenefit(props) {
  const {
    isCandidate = false,
    from = "",
    data = [],
    updateData = () => null,
  } = props;
  const classes = useStyles();
  const [benefitModal, setBenefitModal] = useState(false);

  if (isCandidate && from === "" && isArray(data) && data.length === 0) {
    return null;
  }

  return (
    <Grid
      item
      xs={12}
      className={[
        classes.shadowViewStyle,
        classes.paddingStyle,
        classes.marginDiv,
      ]}
    >
      <div
        id="cbenefit"
        className={`${classes.splitViewStyle} ${classes.mb20}`}
      >
        <Typography className={classes.sectionTitle}>Benefits</Typography>
        {isCandidate || from === "preview" ? null : isArray(data) &&
          data.length > 0 ? (
          <div
            className={classes.editBtnStyle}
            onClick={() => {
              setBenefitModal(true);
            }}
          >
            <img src={Images.edit} style={{ width: 14 }} />
          </div>
        ) : (
          <div
            onClick={() => {
              setBenefitModal(true);
            }}
          >
            <AddCircle color={color.primary} className={classes.addIcon} />
          </div>
        )}
      </div>
      {isCandidate || from === "" || from === "preview" ? (
        <div className={classes.benefitMainContainer}>
          {isArray(data) &&
            data.length > 0 &&
            data.map((item) => {
              return (
                <Grid item xs={12} sm={6} md={4}>
                  <div className={classes.benefitContainer}>
                    <div className={classes.dotStyle} />
                    <Typography className={classes.sectionDesc}>
                      {item || ""}
                    </Typography>
                  </div>
                </Grid>
              );
            })}
        </div>
      ) : (
        <Typography className={classes.sectionDesc}>
          Outline your employee benefits to demonstrate what you have a offer.
        </Typography>
      )}
      <MBenefit
        visible={benefitModal}
        benefits={data}
        handleModal={(detail, type) => {
          setBenefitModal(false);
          if (type === "update") {
            updateData(detail, "benefits");
          }
        }}
      />
    </Grid>
  );
}
export default CBenefit;
