/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  Button,
  LinearProgress,
  CircularProgress,
  Link,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/styles";
import { Add } from "@mui/icons-material";
import { find, isArray, isEmpty, isObject } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Availability from "../../../Components/Profile/Availability";
import JobType from "../../../Components/Profile/JobType";
import Experience from "../../../Components/Profile/Experience";
import Skills from "../../../Components/Profile/Skills";
import Education from "../../../Components/Profile/Education";
import Languages from "../../../Components/Profile/Languages";
import Bio from "../../../Components/Profile/Bio";
import SkillQuiz from "../../../Components/Profile/SkillQuiz";
import SendMessageModal from "../../../Components/SendMessageModal";
import BasicInfo from "../../../Components/Profile/Modal/BasicInfo";
import MWelcome from "../../../Components/Profile/Modal/MWelcome";
import Images from "../../../Config/Images";
import MPurchase from "../../../Components/Profile/Modal/MPurchase";
import MOffers from "../../../Components/Profile/Modal/MOffers";
import authActions from "../../../Redux/reducers/auth/actions";
import { color } from "../../../Config/theme";
import {
  profileSideBarArray,
  profileSideBarArray1,
} from "../../../Config/Static_Data";
import { Setting } from "../../../Utils/Setting";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import CVModal from "../../../Components/CVModal";
import useStyles from "./styles";
import ConfirmDialog from "../../../Components/Modal/ConfirmDialog";
import MRate from "../../../Components/Modal/RateModal";
import { isIOS } from "react-device-detect";

const { AffindaCredential, AffindaAPI } = require("@affinda/affinda");
const credential = new AffindaCredential(
  "9fb7246a4f86c3275d61d0342aa07cf9dc3c5450"
);
const client = new AffindaAPI(credential);

const {
  setCandidateProfile,
  setCommunication,
  setWelcomeScreen,
  setVisibility,
  setRating,
} = authActions;
let completedProfile = 0;

export default function Profile() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { token, dropdowndata, welcomeScreen, rating } = useSelector(
    (state) => state.auth
  );
  const from = location?.state?.from || "";
  const jobId = location?.state?.jobId || null;
  const userId = location?.state?.userId || null;
  const [openImg, setOpenImg] = useState(false);
  const [basicModal, setBasicModal] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoLoad, setVideoLoad] = useState(false);
  const [unlockProfile, setUnlockProfile] = useState(false);
  const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
  const [msgModalVisible, setMsgModalVisible] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const [profileDetail, setProfileDetail] = useState({});
  const [languageList, setLanguageList] = useState(false);
  const [profileVisiblity, setProfileVisibility] = useState(false);
  const [loader, setLoader] = useState(false);
  const [uploadLoader, setUploadLoader] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  const [CVModalVisible, setCVModalVisible] = useState(false);
  const [cvData, setCVData] = useState({});
  const [cvUploadLoader, setCVUploadLoader] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [rateModal, setRateModal] = useState(false);

  const availabilityRef = useRef();
  const jobTypeRef = useRef();
  const SkillsRef = useRef();
  const experienceRef = useRef();
  const educationRef = useRef();
  const languagesRef = useRef();
  const bioRef = useRef();

  const header = { Authorization: `Bearer ${token}` };

  const CustomSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: "58px !important",
    height: "36px !important",
    padding: "6px !important",
    "& .MuiSwitch-switchBase": {
      padding: 1,
      left: 5,
      top: 4,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(21px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: color.primary,
          opacity: 1,
          border: "none",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: color.primary,
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      color: "#d4dbe5",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      border: "1px solid #d4dbe5",
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
  }));

  useEffect(() => {
    document.title = Setting.page_name.CANDIDATE_PROFILE;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (from !== "employee" && welcomeScreen && completedProfile < 100) {
      setWelcomeModal(true);
    } else {
      setWelcomeModal(false);
    }
  }, [completedProfile]);

  useEffect(() => {
    if (from === "employee" && jobId && userId) {
      getUserDetail();
    } else if (token !== "") {
      getProfileDetail();
      getLanguageList();
    }
  }, [token]);

  useEffect(() => {
    console.log("Profile Detail",profileDetail);
    if (!isEmpty(profileDetail)) {
      completedProfile = 0;
      // setTimeout(() => {
      calculateProfilePercent();
      // }, 200);
    }
  }, [profileDetail]);

  // this function for update visibility status
  async function updateVisibility(bool) {
    try {
      const response = await getApiData(
        `${Setting.endpoints.employeeVisibility}?visibility=${bool}`,
        "GET",
        null,
        header
      );

      if (response.status) {
        setProfileVisibility(response?.data?.visibility === "1" ? true : false);
      } else {
        toast.error(response.message || "");
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  // this function for update profile detail state
  function updateProfileState(data, type = "") {
    const obj = { ...profileDetail };
    obj[type] = data;
    setProfileDetail(obj);
  }

  // this function for get profile detail
  async function getProfileDetail() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        Setting.endpoints.employeeProfile,
        "GET",
        null,
        header
      );

      if (response.status) {
        setProfileDetail(response.data);
        setProfileVisibility(response?.data?.visibility === 1 ? true : false);
        setVideoUrl(response?.data?.user_video || "");
        dispatch(setCandidateProfile(response?.data));
        dispatch(setVisibility(Number(response?.data?.visibility)));
        dispatch(setCommunication(response?.data?.communication_preference));
        dispatch(setRating(response?.data?.rating));
      } else {
        toast.error(response.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>", error);
      setPageLoad(false);
    }
  }

  // this function for get user detail
  async function getUserDetail() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.employerUserDetails}?user_id=${userId}&job_post_id=${jobId}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setProfileDetail(response.data);
        setVideoUrl(response?.data?.user_video || "");
        setUnlockProfile(response?.data?.is_payment || false);
      } else {
        toast.error(response.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  }

  // this function for calculate profile percentage
  function calculateProfilePercent() {
    const {
      user_bio = {},
      user_video = "",
      user_image = "",
      candidate_language = [],
      user_education = [],
      skills = [],
      user_experience = [],
      job_role_type = "",
      availablity_type = "",
      is_quiz_submitted = 0,
    } = profileDetail;

    if (profileDetail?.firstname) {
      completedProfile += 1;
    }
    if (profileDetail?.lastname) {
      completedProfile += 1;
    }
    if (profileDetail?.email) {
      completedProfile += 1;
    }
    if (profileDetail?.phone) {
      completedProfile += 1;
    }
    if (profileDetail?.city_id) {
      completedProfile += 1;
    }
    // if (user_bio?.birth_date) {
    //   completedProfile += 1;
    // }
    // if (user_bio?.permanent_address) {
    //   completedProfile += 3;
    // }
    // if (user_bio?.gender) {
    //   completedProfile += 1;
    // }
    // if (user_bio?.hometown) {
    //   completedProfile += 1;
    // }
    if (user_bio?.bio_description) {
      completedProfile += 5;
    }

    if (user_image && !user_image?.includes("placeholder_for_user")) {
      completedProfile += 5;
    }
    if (user_video) {
      completedProfile += 5;
    }
    if (isArray(candidate_language) && candidate_language.length > 0) {
      completedProfile += 15;
    }
    if (isArray(user_education) && user_education.length > 0) {
      completedProfile += 15;
    }
    if (isArray(skills) && skills.length > 0) {
      completedProfile += 15;
    }
    if (isArray(user_experience) && user_experience.length > 0) {
      completedProfile += 15;
    }
    if (job_role_type !== "") {
      completedProfile += 5;
    }
    if (availablity_type !== "") {
      completedProfile += 5;
    }
    if (is_quiz_submitted) {
      completedProfile += 10;
    }
  }

  // this function for get language list
  async function getLanguageList() {
    try {
      const response = await getApiData(
        Setting.endpoints.dropdown,
        "GET",
        null,
        header
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response.data.language) &&
          response.data.language.length > 0
        ) {
          setLanguageList(response.data.language);
        }
      }
    } catch (error) {
      console.log("error ===>>", error);
    }
  }

  // this function for edit profile
  async function editProfileDetail(data1) {
    setLoader(true);
    const data = {
      "EditProfileForm[first_name]": data1?.firstName,
      "EditProfileForm[last_name]": data1?.lastName,
      "EditProfileForm[email]": data1?.email,
      "EditProfileForm[phone]": `+44${data1?.number}`,
      "EditProfileForm[city_id]": data1?.city,
    };
    try {
      const response = await getAPIProgressData(
        Setting.endpoints.editEmployeeProfile,
        "POST",
        data,
        true
      );

      if (response.status) {
        setLoader(false);
        setBasicModal(false);
        getProfileDetail();
      } else {
        toast.error(response.message);
        setLoader(false);
      }
    } catch (error) {
      console.log("error ===>>", error);
      setLoader(false);
    }
  }

  // this function handles user selected image for upload
  function uploadItem(file, type = "") {
    let displayUrl = "";
    if (type !== "doc") {
      displayUrl = URL.createObjectURL(file);
    }

    if (type === "doc") {
      setCVUploadLoader(true);
      uploadCV(file);
    }

    if (type === "video") {
      setVideoLoad(true);
      setVideoUrl("");
    }

    setTimeout(() => {
      if (type === "image") {
        setOpenImg(displayUrl);
        uploadData(file, "img");
      } else if (type === "video") {
        setVideoUrl(displayUrl);
        setVideoLoad(false);
        uploadData(file, "video");
      }
    }, 1000);
  }

  // this function for upload CV to server and get url
  async function uploadCV(file) {
    const obj = {
      "ImportCv[cv]": file,
    };
    try {
      const response = await getAPIProgressData(
        Setting.endpoints.employeeUploadCV,
        "POST",
        obj,
        header
      );

      if (response.status) {
        getCVDataFromURL(response.data);
      } else {
        toast.error(response.message);
        setCVUploadLoader(false);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      setCVUploadLoader(false);
    }
  }

  // this function for get data from uploaded CV
  async function getCVDataFromURL(url = "") {
    try {
      client
        .createResume({ url: url })
        .then((response) => {
          const obj = {
            education: [],
            experience: [],
            name: "",
            phone: "",
            skills: [],
            address: "",
          };

          if (isObject(response.data) && !isEmpty(response.data)) {
            const {
              education,
              location,
              name,
              phoneNumbers,
              skills,
              workExperience,
            } = response.data;

            // this for set education to object (filtering data from CV parser)
            if (isArray(education) && education.length > 0) {
              education.map((item) => {
                const eObj = {
                  organization: item?.organization || "",
                  title: item?.accreditation?.education || "",
                  grade: item?.grade || "",
                  from_date: item?.dates?.startDate || "",
                  currently_studying: item?.dates?.isCurrent ? 1 : 0,
                  to_date: item?.dates?.completionDate || "",
                };
                obj.education.push(eObj);
              });
            }

            // this for set experience to object (filtering data from CV parser)
            if (isArray(workExperience) && workExperience.length > 0) {
              workExperience.map((item) => {
                const eObj = {
                  organization: item?.organization || "",
                  from_date: item?.dates?.startDate || "",
                  currently_working: item?.dates?.isCurrent ? 1 : 0,
                  to_date: item?.dates?.endDate || "",
                  description: item?.jobDescription || "",
                  address: item?.location?.formatted || "",
                };
                obj.experience.push(eObj);
              });
            }

            // this for set name (filtering data from CV parser)
            if (!isEmpty(name)) {
              obj.name = name?.raw
                ? name?.raw
                : (name?.first || "") + (name?.last || "") || "";
            }

            // this for set phone number (filtering data from CV parser)
            if (isArray(phoneNumbers) && phoneNumbers.length > 0) {
              obj.phone = phoneNumbers[0];
            }

            // this for set skills to object (filtering data from CV parser)
            // if (isArray(skills) && skills.length > 0) {
            //   skills.map((item) => {
            //     if (item?.name !== "") {
            //       obj.skills.push(item?.name || "");
            //     }
            //   });
            // }

            // this for set location (filtering data from CV parser)
            if (!isEmpty(location)) {
              obj.address = location?.formatted || location?.rawInput || "";
            }
          }
          setCVData(obj);
          setCVModalVisible(true);
          setCVUploadLoader(false);
        })
        .catch((error) => {
          console.log("error", error);
          setCVUploadLoader(false);
          toast.error(error.toString());
        });
    } catch (error) {
      console.log("error ===>>>", error);
      setCVUploadLoader(false);
      toast.error(error.toString());
    }

    // const myHeaders = new Headers();
    // myHeaders.append("apikey", "ys6Lze60f4ChmMbIDZqOXMbPcKVXNYMq");

    // const requestOptions = {
    //   method: "GET",
    //   redirect: "follow",
    //   headers: myHeaders,
    // };

    // fetch(
    //   `https://api.apilayer.com/resume_parser/url?url=${url}`,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((response) => {
    //     if (!isEmpty(response)) {
    //       setCVData(response);
    //       setCVModalVisible(true);
    //       setCVUploadLoader(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error", error);
    //     setCVUploadLoader(false);
    //     toast.error(error.toString());
    //   });
  }

  // this function for upload profile picture
  async function uploadData(url, type) {
    let endpoint = Setting.endpoints.employeeEditPhoto;
    let obj = {
      "ImageForm[photo]": url,
    };

    if (type === "video") {
      endpoint = Setting.endpoints.employeeAddProfileVideo;
      obj = {
        "ProfileVideoForm[video]": url,
      };
    }

    setUploadLoader(type);
    try {
      const response = await getAPIProgressData(endpoint, "POST", obj, header);

      if (response.status) {
        if (type === "video") {
          setProfileDetail({ ...profileDetail, user_video: response.data });
        } else {
          setProfileDetail({ ...profileDetail, user_image: response.data });
        }
      } else {
        toast.error(response.message);
        if (type === "img") {
          setOpenImg("");
        } else {
          setVideoUrl("");
        }
      }
      setUploadLoader("");
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setUploadLoader("");
      if (type === "img") {
        setOpenImg("");
      } else {
        setVideoUrl("");
      }
    }
  }

  // this function for remove profile picture
  async function removeImageVideo() {
    setRemoveBtnLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.employeeRemoveVideoOrPhoto}?type=${deleteType}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (deleteType === "video") {
          setVideoUrl("");
          setProfileDetail({ ...profileDetail, user_video: "" });
          setConfirmDialog(false);
        } else {
          setProfileDetail({ ...profileDetail, user_image: response?.data });
        }
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setConfirmDialog(false);
      setRemoveBtnLoad(false);
      console.log("error ===>>>", error);
    }
  }

  // this function for scroll
  function redirect(type) {
    if (document) {
      let section = null;

      if (type === "availability") {
        section = document.querySelector("#availability");
      } else if (type === "jobtype") {
        section = document.querySelector("#jobtype");
      } else if (type === "experience") {
        section = document.querySelector("#experience");
      } else if (type === "skills") {
        section = document.querySelector("#skills");
      } else if (type === "education") {
        section = document.querySelector("#education");
      } else if (type === "language") {
        section = document.querySelector("#language");
      } else if (type === "bio") {
        section = document.querySelector("#bio");
      }

      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }

  // this function renders profile from employee
  function renderProfile() {
    return (
      <Grid container className={classes.employeeContainer}>
        <Grid item xs={12} sm={3} className={classes.profileImgSection}>
          <img
            src={profileDetail?.user_image}
            className={classes.profileImgStyle}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Container className={classes.detailContainer}>
            <Grid xs={12}>
              <div className={classes.userDetailStyle}>
                <Typography className={classes.userTitleStyle}>{`${
                  profileDetail?.firstname || ""
                } ${profileDetail?.lastname || ""}`}</Typography>
                {unlockProfile && (
                  <div
                    className={classes.userContentStyle}
                    style={{ marginTop: 0 }}
                  >
                    {profileDetail?.email && (
                      <a
                        href={`mailto:${profileDetail?.email}`}
                        style={{ textDecoration: "none" }}
                      >
                        <div
                          className={classes.userDetailTextStyle}
                          style={{ marginRight: 60 }}
                        >
                          <img
                            src={Images.email}
                            className={classes.emailImgStyle}
                            alt="email_image"
                          />
                          <Typography className={classes.profileDetailText}>
                            {profileDetail?.email || ""}
                          </Typography>
                        </div>
                      </a>
                    )}
                    {profileDetail?.phone && (
                      <a
                        href={`tel:${profileDetail?.phone}`}
                        rel="noopener nofollow"
                        style={{ textDecoration: "none" }}
                      >
                        <div className={classes.userDetailTextStyle}>
                          <img
                            src={Images.phone}
                            className={classes.userDetailIconStyle}
                            alt="phone_image"
                          />
                          <Typography className={classes.profileDetailText}>
                            {profileDetail?.phone || ""}
                          </Typography>
                        </div>
                      </a>
                    )}
                  </div>
                )}
              </div>
              {profileDetail?.user_bio?.bio_description && (
                <>
                  <Typography
                    className={classes.sectionDesc}
                    style={{ color: "#000" }}
                  >
                    Bio
                  </Typography>
                  <Typography className={classes.sectionDesc}>
                    {profileDetail?.user_bio?.bio_description || "-"}
                  </Typography>
                </>
              )}
              {!unlockProfile ? (
                <div className={classes.unlockProfileSection}>
                  <Typography className={classes.sectionDesc}>
                    To view{" "}
                    {`${profileDetail?.firstname || ""} ${
                      profileDetail?.lastname || ""
                    }'s`}{" "}
                    contact details, click on{" "}
                    <span className={classes.unlockText}>Unlock Profile.</span>
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.unlockBtnStyle}
                    onClick={() => {
                      setOpenPurchaseModal(true);
                    }}
                  >
                    Unlock Profile
                  </Button>
                </div>
              ) : (
                <div style={{ marginTop: 20 }}>
                  {/* <Typography className={classes.sectionDesc}>
                    {`${profileDetail?.firstname || ""} ${
                      profileDetail?.lastname || ""
                    }`}{" "}
                    contact details are{" "}
                    <span className={classes.unlockText}>"Unlocked"</span>
                  </Typography> */}
                  <Grid container style={{ marginTop: 15 }}>
                    <Grid item xs={12} sm={5} md={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.makeOfferBtnStyle}
                        onClick={() => setOfferModal(true)}
                      >
                        Make A Job Offer
                      </Button>
                    </Grid>
                    <div style={{ width: 20 }} />
                    <Grid item xs={12} sm={5} md={4}>
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => setMsgModalVisible(true)}
                      >
                        Message
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Container>
        </Grid>
      </Grid>
    );
  }

  // this function renders profile section
  function renderProfileView() {
    const bioDetail = profileDetail?.user_bio || {};
    const cityData = find(
      dropdowndata?.city,
      (item) => item.value === profileDetail?.city_id
    );
    return (
      <Container className={classes.profileContainerStyle}>
        <Grid item xs={12} sm={8} md={9}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              className={classes.deviceContentResponsive}
            >
              <span style={{ position: "relative" }}>
                <img
                  src={profileDetail?.user_image || Images.placeholder}
                  className={classes.userImgStyle}
                  onError={(e) => {
                    e.target.src = Images.placeHolder;
                  }}
                  alt="profile"
                />
                {uploadLoader === "img" && (
                  <div className={classes.imgLoader}>
                    <CircularProgress size={28} />
                  </div>
                )}
                <Button
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  className={classes.cameraIcon}
                >
                  <img
                    src={Images.camera}
                    alt="camera"
                    className={classes.cameraImgStyle}
                  />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => setAnchorEl(null)}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem component="label">
                    Update Image
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadItem(e.target.files[0], "image");
                        setAnchorEl(null);
                      }}
                      accept="image/jpeg, image/png, image/jpg"
                      hidden
                    />
                  </MenuItem>
                  {!profileDetail?.user_image?.includes(
                    "placeholder_for_user"
                  ) && (
                    <MenuItem
                      onClick={() => {
                        setAnchorEl(null);
                        setConfirmDialog(true);
                        setDeleteType("profile_pic");
                      }}
                    >
                      Remove Image
                    </MenuItem>
                  )}
                </Menu>
              </span>
              <div
                className={`${classes.splitViewStyle} ${classes.fullWidthStyle} ${classes.hideWebView}`}
                style={{ paddingLeft: 20 }}
              >
                <Typography className={classes.userTitleStyle}>
                  {`${profileDetail?.firstname || ""} ${
                    profileDetail?.lastname || ""
                  }`}
                </Typography>
                <div
                  className={classes.editBtnStyle}
                  onClick={() => setBasicModal(true)}
                >
                  <img src={Images.edit} style={{ width: 14 }} />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={8} className={classes.ml30}>
              <div
                className={`${classes.splitViewStyle} ${classes.hideMobileView}`}
              >
                <Typography className={classes.userTitleStyle}>
                  {`${profileDetail?.firstname || ""} ${
                    profileDetail?.lastname || ""
                  }`}
                </Typography>
                <div
                  className={classes.editBtnStyle}
                  onClick={() => setBasicModal(true)}
                >
                  <img src={Images.edit} style={{ width: 14 }} />
                </div>
              </div>
              <div className={classes.userContentStyle}>
                {profileDetail?.email && (
                  <div
                    className={classes.userDetailTextStyle}
                    style={{ marginRight: 60 }}
                  >
                    <img src={Images.email} className={classes.emailImgStyle} />
                    <Typography className={classes.profileDetailText}>
                      {profileDetail?.email || ""}
                    </Typography>
                  </div>
                )}
                {profileDetail?.phone && (
                  <div className={classes.userDetailTextStyle}>
                    <img
                      src={Images.phone}
                      className={classes.userDetailIconStyle}
                    />
                    <Typography className={classes.profileDetailText}>
                      {profileDetail?.phone || ""}
                    </Typography>
                  </div>
                )}
                {!isEmpty(cityData) && (
                  <div className={classes.userDetailTextStyle}>
                    <img
                      src={Images.location}
                      className={classes.userDetailIconStyle}
                    />
                    <Typography className={classes.profileDetailText}>
                      {(!isEmpty(cityData) && cityData?.label) || "-"}
                    </Typography>
                  </div>
                )}
              </div>
              <div className={classes.profilePercentDiv}>
                <div className={classes.splitViewStyle}>
                  <Typography className={classes.progessTextStyle}>
                    {`Profile Strength ${
                      completedProfile >= 100 ? "" : "(Average)"
                    }`}
                  </Typography>
                  <Typography className={classes.progessTextStyle}>
                    {completedProfile >= 100 ? 100 : completedProfile}%
                  </Typography>
                </div>
                <LinearProgress
                  variant="determinate"
                  value={completedProfile >= 100 ? 100 : completedProfile}
                  color="secondary"
                  style={{ marginTop: 8 }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        {completedProfile >= 100 ? null : (
          <Grid item xs={12} sm={4} md={3} className={classes.gradientDivStyle}>
            <Typography
              className={classes.sectionTitle}
              style={{ color: "#fff" }}
            >
              Pending Action
            </Typography>
            <div className={classes.actionWraper}>
              {!profileDetail?.is_quiz_submitted && (
                <div className={classes.actionContainer}>
                  <div className={classes.actionDot} />
                  <Link href="/candidate/quiz" prefetch={false}>
                    <Typography className={classes.listTextStyle}>
                      Skill Quiz
                    </Typography>
                  </Link>
                </div>
              )}
              {profileDetail?.availablity_type === "" && (
                <div className={classes.actionContainer}>
                  <div className={classes.actionDot} />
                  <Typography
                    className={classes.listTextStyle}
                    onClick={() => {
                      availabilityRef?.current?.handleModal();
                    }}
                  >
                    Availability
                  </Typography>
                </div>
              )}
              {profileDetail?.job_role_type === "" && (
                <div className={classes.actionContainer}>
                  <div className={classes.actionDot} />
                  <Typography
                    className={classes.listTextStyle}
                    onClick={() => {
                      jobTypeRef?.current?.handleModal();
                    }}
                  >
                    Job Type
                  </Typography>
                </div>
              )}
              {isArray(profileDetail?.user_experience) &&
                profileDetail?.user_experience.length === 0 && (
                  <div className={classes.actionContainer}>
                    <div className={classes.actionDot} />
                    <Typography
                      className={classes.listTextStyle}
                      onClick={() => {
                        experienceRef?.current?.handleModal();
                      }}
                    >
                      Experience
                    </Typography>
                  </div>
                )}
              {isArray(profileDetail?.skills) &&
                profileDetail?.skills.length === 0 && (
                  <div className={classes.actionContainer}>
                    <div className={classes.actionDot} />
                    <Typography
                      className={classes.listTextStyle}
                      onClick={() => {
                        SkillsRef?.current?.handleModal();
                      }}
                    >
                      Skills
                    </Typography>
                  </div>
                )}
              {isArray(profileDetail?.user_education) &&
                profileDetail?.user_education.length === 0 && (
                  <div className={classes.actionContainer}>
                    <div className={classes.actionDot} />
                    <Typography
                      className={classes.listTextStyle}
                      onClick={() => {
                        educationRef?.current?.handleModal();
                      }}
                    >
                      Education
                    </Typography>
                  </div>
                )}
              {isArray(profileDetail?.candidate_language) &&
                profileDetail?.candidate_language.length === 0 && (
                  <div className={classes.actionContainer}>
                    <div className={classes.actionDot} />
                    <Typography
                      className={classes.listTextStyle}
                      onClick={() => {
                        languagesRef?.current?.handleModal();
                      }}
                    >
                      Languages
                    </Typography>
                  </div>
                )}
              {bioDetail?.bio_description ? null : (
                <div className={classes.actionContainer}>
                  <div className={classes.actionDot} />
                  <Typography
                    className={classes.listTextStyle}
                    onClick={() => {
                      bioRef?.current?.handleModal();
                    }}
                  >
                    Bio
                  </Typography>
                </div>
              )}
              {profileDetail?.user_video === "" && (
                <div className={classes.actionContainer}>
                  <div className={classes.actionDot} />
                  <Typography
                    className={classes.listTextStyle}
                    onClick={() => {
                      const section = document.querySelector("#video");
                      section.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }}
                  >
                    Add Video
                  </Typography>
                </div>
              )}
              {profileDetail?.city_id ? null : (
                <div className={classes.actionContainer}>
                  <div className={classes.actionDot} />
                  <Typography
                    className={classes.listTextStyle}
                    onClick={() => setBasicModal(true)}
                  >
                    Add City
                  </Typography>
                </div>
              )}
              {profileDetail?.user_image === "" ||
                (profileDetail?.user_image?.includes(
                  "placeholder_for_user"
                ) && (
                  <div className={classes.actionContainer}>
                    <div className={classes.actionDot} />
                    <Typography
                      component={"label"}
                      className={classes.listTextStyle}
                    >
                      Add Profile Picture
                      <input
                        type="file"
                        onChange={(e) => {
                          console.log("hiii");
                          uploadItem(e.target.files[0], "image");
                        }}
                        accept="image/jpeg, image/png, image/jpg"
                        hidden
                      />
                    </Typography>
                  </div>
                ))}
            </div>
          </Grid>
        )}
      </Container>
    );
  }

  const sArray =
    from === "employee" ? profileSideBarArray1 : profileSideBarArray;

  if (pageLoad) {
    return (
      <Grid container>
        <div className={classes.loaderView}>
          <CircularProgress size={50} />
        </div>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.container}>
      {from === "employee" ? renderProfile() : renderProfileView()}
      <Grid item xs={12} sm={4} md={3}>
        {from !== "employee" && (
          <Grid container>
            <Button
              fullWidth
              variant={"contained"}
              color="primary"
              style={{ minWidth: 150, marginBottom: 20 }}
              component="label"
              onClick={() => {
                navigate("/candidate/jobs");
              }}
            >
              view jobs
            </Button>
          </Grid>
        )}
        <div className={classes.shadowViewStyle}>
          {isArray(sArray) &&
            sArray.length > 0 &&
            sArray.map((item, index) => {
              const lIndex = sArray.length - 1;
              return (
                <div
                  key={`${item.title}_index`}
                  className={classes.singleViewStyle}
                  onClick={() => redirect(item.key)}
                  style={
                    item?.type === "switch"
                      ? {
                          borderBottom:
                            lIndex === index ? "none" : "1px solid #34567b",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }
                      : {
                          borderBottom:
                            lIndex === index ? "none" : "1px solid #34567b",
                          alignItems: "center",
                        }
                  }
                >
                  {item?.icon && (
                    <img
                      src={item.icon}
                      alt="logo"
                      style={{
                        height: 25,
                        width: 25,
                        marginRight: 15,
                      }}
                    />
                  )}
                  <Typography
                    style={{
                      color: color.primary,
                      fontFamily: "PoppinsRegular",
                    }}
                  >
                    {item?.title || ""}
                  </Typography>
                  {item.type === "switch" && (
                    <FormControlLabel
                      control={
                        <CustomSwitch
                          checked={profileVisiblity}
                          onChange={() => {
                            updateVisibility(profileVisiblity ? 0 : 1);
                            setProfileVisibility(!profileVisiblity);
                          }}
                        />
                      }
                      label=""
                      style={{ marginRight: 0 }}
                    />
                  )}
                </div>
              );
            })}
        </div>

        <Grid container>
          <Button
            fullWidth
            variant={"contained"}
            color="primary"
            style={{ minWidth: 150, marginTop: 20 }}
            component="label"
            onClick={() => {
              setRateModal(true);
            }}
          >
            Rate us
          </Button>
        </Grid>
        <div id="video" className={classes.videoContainer}>
          <Typography className={classes.videoTitle}>Your Video</Typography>
          <div className={classes.videoSectionStyle}>
            {videoUrl ? (
              <div>
                <video
                  width="100%"
                  className={classes.videoStyle}
                  controls
                  poster={isIOS ? Images.videoThumbnail : ""}
                >
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </div>
            ) : (
              <Typography>
                {from === "employee" ? "No video" : "Add video"}
              </Typography>
            )}
            {from === "employee" ? null : (
              <>
                <div className={classes.buttonAbsoluteDiv}>
                  <Button component="label" className={classes.videoIcon}>
                    <Add style={{ color: "#FFF" }} />
                    <input
                      type="file"
                      onChange={(e) => uploadItem(e.target.files[0], "video")}
                      accept="video/*"
                      hidden
                    />
                  </Button>
                </div>
                {profileDetail?.user_video !== "" && (
                  <div
                    className={`${classes.buttonAbsoluteDiv} ${classes.editBtnStyle} ${classes.deleteBtnStyle}`}
                    style={{ right: 60 }}
                    onClick={() => {
                      setConfirmDialog(true);
                      setDeleteType("video");
                    }}
                  >
                    <img
                      src={Images.odelete}
                      alt="deletevideo"
                      style={{ width: 14 }}
                    />
                  </div>
                )}
              </>
            )}
            {(videoLoad || uploadLoader === "video") && (
              <CircularProgress style={{ position: "absolute" }} />
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={9}>
        <Container className={classes.detailContainer}>
          {from === "employee" ? (
            <SkillQuiz
              candidateResult={() => {
                navigate("/employer/jobs/candidate-result", {
                  state: { jobId, userId },
                });
              }}
              percent={profileDetail?.candidate_quiz_result || 0}
            />
          ) : (
            <>
              <Grid
                item
                xs={12}
                className={[
                  classes.shadowViewStyle,
                  classes.paddingStyle,
                  classes.marginDiv,
                ]}
              >
                <Typography className={classes.sectionTitle}>
                  Upload your CV to complete your profile
                </Typography>
                <Typography className={classes.sectionDesc}>
                  Relevant information will be extracted from your CV
                  automatically and inserted into your profile. You can edit,
                  remove, and add information after this stage.
                </Typography>
                <Button
                  variant={cvUploadLoader ? "outlined" : "contained"}
                  color="primary"
                  style={{ marginTop: 10, minWidth: 150 }}
                  component="label"
                  disabled={cvUploadLoader}
                >
                  {cvUploadLoader ? (
                    <CircularProgress size={28} />
                  ) : (
                    "IMPORT CV"
                  )}
                  <input
                    type="file"
                    onClick={(e) => (e.target.value = "")}
                    onChange={(e) => {
                      uploadItem(e.target.files[0], "doc");
                    }}
                    accept=".doc, .docx"
                    hidden
                  />
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                className={[
                  classes.shadowViewStyle,
                  classes.paddingStyle,
                  classes.marginDiv,
                ]}
              >
                <Typography className={classes.sectionTitle}>
                  Boost your profile by taking a Situational Judgement test{" "}
                  <span style={{ color: "#ff0000" }}>*</span>
                </Typography>
                <Typography className={classes.sectionDesc}>
                  For your profile to be shown to potential employers, complete
                  the Situational Judgement Test.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 10 }}
                >
                  <Link
                    href="/candidate/quiz"
                    prefetch={false}
                    style={{ color: color.white, textDecoration: "none" }}
                  >
                    START Situational Judgement test
                  </Link>
                </Button>
              </Grid>
            </>
          )}
          <Availability
            ref={availabilityRef}
            from={from}
            data={profileDetail}
            getProfileData={() => {
              getProfileDetail();
            }}
          />
          <JobType
            ref={jobTypeRef}
            from={from}
            data={profileDetail}
            getProfileData={() => {
              getProfileDetail();
            }}
          />
          <Experience
            ref={experienceRef}
            from={from}
            data={profileDetail}
            getProfileData={() => {
              getProfileDetail();
            }}
          />
          <Skills
            ref={SkillsRef}
            from={from}
            data={profileDetail}
            jobId={jobId || null}
            updateProfileState={updateProfileState}
          />
          <Education
            ref={educationRef}
            from={from}
            data={profileDetail}
            getProfileData={() => {
              getProfileDetail();
            }}
          />
          <Languages
            ref={languagesRef}
            from={from}
            data={profileDetail}
            list={languageList}
            updateProfileState={updateProfileState}
          />
          <Bio
            ref={bioRef}
            from={from}
            data={profileDetail}
            updateProfileState={updateProfileState}
          />
        </Container>
      </Grid>
      <BasicInfo
        visible={basicModal}
        profileDetail={profileDetail}
        DataSave={(data) => {
          editProfileDetail(data);
        }}
        loader={loader}
        handleModal={() => setBasicModal(false)}
      />
      <MWelcome
        visible={welcomeModal}
        handleModal={() => {
          setWelcomeModal(false);
          dispatch(setWelcomeScreen(false));
        }}
      />
      <SendMessageModal
        visible={msgModalVisible}
        receiverId={userId || null}
        handleModal={() => setMsgModalVisible(false)}
      />
      <MPurchase
        visible={openPurchaseModal}
        data={profileDetail || {}}
        jobId={jobId}
        candidateId={userId}
        handleModal={() => {
          setOpenPurchaseModal(false);
        }}
        puchaseSuccess={(data) => {
          const obj = { ...profileDetail };
          obj.email = data?.email;
          obj.phone = data?.phone;
          setProfileDetail(obj);
          setUnlockProfile(true);
        }}
      />
      <MOffers
        visible={offerModal}
        jobId={jobId}
        employerData={profileDetail || {}}
        handleModal={() => setOfferModal(false)}
        apiSussecc={() => {
          setOfferModal(false);
        }}
      />
      <ConfirmDialog
        title={`Are you sure you want to delete this ${
          deleteType === "video" ? "video" : "profile image"
        }?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            removeImageVideo();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
      <MRate
        visible={rateModal}
        handleModal={() => {
          setRateModal(false);
        }}
        ratings={rating}
      />
      {CVModalVisible && (
        <CVModal
          visible={CVModalVisible}
          data={cvData}
          handleModal={(type) => {
            setCVModalVisible(false);
            if (type === "success") {
              getProfileDetail();
            }
          }}
        />
      )}
    </Grid>
  );
}
