import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useTheme,
  useMediaQuery,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import _, { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Images from "../../../../Config/Images";
import { Setting } from "../../../../Utils/Setting";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import useStyles from "../../styles";

function MOverview(props) {
  const {
    visible = false,
    handleModal = () => null,
    description = false,
    onSave = () => null,
    overviewDetail = "",
  } = props;
  const { token } = useSelector((state) => state.auth);
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const [overview, setOverview] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : md ? 500 : 700,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (visible) {
      setOverview(overviewDetail || "");
    }
  }, [visible]);

  function resetFormData(type = "", data = "") {
    setOverview("");
    if (type === "close" && handleModal) {
      handleModal();
    } else if (type === "update") {
      handleModal(data, type);
    }
  }

  // this function checks validation
  async function updateData() {
    if (isEmpty(overview.trim())) {
      toast.error("Please enter description");
    } else {
      setBtnLoad(true);
      try {
        const response = await getAPIProgressData(
          Setting.endpoints.employerAddOverview,
          "POST",
          {
            "OverviewForm[company_overview]": overview,
          },
          { Authorization: `Bearer ${token}` }
        );

        if (response.status) {
          resetFormData("update", response?.data?.company_overview || "");
        } else {
          toast.error(response.message);
        }
        setBtnLoad(false);
      } catch (error) {
        console.log("error ===>>>", error);
        toast.error(error.toString());
        setBtnLoad(false);
      }
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => (btnLoad ? null : resetFormData("close"))}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={classes.splitViewStyle} style={{ marginBottom: 20 }}>
            <Typography
              variant="subtitle1"
              component="h2"
              className={classes.modalTitle}
            >
              {description ? "Add description of role" : "Add Overview"}
            </Typography>
            <img
              alt="close"
              src={Images.close}
              className={classes.closeBtnStyle}
              onClick={() => (btnLoad ? null : resetFormData("close"))}
            />
          </div>
          <Editor
            onEditorChange={(e) => {
              setOverview(e);
            }}
            value={overview}
            apiKey="fc04elxcr618mmqpqlz178ljh3fnxlmk4yduvz64c9yfgsqk"
            init={{
              height: 300,
              plugins: "lists advlist emoticons",
              // menubar: false,
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor  | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help emoticons",
            }}
          />
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: sm ? "space-between" : "unset",
            }}
          >
            <Grid xs={5.8} sm={4} md={3} style={{ marginRight: 10 }}>
              <Button
                variant="outlined"
                style={{ width: "100%" }}
                onClick={() => {
                  resetFormData();
                }}
                disabled={btnLoad}
              >
                RESET
              </Button>
            </Grid>
            <Grid xs={5.8} sm={4} md={3}>
              <Button
                variant={btnLoad ? "outlined" : "contained"}
                style={{ width: "100%" }}
                disabled={btnLoad}
                onClick={() => {
                  description ? onSave(overview) : updateData();
                }}
              >
                {btnLoad ? <CircularProgress size={28} /> : "SAVE"}
              </Button>
            </Grid>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default MOverview;
